
import { useNavigate, useParams } from "react-router-dom";
import {
  addSubscription,
  getSubscriptionById,
} from "../../../../../services/subscription/subscriptionServices";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";
import { Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
export default function ProductEditForm() {
  const [content, setContent] = useState("");
  const [logs, setLogs] = useState([]);
  const appendLog = useCallback(
    (message) => {
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter Plan Details"

    }),
    []
  );

  const onChange = useCallback(
    (newContent) => {
      appendLog(`onChange triggered with ${newContent}`);
    },
    [appendLog]
  );

  useEffect(() => {
  }, [onChange]);

  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );


  const router = useNavigate();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [subscriptioinforms, setSubscriptionForms] = useState({
    planName: "",
    planPrice: 0,
    allowedProducts: 0,
    planDescription: "",
    flatPercentage: 0,
    durationMonth: 0,
  });



  const {
    planName,
    planPrice,
    allowedProducts,
    planDescription,
    flatPercentage,
    durationMonth,
  } = subscriptioinforms;

  const handleChange = (e) => {
    setSubscriptionForms({
      ...subscriptioinforms,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setLoading(false);
    getProductPlan();
  }, [loading, id]);

  const getProductPlan = () => {
    if (id) {
      getSubscriptionById(id)
        .then((res) => {
          setSubscriptionForms(res?.data);
          setContent(res?.data?.planDetails.replace(/&lt;/g, "<"));
        })
        .catch((err) => console.log(err));
    }
    else {
      setSubscriptionForms({})
    }

  };

  const createPlan = () => {
    setLoading(true);

    if (planName?.length === 0 || planPrice?.length === 0 || allowedProducts?.length === 0 || planDescription?.length === 0 || content?.length === 0 || flatPercentage?.length === 0) {
      setError(true);
    }


    if (
      planName &&
        planPrice?.length > 0 ? planPrice : 0 &&
          allowedProducts?.length > 0 ? allowedProducts : 0 &&
            planDescription &&
            flatPercentage?.length > 0 ? flatPercentage : 0 &&
              durationMonth?.length > 0 ? durationMonth : 0 && content
    ) {
      let data = {
        planId: id,
        planTypeId: 1,
        planName: planName,
        planPrice: planPrice,
        allowedProducts: allowedProducts,
        planDescription: planDescription,
        allowedServices: null,
        planDetails: content,
        flatPercentage: Number(flatPercentage),
        durationMonth: durationMonth,
      };


      addSubscription(data)
        .then((res) => {
          setLoading(false);
          BackPage();
          toast.success("Updated Success")

        })
        .catch((err) => console.log(err));
    }



  };

  const BackPage = () => {
    router("/womeyn/subscribeplans");
  };

  return (
    <div>
      <Card>
        <div className="fs-5 ms-4 mt-4">Product Plans</div>
        <Card.Body>
          <Row className="mb-3 mt-2">
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="planName"
                    onChange={handleChange}
                    value={planName}
                  />
                  <Form.Text className="text-muted">
                    {error && planName?.length <= 0 ? <div className="text-danger">PlanName is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Package Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Amount"
                    name="planPrice"
                    onChange={handleChange}
                    value={planPrice}
                  />
                  <Form.Text className="text-muted">
                    {error && planPrice?.length <= 0 ? <div className="text-danger">PlanPrice is Required</div> : null}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row className="mb-3 mt-2">
            <Col xs={6}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Products Listing</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of products"
                    name="allowedProducts"
                    value={allowedProducts}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {error && allowedProducts?.length <= 0 ? <div className="text-danger">AllowedProducts is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="planDescription"
                    value={planDescription}
                    onChange={handleChange}
                    rows={5}
                  />
                  <Form.Text className="text-muted">
                    {error && planDescription?.length <= 0 ? <div className="text-danger">PlanDescription is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row className="mb-3 mt-2">
            <Col xs={6}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Flat Percentage</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter number of Flat Percentage"
                    name="flatPercentage"
                    value={flatPercentage}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {error && flatPercentage?.length <= 0 ? <div className="text-danger">FlatPercentage is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Duration Month</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter duration Month"
                    name="durationMonth"
                    value={durationMonth}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {error && durationMonth?.length <= 0 ? <div className="text-danger">DurationMonth is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <div>
            <Form.Label>Plan Details</Form.Label>
          </div>
          <JoditEditor
            value={content}
            config={config}
            tabIndex={1}
            onBlur={onBlur}
            onChange={onChange}
          />
          <Form.Text className="text-muted">
            {error && content?.length <= 0 ? <div className="text-danger">Content is Required</div> : null}

          </Form.Text>
          <div>
            <div className="buttonsections">
              <div>
                <button className="savebutton" onClick={createPlan}>
                  Save
                </button>
              </div>
              <div>
                <button className="cancelbutton" onClick={BackPage}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
