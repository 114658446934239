import { HttpInterceptor } from "../auth/http-interceptors";

export function createContact(data) {
    return new Promise((resolve, reject) => {
        HttpInterceptor.post("/admin/contact-info", data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}



export function getSingleContact() {
    return new Promise((resolve, reject) => {
        HttpInterceptor.get(`/admin/contact-info`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}



