import { HttpInterceptor } from "../auth/http-interceptors";

export function DashboardServices() {
  return HttpInterceptor.get(`/admin/dashboard`);
}

export function TopSellerServices() {
  return HttpInterceptor.get(`/admin/top-sellers`);
}

export function TopSellerServicesFilter(id) {
  return HttpInterceptor.get(`/admin/top-sellers?filterType=${id}`);
}

export function UserEngagementServicesFilter(id) {
  return HttpInterceptor.get(`/admin/user-engagement?filterType=${id}`);
}

export function getPopularProducts() {
  return HttpInterceptor.get(`/admin/popular-products`);
}

export function getRevenue() {
  return HttpInterceptor.get(`/admin/revenue`);
}
