import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { singleBlog } from '../../../../../services/blogservice/blog-services';
import { Card } from 'react-bootstrap';
import ReactHtmlParser from "react-html-parser";

import './styles/Viewblog.scss';
function Viewblog() {
    const { id } = useParams();
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
        singleBlog(id).then((res) => {
            setBlogs(res?.data);
        
        }).catch((err) => {
            console.log(err);
        })
    }, [])
    return (
        <div>
            <Card>
                <div className='d-flex align-items-center justify-content-center'>
                    <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/${blogs?.postImageName}`}
                        alt="no image"
                        className="imageblogss"
                    />
                    <div>
                    </div>
                </div>


                <div className='mt-4 ms-1'>
                    <div>
                        {/* <h4> Title :</h4> */}
                    </div>
                    <div className='ms-3 mt-3 mb-1'>
                        {blogs?.title}
                    </div>
                </div>

                <div className='mt-5 mb-5'>
                    <div className='mb-4'>
                        {/* <h4>Content :</h4> */}
                    </div>
                    <div className='ms-3'>{ReactHtmlParser(blogs?.postContent)?.slice(0, 30)}</div>
                </div>

                <div>
                    <div className='ms-1'>
                        {/* <h4> Description :</h4> */}
                    </div>
                    <div className='ms-3 mt-3 mb-4'>
                        {blogs?.shortDescription}
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default Viewblog