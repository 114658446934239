import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Termsgetsingleeseller } from '../../../../../../services/termsandcondition_services/termsandconditions_services';
import ReactHtmlParser from "react-html-parser";
import './styles/Sellerterms.scss';
import {
    Button,
    Card,
    Col,
    Modal,
    Nav,
    Row,
    Tab,
    Table,
} from "react-bootstrap";
import SellerTearmsconditions from './createsellerterms/SellerTearmsconditions';
import SellerPrivacypolicy from './createsellerprivacypolicy/SellerPrivacypolicy';
function Sellertermsandconditions() {
    
    return (


        <Tab.Container defaultActiveKey="1">
            <Nav className="tab-bottom-bordered">
                <div className="mb-0 nav nav-tabs" id="nav-tab1" role="tablist">
                    <Nav.Link
                        eventKey="1"
                        id="nav-admin"
                        data-bs-target="#tab-admin"
                        aria-controls="pills-admin"
                        data-bs-toggle="tab"
                        type="button"
                        role="tab"
                        aria-selected="true"
                    >
                        <span className="iq-mail-section">Terms & Conditions Seller</span>
                    </Nav.Link>

                    <Nav.Link
                        eventKey="2"
                        id="nav-sellers"
                        data-bs-target="#tab-sellers"
                        aria-controls="pills-sellers"
                        data-bs-toggle="tab"
                        type="button"
                        role="tab"
                        aria-selected="false"
                    >
                        <span className="iq-mail-section">Privacy and Policy</span>
                    </Nav.Link>


                </div>
            </Nav>
            <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                    eventKey="1"
                    id="nav-admin"
                    role="tab"
                    aria-labelledby="nav-admin-tab"
                >
                   <SellerTearmsconditions/>
                </Tab.Pane>
                <Tab.Pane
                    eventKey="2"
                    id="nav-sellers"
                    role="tab"
                    aria-labelledby="nav-sellers-tab"
                >
                    <SellerPrivacypolicy/>
                </Tab.Pane>

            </Tab.Content>
        </Tab.Container>
    )
}

export default Sellertermsandconditions

