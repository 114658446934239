import { HttpInterceptor } from "../auth/http-interceptors";

export function getTransaction(page) {
  return HttpInterceptor.get(`/admin/transactions?limit=10&&page=${page}`);
}

export function getSubscriber() {
  return HttpInterceptor.get(`/admin/subscribers?limit=10`);
}

export function getTransactionByQuery(value) {
  return HttpInterceptor.get(`/admin/transactions?limit=10&search=${value}`);
}

export function getSubscriberByQuery(value) {
  return HttpInterceptor.get(`/admin/subscribers?limit=100&search=${value}`);
}
