import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import arrowleft from "../../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../../assets/loginLogos/rightarrow.png";
import Loader from "../../../../components/Loader";
import {
  OrdersListByStatus,
  OrdersListByStatusByQuery,
} from "../../../../services/list/listServices";
import "./styles/Allorders.scss";
import * as moment from "moment";
function InProgress() {
  const history = useNavigate();

  const [orderLimit, setOrderLimit] = useState(10);
  const [limit, setLimit] = useState([]);
  const [current, setCurrent] = useState(1);

  const tableheaders = [
    {
      header: "Order Id",
    },
    {
      header: "WomeynPatron",
    },
    {
      header: "Order Date",
    },
    {
      header: "WomeynPreneur",
    },
    {
      header: "Order",
    },
    // {
    //   header: "Delivery Date",
    // },
    {
      header: "Order Amount",
    },
    {
      header: "Order Status",
    },
  ];

  const [tables, setTables] = useState([]);

  useEffect(() => {
    getOrdersListByStatus();
  }, [current]);

  const getOrdersListByStatus = () => {
    OrdersListByStatus(3, current)
      .then((res) => {
        setTables(res.data.results);
        setLimit(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchCurrentData = async (current) => {
    const resdata = await OrdersListByStatus(3, current);
    setLimit(resdata?.data);
  };
  const handleAdminChangePagecount = async (e) => {
    setCurrent(e);
    const current = e;
    await fetchCurrentData(current);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const orderSearch = () => {
    OrdersListByStatusByQuery(3, searchText)
      .then((res) => {
        setTables(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  const loadMoreOrders = () => {
    setOrderLimit(orderLimit + 10);
  };
  if (!tables) {
    return <Loader />;
  }
  return (
    <div>
      <Col lg={6}>
        <div className="py-0 col-lg-10">
          <div className="form-group input-group mb-0">
            <input
              type="text"
              className="form-control"
              placeholder="Search for order"
              onChange={handleSearch}
            />
            <span className="input-group-text" style={{ cursor: "pointer" }}>
              <svg
                className="icon-20"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={orderSearch}
              >
                <circle
                  cx="11.7669"
                  cy="11.7666"
                  r="8.98856"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></circle>
                <path
                  d="M18.0186 18.4851L21.5426 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </Col>
      <Table hover className="mt-2">
        <thead>
          <tr>
            {tableheaders.map((item, index) => {
              return <th>{item.header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tables.map((item, index) => {
            return (
              <>
                <tr
                  className="cursor-texts"
                  onClick={() => history(`/womeyn/view-order/${item.orderId}`)}
                >
                  <td>{item?.orderId} </td>
                  <td>{item.customerName?item.customerName:"-"}</td>
                  <td>{moment(item?.orderedDate).format("LL")}</td>
                  <td>{item.sellerBusinessName}</td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {item.itemsOrdered[0].productImageName ? (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/${item.itemsOrdered[0].productImageName}`}
                          alt="no image"
                          width={30}
                          height={30}
                          style={{ borderRadius: "20%" }}
                        />
                      ) : (
                        <img
                          src={
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo13dMxSP1rF3oMPw_0yGj892NadEqEnQ_Uw&usqp=CAU"
                          }
                          alt="no image"
                          width={30}
                          height={30}
                          style={{ borderRadius: "20%" }}
                        />
                      )}
                    </div>
                  </td>
                  {/* <td>{item?.countryName}</td> */}
                  <td>A$ {item.totalOrderAmount}</td>
                  <td>
                    {item.stateId === 3 && (
                      <span
                        className="text-lights box-shdow"
                        style={{ backgroundColor: "orange", color: "black" }}
                      >
                        In Progress
                      </span>
                    )}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        className="pagination-data mt-4"
        total={limit.totalPages * 10}
        onChange={handleAdminChangePagecount}
        current={current}
        itemRender={PrevNextArrow}
        breakLabel="..."
      />
    </div>
  );
}

export default InProgress;
