import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { OrdersDetailsById } from "../../../../services/list/listServices";
import OrderDetails from "../OrderDetails";
import "./styles/Allorders.scss";
import Spinner from "react-bootstrap/Spinner";
import { DownloadBillInvoice, DownloadRevenue } from "../../../../services/invoicedownload_service/invoicedownload_service";
import { toast } from 'react-toastify';
export default function ViewOrderDetails() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { id } = useParams();
  const history = useNavigate();

  const [orderDetails, setOrderDetails] = useState("");
  const [invoiceloading, setInvoiceLoading] = useState(false);
  const [invoiceloading1, setInvoiceLoading1] = useState(false);
  const [billdata, setBillData] = useState("");
  const [billdata1, setBillData1] = useState("");

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = () => {
    OrdersDetailsById(id)
      .then((res) => {
        setOrderDetails(res.data.results);
      })
      .catch((err) => console.log(err));
  };



  const ViewInvoicedownload = (orderids) => {

    setInvoiceLoading(true);

    DownloadBillInvoice(orderids).then((res) => {
      setBillData(res?.data?.url);
      if (res?.data?.url) {
        window.open(res?.data?.url);
      }
      else {
        toast.error("Server Error. Try after some time.")

      }
      setTimeout(() => {
        setInvoiceLoading(false);
      }, 600);
    }).catch((err) => {
      console.log(err);
      setInvoiceLoading(false);
    })
  }

  const ViewRevenuedownload = (orderids) => {


    setInvoiceLoading1(true);

    DownloadRevenue(orderids).then((res) => {

      setBillData1(res?.data?.url);

      if (res?.data?.url) {
        window.open(res?.data?.url);
      }
      else {
        toast.error("Server Error. Try after some time.")

      }
      setTimeout(() => {
        setInvoiceLoading1(false);
      }, 600);
    }).catch((err) => {
      setInvoiceLoading1(false);
    })
  }




  if (!orderDetails) {
    return <Loader />;
  }

  return (
    <div>
      <h3 className="mb-4">Order Details</h3>
      {orderDetails && orderDetails?.map((item, index) => {
        return (
          <div key={index}>
            <Row>
              <Col>
                <Card style={{ padding: "0px" }}>
                  <Card.Header className="d-flex justify-content-between">
                    {/* <div className="header-title">
                      <h4 className="card-title">Customer Details</h4>
                    </div> */}
                  </Card.Header>
                  <Card.Body>
                    <div className="new-user-info">
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Name:</h6>
                          <p>{item.customerName}</p>
                        </div>
                        <div className="col-md-6">
                          <h6>Address:</h6>
                          <p>{item?.deliveryAddress[0].cityName}, {item?.deliveryAddress[0]?.stateName ? item?.deliveryAddress[0]?.stateName : "-"}, {item?.deliveryAddress[0]?.landMark ? item?.deliveryAddress[0]?.landMark : "-"}, {item?.deliveryAddress[0]?.countryName ? item?.deliveryAddress[0]?.countryName : "-"}, {item?.deliveryAddress[0]?.pinCode ? item?.deliveryAddress[0]?.pinCode : "."}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Payment Status</h6>
                          <p>{item.paymentStatus}</p>
                        </div>
                        <div className="col-md-6">
                          <h6>Payment Method</h6>
                          <p>{item.paymentMethod}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Ordered Date</h6>
                          <p>{moment(item.orderedDate).format("LLLL")}</p>
                        </div>
                        {/* <div className="col-md-6">
                          <h6>Item Price</h6>
                          <p>$ {item.itemsOrdered[0].price}</p>
                        </div> */}
                        <div className="col-md-6">
                          <h6>Order Status</h6>
                          <p>
                            {item.stateId === 1 && <span>New Order</span>}
                            {item.stateId === 2 && <span>Confirmed</span>}
                            {item.stateId === 3 && <span>In Progress</span>}
                            {item.stateId === 4 && (
                              <span>Cancelled by Buyer</span>
                            )}
                            {item.stateId === 5 && (
                              <span>Cancelled by WomeynPreneur</span>
                            )}
                            {item.stateId === 6 && <span>Delivered</span>}
                            {item.stateId === 7 && <p>Packing Completed</p>}
                            {item.stateId === 8 && <p>Shipping Completed</p>}
                            {item.stateId === 9 && <p>Out for Delivery</p>}
                          </p>
                        </div>
                        {/* <div className="col-md-6">
                          <h6>Item Price</h6>
                          <p>$ {item.itemsOrdered[0].price}</p>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Item Price</h6>
                          <p>A$ {item.itemsOrdered[0].price}</p>
                        </div>
                        <div className="col-md-6">
                          <h6>Order GST</h6>
                          <p>A$ {item.itemsOrdered[0].salePriceGST}</p>
                        </div>
                        {/* <div className="col-md-6">
                          <h6>Delivery Fee</h6>
                          <p>$ {item.itemsOrdered[0].deliveryFee}</p>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Delivery Fee</h6>
                          <p>A$ {item.itemsOrdered[0].deliveryFee}</p>
                        </div>
                        <div className="col-md-6">
                          <h6>Delivery GST</h6>
                          <p>A$ {item.itemsOrdered[0].deliveryChargeGST}</p>
                        </div>
                        {/* <div className="col-md-6">
                          <h6>Total Amount</h6>
                          <p>$ {item.totalOrderAmount}</p>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Coupon Code</h6>
                          <p>{item.couponCode ? item.couponCode : "-----"}</p>
                        </div>
                        <div className="col-md-6">
                          <h6>Discount Amount</h6>
                          <p>A$ {item.discountAmount}</p>
                        </div>
                        {/* <div className="col-md-6">
                          <h6>Total Amount</h6>
                          <p>$ {item.totalOrderAmount}</p>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Qunatity Ordered</h6>
                          {item.itemsOrdered?.map((items, index) => {
                            return (
                              <div key={index}>
                                {items?.quantity}
                              </div>
                            )
                          })}
                        </div>
                        <div className="col-md-6">
                          <h6>Total Amount</h6>
                          <p>A$ {item.totalOrderAmount}</p>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>






            <div className="">

              <div className="header-container">
                <p className="header-btn">{item?.orderId}</p>
                <div className="d-flex gap-2">
                  <div className="rightside-btn">

                    <Button
                      className="invoiceprint mx-5"
                      onClick={() => ViewRevenuedownload(orderDetails[0]?.orderId)}
                    >
                      {invoiceloading1 ? <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-2">Loading...</span>
                      </> : <> View Revenue</>}

                    </Button>
                  </div>

                  <div className="rightside-btn">
                    <Button
                      className="invoiceprint mx-5"
                      onClick={() => ViewInvoicedownload(orderDetails[0]?.orderId)}
                    >

                      <div className="d-flex gap-3">
                        <div>
                          {invoiceloading ? <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="ms-2">Loading...</span>
                          </> : <> View Invoice</>}
                        </div>
                      </div>
                    </Button>
                  </div>

                  {/* {item.shippingDetails ? (
      <div className="rightside-btn">
        {item.shippingDetails.status === "completed" ? (
          <Button
            className="invoiceprint mx-5"
            onClick={() =>
              window.open(
                item.shippingDetails.label_download.png,
                "_blank"
              )
            }
          >
            <div className="d-flex gap-2">
              <div>View Label</div>
            </div>
          </Button>
        ) : item.shippingDetails.status !== "cancelled" ||
          item.shippingDetails.status !== "completed" ? (
          <Button className="invoiceprint mx-5">
            <div className="d-flex gap-3">
              <div>Track Order</div>
            </div>
          </Button>
        ) : null}


      </div>
    ) : null} */}
                </div>
              </div>

            </div>
            {item.itemsOrdered.map((e) => {
              return (
                <>
                  <div className="mt-4">
                    <div className="content-container">
                      <div>
                        {e?.productThumbImage ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}/${e.productThumbImage}`}
                            className="set-img"
                          />
                        ) : (
                          <img
                            src={
                              "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fae01.alicdn.com%2Fkf%2FHTB1mnnhaYYI8KJjy0Faq6zAiVXa4%2FKids-Party-Wear-Frocks-Kids-Party-Dress-for-Winter-Girls-Puffy-Dresses-Children-Vintage-Dresses-Big.jpg&f=1&nofb=1&ipt=8ebd3f3ecdf1134c57d9f35534468b857f033a925d6c6bef13ba529599b4ddfd&ipo=images"
                            }
                            className="set-img"
                          />
                        )}
                      </div>
                      <div>
                        <p className="header-text">Name</p>
                        <div style={{
                          width: "300px"
                        }} className="mb-2">{e.productName}</div>
                        <p className="header-text">WomeynPreneur</p>
                        <span>{item.sellerBusinessName}</span>

                      </div>
                      <div>
                        <p className="header-text">Price</p>
                        <p>A$ {e.price}</p>
                        <p className="header-text">GST</p>
                        <p>A$ {item.itemsOrdered[0].salePriceGST}</p>
                      </div>
                      <div className="">
                        <h6>Qunatity Ordered</h6>
                        {item.itemsOrdered?.map((items, index) => {
                          return (
                            <div>
                              {items?.quantity}
                            </div>
                          )
                        })}

                        <div className="mt-3">
                          {e.variations ? (
                            <>
                              {e.variations.map((item) => {
                                return (
                                  <div className="">
                                    <p className="header-text">{item?.name}:</p>
                                    <p className="ms-1"> {item?.value}</p>
                                  </div>
                                );
                              })}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <p className="header-text">Expected Delivery</p>
                        <p>
                          {moment(
                            item.itemsOrdered[0].expectedDeliveryDate
                          ).format("LLLL")}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            <div className="footer-container mt-1 mb-5">
              <div></div>
              <p className="mb-0 fs-4">
                Order Total :
                <span className="text-black "> A$ {item.totalOrderAmount}</span>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
