import moment from "moment";
import React, { useCallback, useState, useMemo, useEffect } from "react";

import { Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Camera from "../../../../../assets/images/bxs_camera.svg";
import "./styles/Createblog.scss";

import { toast } from "react-toastify";
import b1 from "../../../../../assets/blogs/b1.png";

import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createEvent,
  getSingleEvent,
  updateEvent,
} from "../../../../../services/eventsservices/events_services";

function Createevents() {
  // const editor = useRef(null);
  const [content, setContent] = useState("");
  const [logs, setLogs] = useState([]);
  const { id } = useParams();
  const [blogimage, setBlogImage] = useState("");
  const history = useNavigate();

  const [selectimage, setSelectImage] = useState("");

  const [error, setError] = useState(false);

  const [eventforms, setEventForms] = useState({
    title: "",
    description: "",
    eventContent: "",
    keywords: "",
    startDate: "",
    endDate: "",
    eventLength: "",
    location: "",
    organizerName: "",
    organizationCompany: "",
    ticketingDetails: "",
    upl: "",
  });

  const {
    title,
    description,
    eventContent,
    eventLength,
    location,
    organizerName,
    organizationCompany,
    ticketingDetails,
    keywords,
    startDate,
    endDate,
  } = eventforms;

  const handleChange = (e) => {
    setEventForms({ ...eventforms, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setSelectImage(e?.target?.files[0]);
  };

  const SubmitBlogs = () => {
    if (
      title?.length === 0 ||
      description?.length === 0 ||
      content?.length === 0 ||
      keywords?.length === 0 ||
      startDate?.length === 0 ||
      endDate?.length === 0 ||
      eventLength?.length === 0 ||
      location?.length === 0 ||
      organizerName?.length === 0 ||
      organizationCompany?.length === 0 ||
      ticketingDetails?.length === 0
    ) {
      setError(true);
    }

    if (
      title &&
      description &&
      content &&
      keywords &&
      startDate &&
      endDate &&
      eventLength &&
      location &&
      organizerName &&
      organizationCompany &&
      ticketingDetails
    ) {
      const formsDatas = new FormData();
      formsDatas.append("title", title);
      formsDatas.append("description", description);
      formsDatas.append("eventContent", content);
      formsDatas.append("keywords", keywords);
      formsDatas.append("startDate", startDate);
      formsDatas.append("endDate", endDate);
      formsDatas.append("eventLength", eventLength);
      formsDatas.append("location", location);
      formsDatas.append("organizerName", organizerName);
      formsDatas.append("organizationCompany", organizationCompany);
      formsDatas.append("ticketingDetails", ticketingDetails);
      formsDatas.append("upl", selectimage);
      console.log(formsDatas, "formsDatas");
      createEvent(formsDatas)
        .then((res) => {
          toast.success("Event Created");
          setTimeout(() => {
            history("/womeyn/events");
          }, 800);
        })
        .catch((err) => {
          toast.error("error upload images");
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getSingleEvent(id)
      .then((res) => {

        setEventForms(res?.data);
        setContent(res?.data?.eventContent);
        // setSelectImage(res?.data?.postImageName)
        setBlogImage(res?.data?.eventImageName);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const EditBlog = () => {
    if (
      title?.length === 0 ||
      description?.length === 0 ||
      content?.length === 0 ||
      keywords?.length === 0 ||
      startDate?.length === 0 ||
      endDate?.length === 0 ||
      eventLength?.length === 0 ||
      location?.length === 0 ||
      organizerName?.length === 0 ||
      organizationCompany?.length === 0 ||
      ticketingDetails?.length === 0
    ) {
      setError(true);
    }

    if (
      title &&
      description &&
      content &&
      keywords &&
      startDate &&
      endDate &&
      eventLength &&
      location &&
      organizerName &&
      organizationCompany &&
      ticketingDetails
    ) {
      const formsDatas = new FormData();
      formsDatas.append("eventId", id);
      formsDatas.append("title", title);
      formsDatas.append("description", description);
      formsDatas.append("eventContent", content);
      formsDatas.append("keywords", keywords);
      formsDatas.append("startDate", startDate);
      formsDatas.append("endDate", endDate);
      formsDatas.append("eventLength", eventLength);
      formsDatas.append("location", location);
      formsDatas.append("organizerName", organizerName);
      formsDatas.append("organizationCompany", organizationCompany);
      formsDatas.append("ticketingDetails", ticketingDetails);
      formsDatas.append("upl", selectimage);
      updateEvent(formsDatas)
        .then((res) => {
          toast.success("Events Updated");
          setTimeout(() => {
            history("/womeyn/events");
          }, 800);
        })
        .catch((err) => {
          toast.error("error upload Blog");
          console.log(err);
        });
    }
  };

  const BackGo = () => {
    history("/womeyn/events");
  };

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter Event Content"

    }),
    []
  );

  const appendLog = useCallback(
    (message) => {
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );

  const onChange = useCallback(
    (newContent) => {
      appendLog(`onChange triggered with ${newContent}`);
    },
    [appendLog]
  );

  useEffect(() => {
  }, [onChange]);

  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );
  return (
    <div>
      <Card>
        <Card.Body className="m-0 p-0">
          <div className="p-2">
            <h4>Event Create</h4>
          </div>
          <div className="col-lg-12 mt-2 mb-5">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                onChange={handleChange}
                value={title}
                name="title"
              />
              <Form.Text className="text-muted">
                {error && title?.length <= 0 ? (
                  <span className="text-danger">Title is Required</span>
                ) : (
                  ""
                )}
              </Form.Text>
            </Form.Group>
          </div>

          <Row>
            <Col lg="6">
              <div className="col-lg-12 mt-2 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Event Length</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter eventLength"
                    onChange={handleChange}
                    value={eventLength}
                    name="eventLength"
                  />
                  <Form.Text className="text-muted">
                    {error && eventLength?.length <= 0 ? (
                      <span className="text-danger">
                        Event Length is Required
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col lg="6">
              <div className="col-lg-12 mt-2 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Location </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter location"
                    onChange={handleChange}
                    value={location}
                    name="location"
                  />
                  <Form.Text className="text-muted">
                    {error && location?.length <= 0 ? (
                      <span className="text-danger">Location is Required</span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <div className="col-lg-12 mt-2 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Organizer Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter organizerName"
                    onChange={handleChange}
                    value={organizerName}
                    name="organizerName"
                  />
                  <Form.Text className="text-muted">
                    {error && organizerName?.length <= 0 ? (
                      <span className="text-danger">
                        Organizer Name is Required
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col lg="6">
              <div className="col-lg-12 mt-2 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Organization Company </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter organizationCompany"
                    onChange={handleChange}
                    value={organizationCompany}
                    name="organizationCompany"
                  />
                  <Form.Text className="text-muted">
                    {error && organizationCompany?.length <= 0 ? (
                      <span className="text-danger">
                        Organization Company is Required
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <div className="col-lg-12 mt-2 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter start Date"
                    onChange={handleChange}
                    value={startDate}
                    name="startDate"
                    min={moment().format("YYYY-MM-DD")}
                  />
                  <Form.Text className="text-muted">
                    {error && startDate?.length <= 0 ? (
                      <span className="text-danger">
                        Start Date is Required
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col lg="6">
              <div className="col-lg-12 mt-2 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter end date"
                    onChange={handleChange}
                    value={endDate}
                    name="endDate"
                    min={startDate}
                  />
                  <Form.Text className="text-muted">
                    {error && endDate?.length <= 0 ? (
                      <span className="text-danger">End Date is Required</span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <div className="col-lg-12 mb-5">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Keywords</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter keywords"
                    onChange={handleChange}
                    value={keywords}
                    name="keywords"
                  />
                  <Form.Text className="text-muted">
                    {error && keywords?.length <= 0 ? (
                      <span className="text-danger">Keywords is Required</span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Ticketing Details</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter ticketingDetails"
                    onChange={handleChange}
                    value={ticketingDetails}
                    name="ticketingDetails"
                  />
                  <Form.Text className="text-muted">
                    {error && ticketingDetails?.length <= 0 ? (
                      <span className="text-danger">
                        Ticketing Details is Required
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className="col-lg-12 mb-5">
            {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}
            <Form.Label>Event Content</Form.Label>
            {/* <JoditEditor
                ref={editor}
                value={content}
                placeholder="Enter Event contents"
                onChange={(newContent) => setContent(newContent)}
                style={{ height: "100px" }}
              />
              <Form.Text className="text-muted">
                {error && content?.length <= 0 ? (
                  <span className="text-danger">Event content is Required</span>
                ) : (
                  ""
                )}
              </Form.Text> */}

            <JoditEditor
              value={content}
              config={config}
              tabIndex={1}
              onBlur={onBlur}
              onChange={onChange}
            />


            <Form.Text className="text-muted">
              {error && content?.length <= 0 ? <div className="text-danger">Content is Required</div> : null}

            </Form.Text>
            {/* </Form.Group> */}
          </div>

          <div className="col-lg-12 mb-5">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Description</Form.Label>

              <Form.Control
                as="textarea"
                placeholder="Enter description"
                onChange={handleChange}
                value={description}
                name="description"
                style={{ height: "200px" }}
              />

              <Form.Text className="text-muted">
                {error && description?.length <= 0 ? (
                  <span className="text-danger">Description is Required</span>
                ) : (
                  ""
                )}
              </Form.Text>
            </Form.Group>
          </div>
          <div className="mt-3 mb-5">
            <div className="text-danger mt-2 mb-3 cursor-pointer">
              Image Size Maximum Dimensions are 1154 x 340 pixels.
            </div>
            {id ? (
              <>
                {selectimage ? (
                  <div className="boximage">
                    <img
                      src={URL.createObjectURL(selectimage)}
                      alt="no image"
                      className="blogbannerimage"
                    />
                    <div>
                      <div>
                        <input
                          id="upload-button1"
                          type="file"
                          name="myImage"
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                        />
                      </div>
                      <label htmlFor="upload-button1">
                        <img
                          src={b1}
                          alt="no image"
                          className="editblogimage"
                        />
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="boximage">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}/${blogimage}`}
                      alt="no image"
                      className="blogbannerimage"
                    />
                    <div>
                      <div>
                        <input
                          id="upload-button1"
                          type="file"
                          name="myImage"
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                        />
                      </div>
                      <div className="editblogimageedit">
                        <label htmlFor="upload-button1">
                          <img
                            src={b1}
                            alt="no image"
                            className="editblogimage"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {selectimage ? (
                  <div className="boximage">
                    <img
                      src={URL.createObjectURL(selectimage)}
                      alt="no image"
                      className="blogbannerimage"
                    />
                    <div>
                      <div>
                        <input
                          id="upload-button1"
                          type="file"
                          name="myImage"
                          accept="image/png,image/jpeg,image/jpg"
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                        />
                      </div>
                      <div className="editblogimageedit">
                        <label htmlFor="upload-button1">
                          <img
                            src={b1}
                            alt="no image"
                            className="editblogimage"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4 mb-3">
                    <label htmlFor="upload-button">
                      <div className="main-camera-sections">
                        <div className="inside-camera-section-upload">
                          <img src={Camera} alt="no image" className="camers" />
                        </div>
                      </div>
                    </label>

                    <div>
                      <input
                        id="upload-button"
                        type="file"
                        name="myImage"
                        accept="image/png,image/jpeg,image/jpg"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            <Form.Text className="text-muted">
              {error && selectimage?.length <= 0 ? (
                <span className="text-danger">Image is Required</span>
              ) : (
                ""
              )}
            </Form.Text>
          </div>

          <div className="buttonsections">
            <div>
              {id ? (
                <button className="savebutton" onClick={EditBlog}>
                  Update
                </button>
              ) : (
                <button className="savebutton" onClick={SubmitBlogs}>
                  Save
                </button>
              )}
            </div>
            <div>
              <button className="cancelbutton" onClick={BackGo}>
                Cancel
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Createevents;
