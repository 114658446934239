import { Fragment, memo, useEffect, useState } from "react";
import { Button, Col, Form, Nav, Row, Tab, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import ProductImg from "../../../assets/product_logo.png";
import Loader from "../../../components/Loader";
import { getServiceCategory } from "../../../services/category/categoryServices";
import {
  ProductListServices,
  ServicesList,
} from "../../../services/list/listServices";
import {
  addRecommendation,
  getRecommendation,
} from "../../../services/topPicks/topPicks";

// const Options = [
//   { value: "Item One", label: "Item One" },
//   { value: "Item Two", label: "Item Two" },
//   { value: "Item Three", label: "Item Three" },
//   { value: "Item Four", label: "Item Four" },
//   { value: "Item Five", label: "Item Five" },
// ];

const TopServices = memo((props) => {
 
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [options, setOptions] = useState([{ value: "", label: "", id: "" }]);
  const [countpage, setCountPage] = useState(10);

  const [topServices, setTopServices] = useState();
  const [loading, setLoading] = useState(false);
  const [addStatus, setAddStatus] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const [recommendationId, setRecommendationId] = useState("");

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption.id);
  };

  useEffect(() => {
    setAddStatus(false);
    if (props.props === "Top Services") {
      services();
      getServices();
    }
  }, [addStatus]);
  const services = () => {
    ServicesList(countpage)
      .then((res) => {
        let service = res.data.results.map((e) => {
          return {
            value: e.serviceName,
            label: e.serviceName,
            id: e.id,
          };
        });
        setCountPage(res.data.results?.length)
        setOptions(service);
      })
      .catch((err) => console.log(err));
  };

  useEffect(()=>{

  },[countpage])

  const getServices = () => {
    getRecommendation(2)
      .then((res) => {
        let result = res.data.map((e) => {
          return e?.results;
        });
        setTopServices(result);
      })
      .catch((err) => console.log(err));
  };

  const selectServices = () => {
    setLoading(true);
    let data = {
      itemId: selectedOption,
      typeId: 2,
    };
    addRecommendation(data)
      .then((res) => {
        setAddStatus(true);
        setLoading(false);
        setShow(false);
        // window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const editService = () => {
    setLoading(true);
    let data = {
      recommendationId: recommendationId,
      newItemId: selectedOption,
      typeId: 2,
    };
    addRecommendation(data)
      .then((res) => {
        setAddStatus(true);
        setLoading(false);
        setEditShow(false);
        // window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const deleteService = () => {
    setLoading(true);
    let data = {
      recommendationId: recommendationId,
      stateId: 5,
    };
    addRecommendation(data)
      .then((res) => {
        setAddStatus(true);
        setLoading(false);
        setDeleteShow(false);
        // window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const enableService = (id, stateId) => {
    setLoading(true);
    let data;

    stateId === 1
      ? (data = {
          recommendationId: id,
          stateId: 3,
        })
      : (data = {
          recommendationId: id,
          stateId: 1,
        });
    addRecommendation(data)
      .then((res) => {
        setAddStatus(true);
        setLoading(false);
        setDeleteShow(false);
        // window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const handleEditShow = (id) => {
    setEditShow(true);
    setRecommendationId(id);
  };

  const handleEditClose = () => {
    setEditShow(false);
  };

  const handleDeleteShow = (id) => {
    setDeleteShow(true);
    setRecommendationId(id);
  };

  const handleDeleteClose = () => {
    setDeleteShow(false);
  };

  const handleShow = (id) => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  if (!topServices) {
    return <Loader />;
  }

  return (
    <Fragment className="mb-7">
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <div>
          {props.props === "Top Services" ? (
            <div className="mt-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: "23px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Top Services
                </p>
                <Button onClick={() => handleShow()}>
                  + Select New Service
                </Button>
              </div>
              <Row>
                {topServices.map((item) => {
                  return item.map((data) => {
                    return (
                      <>
                        <Col lg="4" md="2">
                          <div
                            style={{
                              border: "1px solid #C0C0C0",
                              borderRadius: "5px",
                              marginTop: "5%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginLeft: "3%",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "5%",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "#E95A5A",
                                }}
                              >
                                {data.productName}
                              </p>
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}/${data.productThumbImage}`}
                                alt="Service Img"
                                style={{ width: 80, height: 80 }}
                              />
                            </div>
                            <p
                              style={{
                                fontSize: "13px",
                                fontWeight: "400",
                                color: "black",
                                marginTop: "5%",
                                marginLeft: "3%",
                              }}
                            >
                              Best selling products in clothing & fashion
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "3%",
                                borderTop: "1px solid #C0C0C0",
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: "white",
                                  border: "1px solid #C0C0C0",
                                  borderRadius: "5px",
                                  padding: "1%",
                                  paddingRight: "5%",
                                  paddingLeft: "5%",
                                }}
                                onClick={() => handleEditShow(data._id)}
                              >
                                Change
                              </button>
                              <button
                                style={{
                                  backgroundColor: "white",
                                  border: "1px solid #C0C0C0",
                                  borderRadius: "5px",
                                  padding: "1%",
                                  paddingRight: "5%",
                                  paddingLeft: "5%",
                                }}
                                onClick={() => handleDeleteShow(data._id)}
                              >
                                Delete
                              </button>
                              <Form.Check className="form-switch">
                                <Form.Check.Input
                                  type="checkbox"
                                  style={{
                                    height: "20px",
                                    width: "40px",
                                    // backgroundColor: "#C0C0C0",
                                  }}
                                  defaultChecked={
                                    data.stateId === 1 ? true : false
                                  }
                                  onChange={() =>
                                    enableService(data._id, data.stateId)
                                  }
                                />
                              </Form.Check>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  });
                })}
              </Row>
            </div>
          ) : null}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          // centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "red" }}>Select Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="field-container">
              <Form.Label htmlFor="validationServer01">
                <h6>Select One of the Services:</h6>
              </Form.Label>
              {/* <Form.Control style={{ color: "black" }}> */}
              <Select
                value={selectedOption.id}
                onChange={handleChange}
                options={options}
              />
              {/* </Form.Control> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={selectServices}>
              {loading ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={editShow}
          onHide={handleEditClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          // centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "red" }}>Select Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="field-container">
              <Form.Label htmlFor="validationServer01">
                <h6>Select One of the Products:</h6>
              </Form.Label>
              {/* <Form.Control style={{ color: "black" }}> */}
              <Select
                value={selectedOption.id}
                onChange={handleChange}
                options={options}
              />
              {/* </Form.Control> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={editService}>
              {loading ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={deleteShow}
          onHide={handleDeleteClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          // centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "red" }}>Alert..!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you really want to delete this Service?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={deleteService}>
              {loading ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Delete"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
});

export default TopServices;
