import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import b3 from "../../../../../assets/blogs/b1.png";
import b2 from "../../../../../assets/blogs/b2.png";
import {
  addSubscription,
  getSubscription,
} from "../../../../../services/subscription/subscriptionServices";
import "./styles/Productplan.scss";

function Productplans() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    getProductPlan();
  }, [loading]);

  const getProductPlan = () => {
    getSubscription()
      .then((res) => {
        let data = res.data.results.filter((e) => e.planTypeId === 1);
        setData(data);
      })
      .catch((err) => console.log(err));
  };

  const handleRemove = (id) => {
    let data = {
      planId: id,
      stateId: 5,
    };
    Swal.fire({
      icon: "error",
      title: "Are you sure?",
      text: "You want to delete this item",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        addSubscription(data)
          .then((res) => {
            setLoading(true);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const router = useNavigate();

  const NavigateProductForm = () => {
    router("/womeyn/productplan");
  };
  const NavigateProductFormEdit = (id) => {
    router(`/womeyn/productplan/${id}`);
  };
  return (
    <div className="productplans">
      <div className="d-flex justify-content-end align-items-center  mb-5 mt-3">
        {data && (
          <button className="AddBlog" onClick={NavigateProductForm}>
            + Add Product Plans
          </button>
        )}
      </div>

      <div className="cards row ">
        {data?.map((item, index) => {
          return (
            <div className="card col-lg-4">
              <div className="text-center mb-3 mt-4 planname">{item.planName}</div>
              <div className="text-center mb-3 mt-4">
                {item.planDescription}
              </div>
              <div className="mt-5 mb-4">
                <h4>A$ {item.planPrice}</h4>
              </div>
              <div className="mt-2 mb-4">
                Product List - {item.allowedProducts}
              </div>
              <div>
                {ReactHtmlParser(item?.planDetails.replace(/&lt;/g, "<"))}
              </div>

              <div className="productplaniconssections">
                <div onClick={() => NavigateProductFormEdit(item.id)}>
                  <img src={b3} alt="no image" className="iconedits" />
                </div>
                <div onClick={() => handleRemove(item.id)}>
                  <img src={b2} alt="no image" className="iconedits" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Productplans;
