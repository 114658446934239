import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Button, Card, Col, Dropdown, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader.js";
import {
  DashboardServices,
  TopSellerServices,
  TopSellerServicesFilter,
  UserEngagementServicesFilter,
  getPopularProducts,
  getRevenue,
} from "../../../services/dashboard/dashboardServices.js";
import * as SettingSelector from "../../../store/setting/selectors.ts";
import Search from "./Search.svg";
import DarkArrow from "./darkArrow.svg";
import "./dashboard.scss";
import Down from "./down.svg";
import Four from "./four.svg";
import Left from "./leftArrow.svg";
import LightArrow from "./lightArrow.svg";
import One from "./one.svg";
import Star from "./star.svg";
import Three from "./three.svg";
import Two from "./two.svg";

function Index() {
  // const productData = [
  //   {
  //     name: "Biker’s Jacket",
  //     img: Jacket,
  //     rate: 3.7,
  //   },
  //   {
  //     name: "Biker’s Jacket",
  //     img: Jacket,
  //     rate: 3.7,
  //   },
  //   {
  //     name: "Biker’s Jacket",
  //     img: Jacket,
  //     rate: 3.7,
  //   },
  //   {
  //     name: "Biker’s Jacket",
  //     img: Jacket,
  //     rate: 3.7,
  //   },
  // ];

  useSelector(SettingSelector.theme_color);

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [variableColors.primary, variableColors.info];
  // useEffect(() => {
  //   return () => colors;
  // });

  const [revenue, setRevenue] = useState([]);

  const chart1 = {
    options: {
      chart: {
        fontFamily:
          '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          minWidth: 19,
          maxWidth: 19,
          style: {
            colors: "#8A92A6",
          },
          offsetX: -5,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        labels: {
          minHeight: 22,
          maxHeight: 22,
          show: true,
          style: {
            colors: "#8A92A6",
          },
        },
        lines: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      responsive: [
        {
          breakpoint: 1399,
          options: {
            chart: {
              height: 320,
            },
          },
        },
      ],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.4,
          opacityTo: 0.1,
          stops: [0, 50, 80],
          colors: ["#3a57e8"],
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    series: [
      {
        name: "total",
        // data: [30, 60, 20, 60, 25, 80, 40, 70, 10, 40, 76, 55],
        data: revenue,
      },
    ],
  };

  const [newUser, setNewUser] = useState();
  const [repeatedUser, setRepeatedUser] = useState();
  const [newUserCount, setNewUserCount] = useState();
  const [repeatedUserCount, setRepeatedUserCount] = useState();

  const chart4 = {
    options: {
      colors: colors,
      plotOptions: {
        radialBar: {
          inverseOrder: false,
          endAngle: 360,
          hollow: {
            margin: 5,
            size: "50%",
            background: "transparent",
            imageWidth: 150,
            imageHeight: 150,
            imageClipped: true,
            position: "front",
            dropShadow: {
              enabled: false,
              blur: 3,
              opacity: 0.5,
            },
          },
          track: {
            show: true,
            background: "#f2f2f2",
            strokeWidth: "70%",
            opacity: 1,
            margin: 6,
            dropShadow: {
              enabled: false,
              blur: 3,
              opacity: 0.5,
            },
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: "16px",
              fontWeight: 600,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "14px",
              fontWeight: 400,
              offsetY: 16,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      labels: ["Male", "Female"],
    },
    series: [newUser, repeatedUser],
  };

  const [dashboardData, setDashboardData] = useState("");
  const [topSeller, setTopSeller] = useState([]);
  const [filteredSeller, setFilteredSeller] = useState("");
  const [filter, setFilter] = useState("Year");
  const [engageFilter, setEngageFilter] = useState("week");
  const [productData, setProductData] = useState([]);

  const goBack = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("permissions");

    window.location.assign("/");
  };

  useEffect(() => {
    dashboardDetails();
    topSellers();
    userEngageMent();
    popularProducts();
    revenueDetails();
  }, []);

  const dashboardDetails = () => {
    DashboardServices()
      .then((res) => {

        setDashboardData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const topSellers = () => {
    TopSellerServices()
      .then((res) => {
        setTopSeller(res.data);
      })
      .catch((err) => console.log(err));
  };

  const popularProducts = () => {
    getPopularProducts()
      .then((res) => {
        setProductData(res.data[0].results);
      })
      .catch((err) => console.log(err));
  };

  const revenueDetails = () => {
    getRevenue()
      .then((res) => {
        const data = res.data.map((e) => e.total);
        setRevenue(data);
        // set(res.data[0].results);
      })
      .catch((err) => console.log(err));
  };

  const userEngageMent = () => {
    UserEngagementServicesFilter("week")
      .then((res) => {
        let totalUser = res.data.totalNewUsers + res.data.totalRepeatUsers;
        let newUser = (res.data.totalNewUsers / totalUser) * 100;
        let registeredUser = (res.data.totalRepeatUsers / totalUser) * 100;
        setNewUserCount(res.data.totalNewUsers);
        setRepeatedUserCount(res.data.totalRepeatUsers);
        setNewUser(newUser);
        setRepeatedUser(registeredUser);
      })
      .catch((err) => console.log(err));
  };

  // const filterTopSellers = (value) => {
  //   console.log("value", value);
  //   TopSellerServicesFilter(value)
  //     .then((res) => {
  //       console.log("singleOrder", res.data);
  //       setTopSeller(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  if (dashboardData === "") {
    return <Loader />;
  }

  return (
    <div className="p-5">
      <div>
        <p className="summary-heading">Summary</p>
        <div className="d-flex justify-content-between">
          <p className="welcome-text">Welcome Back to Dashboard.</p>
        </div>
      </div>
      <div className="mt-4">
        <Row>
          <Col lg="6">
            <Row>
              <Col md="6">
                <Card className="small-card">
                  <Card.Body>
                    <div className="smallcard-inside-container">
                      <img className="smallcard-inside-img" src={One} />
                      <p className="smallcard-inside-numtext">
                        {dashboardData?.totalVendors && dashboardData?.totalVendors || 0}
                      </p>
                      <p className="smallcard-inside-text">
                        Active / Total WomeynPreneurs
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6">
                <Card className="small-card">
                  <Card.Body>
                    <div className="smallcard-inside-container">
                      <img src={Two} className="smallcard-inside-img" />
                      <p className="smallcard-inside-numtext">
                        {dashboardData?.totalProducts && dashboardData?.totalProducts || 0}
                      </p>
                      <p className="smallcard-inside-text">
                        Active / Total Products
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="small-card">
                  <Card.Body>
                    <div className="smallcard-inside-container">
                      <img src={Three} className="smallcard-inside-img" />
                      <p className="smallcard-inside-numtext">
                        ${dashboardData?.totalRevenue[0]?.total && dashboardData?.totalRevenue[0]?.total || 0}
                      </p>
                      <p className="smallcard-inside-text">
                        Total Sales / Revenue
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6">
                <Card className="small-card">
                  <Card.Body>
                    <div className="smallcard-inside-container">
                      <img src={Four} className="smallcard-inside-img" />
                      <p className="smallcard-inside-numtext">
                        ${dashboardData?.avgOrderAmount[0]?.avgOrderAmount && dashboardData?.avgOrderAmount[0]?.avgOrderAmount || 0}
                      </p>
                      <p className="smallcard-inside-text">Avg Order Value</p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6">
            <Card className="rightside-card-container">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <h4>Revenue</h4>
                </div>
              </div>
              <Card.Body>
                <Chart
                  options={chart1.options}
                  series={chart1.series}
                  type="area"
                  height="350"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col lg="7">
            <Card>
              <Card.Header className="flex-wrap align-items-center p-0">
                <div className="d-flex justify-content-between">
                  <p className="topseller-text">Top WomeynPreneurs</p>
                  <div className="d-flex align-items-center">
                    <Dropdown className="ms-2">
                      <Dropdown.Toggle
                        variant="text-secondary dropdown-toggle"
                        id="dropdownMenuButton2"
                      >
                        This {filter?filter:null}
                        <img src={DarkArrow} className="darkarrow-img" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu aria-labelledby="dropdownMenuButton2">
                        <Dropdown.Item
                          onClick={() => {
                            TopSellerServicesFilter("year")
                              .then((res) => {
                                setFilter("Year");
                                setTopSeller(res.data);
                              })
                              .catch((err) => console.log(err));
                          }}
                        >
                          Year
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            TopSellerServicesFilter("month")
                              .then((res) => {
                                setFilter("Month");
                                setTopSeller(res.data);
                              })
                              .catch((err) => console.log(err));
                          }}
                        >
                          Month
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            TopSellerServicesFilter("week")
                              .then((res) => {
                                setFilter("Week");
                                setTopSeller(res.data);
                              })
                              .catch((err) => console.log(err));
                          }}
                        >
                          Week
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="mt-4 table-responsive  ">
                  <Table className="data-table list-table mb-0" role="grid">
                    <thead>
                      <tr className="added-line">
                        <th>Image</th>
                        <th>Profiles</th>
                        <th>Revenue</th>
                        <th>Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topSeller && topSeller?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                {item.profileImageName ? (
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${item.profileImageName}`}
                                    alt="no image"
                                    className="image-circle"
                                  />
                                ) : (
                                  <img
                                    src={
                                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo13dMxSP1rF3oMPw_0yGj892NadEqEnQ_Uw&usqp=CAU"
                                    }
                                    alt="no image"
                                    className="image-circle"
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <h6>{item.firstName}</h6>
                              <p className="mb-0">{item.businessName}</p>
                            </td>
                            <td>
                              <strong>${item.totalOrderAmount?.toFixed(2)}</strong>
                            </td>
                            <td>{item.count}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="5">
            <Card className="rightside-1st-container">
              <Card.Header className="rightside-1st-cardheader">
                <div className="d-flex justify-content-between">
                  <p className="user-text">WomeynPatron Engagement</p>
                  <div className="d-flex align-items-center">
                    <Dropdown className="ms-2 user-week-dropdown">
                      <Dropdown.Toggle
                        variant="text-secondary dropdown-toggle"
                        id="dropdownMenuButton2"
                      >
                        This {engageFilter}
                        <img src={DarkArrow} className="user-darkarrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu aria-labelledby="dropdownMenuButton2">
                        <Dropdown.Item
                          onClick={() => {
                            UserEngagementServicesFilter("year")
                              .then((res) => {
                                console.log("UserEnagement", res.data);
                                let totalUser =
                                  res.data.totalNewUsers +
                                  res.data.totalRepeatUsers;
                                let newUser =
                                  (res.data.totalNewUsers / totalUser) * 100;
                                let registeredUser =
                                  (res.data.totalRepeatUsers / totalUser) * 100;
                                setNewUserCount(res.data.totalNewUsers);
                                setRepeatedUserCount(res.data.totalRepeatUsers);
                                setNewUser(newUser);
                                setRepeatedUser(registeredUser);
                                setEngageFilter("Year");
                              })
                              .catch((err) => console.log(err));
                          }}
                        >
                          Year
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            UserEngagementServicesFilter("month")
                              .then((res) => {
                                console.log("UserEnagement", res.data);
                                let totalUser =
                                  res.data.totalNewUsers +
                                  res.data.totalRepeatUsers;
                                let newUser =
                                  (res.data.totalNewUsers / totalUser) * 100;
                                let registeredUser =
                                  (res.data.totalRepeatUsers / totalUser) * 100;
                                setNewUserCount(res.data.totalNewUsers);
                                setRepeatedUserCount(res.data.totalRepeatUsers);
                                setNewUser(newUser);
                                setRepeatedUser(registeredUser);
                                setEngageFilter("Month");
                              })
                              .catch((err) => console.log(err));
                          }}
                        >
                          Month
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            UserEngagementServicesFilter("week")
                              .then((res) => {
                                console.log("UserEnagement", res.data);
                                let totalUser =
                                  res.data.totalNewUsers +
                                  res.data.totalRepeatUsers;
                                let newUser =
                                  (res.data.totalNewUsers / totalUser) * 100;
                                let registeredUser =
                                  (res.data.totalRepeatUsers / totalUser) * 100;
                                setNewUserCount(res.data.totalNewUsers);
                                setRepeatedUserCount(res.data.totalRepeatUsers);
                                setNewUser(newUser);
                                setRepeatedUser(registeredUser);
                                setEngageFilter("Week");
                              })
                              .catch((err) => console.log(err));
                          }}
                        >
                          Week
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="rightside-1st-cardbody">
                <div className="d-flex align-items-center justify-content-between  flex-wrap">
                  <Chart
                    options={chart4.options}
                    series={chart4.series}
                    type="radialBar"
                    className="col-md-7 col-lg-7"
                    height="250"
                  />
                  <div className="d-grid gap col-md-5 col-lg-5">
                    <div className="d-flex align-items-start">
                      <svg
                        className="mt-2 text-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g>
                          <circle
                            cx="12"
                            cy="12"
                            r="8"
                            fill="currentColor"
                          ></circle>
                        </g>
                      </svg>
                      <div className="ms-2">
                        <span className="mb-3">New Registration</span>
                        <h6 className="mb-0">{newUserCount}</h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-start">
                      <svg
                        className="mt-2 text-info"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g>
                          <circle
                            cx="12"
                            cy="12"
                            r="8"
                            fill="currentColor"
                          ></circle>
                        </g>
                      </svg>
                      <div className="ms-2">
                        <span className="mb-3">Repeat Users</span>
                        <h6 className="mb-0">{repeatedUserCount}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className="rightside-2nd-container">
              <p className="popular-text">Popular Products</p>
              {productData?.map((data) => (
                <div className="d-flex justify-content-between mt-3">
                  <div className="product-img-container">
                    <img
                      // className="product-img"
                      alt="no image"
                      width={50}
                      height={50}
                      style={{ borderRadius: "20%" }}
                      src={`${process.env.REACT_APP_IMAGE_URL}/${data.productThumbImage}`}
                    />
                  </div>
                  <div className="product-center-container">
                    <p>{data.productName}</p>
                    <div className="d-flex">
                      <img src={Star} />
                      <p className="product-rate-text">
                        {Math.round(data?.averageRating)}
                      </p>
                    </div>
                  </div>
                  <div className="product-arrow">
                    <img src={Left} />
                  </div>
                </div>
              ))}
              {/* <Dropdown className="mt-5">
                <Dropdown.Toggle>
                  See all top selling products{" "}
                  <img
                    src={LightArrow}
                    className="product-lightarrow-img"
                    style={{ marginLeft: "5%" }}
                  />
                </Dropdown.Toggle>
              </Dropdown> */}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Index;
