import { Fragment, memo, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import {
  getCouponDetails,
  updateCoupon,
} from "../../../services/coupons/couponServices";
import "./styles.scss";

const EditCoupon = memo((props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [updateForm, setUpdateForm] = useState({
    title: "",
    couponCode: "",
    couponType: "",
    maxDiscountAmount: "",
    minOrderAmount: "",
    couponDescription: "",
    startDate: "",
    endDate: "",
    createdBy: "",
    offerPercentage: "",
    offerAmount: "",
  });

  const {
    title,
    couponCode,
    couponType,
    maxDiscountAmount,
    minOrderAmount,
    couponDescription,
    startDate,
    endDate,
    offerPercentage,
    offerAmount,
  } = updateForm;


  const updateHandleChanges = (e) => {
    setUpdateForm({ ...updateForm, [e.target.name]: [e.target.value][0] });
  };

  const update = () => {
    let data;

    data = {
      title: title,
      offerPercentage: offerPercentage,
      offerAmount: offerAmount,
      couponCode: couponCode,
      couponType: couponType,
      minOrderAmount: minOrderAmount,
      maxDiscountAmount: maxDiscountAmount,
      couponDescription: couponDescription,
      startDate: startDate,
      endDate: endDate,
    };

    updateCoupon(updateForm.id, data)
      .then((res) => {
        setLoading(false);
        navigate("/womeyn/coupons");
      })
      .catch((err) => console.log(err));
  };

  const getCoupon = () => {
    getCouponDetails(params.id).then((res) => {
      setUpdateForm(res.data);
    });
  };

  useEffect(() => {
    getCoupon();
  }, []);

  return (
    <Fragment className="mb-7">
      <div className="header">
        <h2 className="text-primary">Coupons</h2>
      </div>

      <div>
        <div>
          <div style={{ border: "1px solid #D1D6E3", borderRadius: "5px" }}>
            <div className="p-3 ps-5">
              <h4 className="text-dark">Select your coupon duration</h4>
              <Form>
                <Row>
                  <Col md="3" className="mb-3 mt-2">
                    <Form.Label md="6" htmlFor="validationDefault01">
                      Start Date
                    </Form.Label>
                    <Form.Group
                      className="input-group"
                      style={{
                        border: "1px solid #828793",
                        borderRadius: "5px",
                      }}
                    >
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                      ></span>
                      <Form.Control
                        style={{
                          borderLeft: "1px solid #828793",
                          textTransform: "uppercase",
                        }}
                        type="date"
                        id="startDate"
                        name="startDate"
                        aria-label="startDate"
                        aria-describedby="basic-addon1"
                        value={startDate.slice(0, 10)}
                        required
                        onChange={updateHandleChanges}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3" className="mb-3 mt-2">
                    <Form.Label htmlFor="validationDefault02">
                      End Date
                    </Form.Label>
                    <Form.Group
                      className="input-group"
                      style={{
                        border: "1px solid #828793",
                        borderRadius: "5px",
                      }}
                    >
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                      ></span>
                      <Form.Control
                        style={{
                          borderLeft: "1px solid #828793",
                          textTransform: "uppercase",
                        }}
                        type="date"
                        name="endDate"
                        id="endDate"
                        aria-label="endDate"
                        aria-describedby="basic-addon1"
                        required
                        value={endDate.slice(0, 10)}
                        onChange={updateHandleChanges}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
        <div className="content-margin">
          <h3 className="ms-5">Set Discount</h3>
          <div style={{ border: "1px solid #D1D6E3", borderRadius: "5px" }}>
            <div className="p-3 ps-3">
              <p className="text-dark p-3 pt-1" style={{ width: "60%" }}>
                Please select the discount type that you want to apply.
              </p>
              {couponType === 2 ? (
                <>
                  <Form>
                    <Form.Group>
                      <Form.Check className="d-block ms-3">
                        <Form.Check.Input
                          className="me-2"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={true}
                        />
                        <Form.Check.Label htmlFor="flexRadioDefault1 text-dark">
                          <p className="ms-5 mb-0" style={{ color: "black" }}>
                            Percentage Off
                          </p>
                        </Form.Check.Label>
                      </Form.Check>
                      <Row>
                        <Col md="6" className="mb-1">
                          <Form.Label md="6" htmlFor="validationDefault01">
                            <p className="ms-3 mb-0">Discount</p>
                          </Form.Label>

                          <Form.Control
                            style={{
                              border: "1px solid #000",
                              marginLeft: 15,
                            }}
                            type="text"
                            name="offerPercentage"
                            id="offerPercentage"
                            value={offerPercentage}
                            onChange={updateHandleChanges}
                            maxLength={2}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-1">
                          <Form.Label md="6" htmlFor="validationDefault01">
                            <p className="ms-3 mb-0">Maximum Discount Amount</p>
                          </Form.Label>

                          <Form.Control
                            style={{ border: "1px solid #000", marginLeft: 15 }}
                            type="text"
                            name="maxDiscountAmount"
                            id="maxDiscountAmount"
                            onChange={updateHandleChanges}
                            defaultValue={maxDiscountAmount}
                            // maxLength={2}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-1">
                          <Form.Label md="6" htmlFor="validationDefault01">
                            <p className="ms-3 mb-0">Minimum Order Amount</p>
                          </Form.Label>

                          <Form.Control
                            style={{ border: "1px solid #000", marginLeft: 15 }}
                            type="text"
                            name="minOrderAmount"
                            id="minOrderAmount"
                            onChange={updateHandleChanges}
                            defaultValue={minOrderAmount}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </>
              ) : couponType === 1 ? (
                <>
                  <Form>
                    <Form.Group>
                      <Form.Check className="d-block ms-3">
                        <Form.Check.Input
                          className="me-2"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={true}
                        />
                        <Form.Check.Label htmlFor="flexRadioDefault1 text-dark">
                          <p className="ms-5 mb-0" style={{ color: "black" }}>
                            Money Off
                          </p>
                        </Form.Check.Label>
                      </Form.Check>
                      <Row>
                        <Col md="6" className="mb-1">
                          <Form.Label md="6" htmlFor="validationDefault01">
                            <p className="ms-3 mb-0">Discount</p>
                          </Form.Label>

                          <Form.Control
                            style={{
                              border: "1px solid #000",
                              marginLeft: 15,
                            }}
                            type="text"
                            name="offerAmount"
                            id="offerAmount"
                            value={offerAmount}
                            onChange={updateHandleChanges}
                            // maxLength={2}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-1">
                          <Form.Label md="6" htmlFor="validationDefault01">
                            <p className="ms-3 mb-0">Minimum Order Amount</p>
                          </Form.Label>

                          <Form.Control
                            style={{ border: "1px solid #000", marginLeft: 15 }}
                            type="text"
                            name="minOrderAmount"
                            id="minOrderAmount"
                            onChange={updateHandleChanges}
                            value={minOrderAmount}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className="content-margin">
          <h3 className="ms-5">Coupon Details</h3>
          <div style={{ border: "1px solid #D1D6E3", borderRadius: "5px" }}>
            <div className="p-3 ps-5">
              <Form>
                <Row>
                  <Col md="6" className="mb-4">
                    <Form.Label md="6" htmlFor="validationDefault01">
                      <p className=" mb-0">Coupon Title</p>
                    </Form.Label>

                    <Form.Control
                      style={{ border: "1px solid #000" }}
                      type="text"
                      id="title"
                      name="title"
                      onChange={updateHandleChanges}
                      value={title}
                    />
                  </Col>
                  <p
                    style={{ color: "black", fontSize: "11px" }}
                    className=" mb-0"
                  >
                    {/* Maximum 100 characters */}
                  </p>

                  <Col md="6" className="mb-4">
                    <Form.Label md="6" htmlFor="validationDefault01">
                      Coupon Code
                    </Form.Label>

                    <Form.Control
                      style={{ border: "1px solid #000" }}
                      type="text"
                      id="couponCode"
                      name="couponCode"
                      onChange={updateHandleChanges}
                      value={couponCode}
                    />
                  </Col>
                  <p
                    style={{ color: "black", fontSize: "11px" }}
                    className=" mb-0"
                  >
                    {/* Maximum 100 characters */}
                  </p>

                  <Col md="6" className="mb-4">
                    <Form.Label md="6" htmlFor="validationDefault01">
                      Description
                    </Form.Label>

                    <Form.Control
                      style={{ border: "1px solid #000" }}
                      type="text"
                      id="couponDescription"
                      name="couponDescription"
                      as="textarea"
                      rows={5}
                      onChange={updateHandleChanges}
                      value={couponDescription}
                    />
                  </Col>
                </Row>
              </Form>
              <div>
                <Button
                  variant="primary"
                  onClick={update}
                  className="margin-left-button "
                >
                  {loading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "update Coupon"
                  )}
                </Button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default EditCoupon;
