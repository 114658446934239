import React, { useEffect, useState } from 'react'
import { ServiceDownloadBillInvoice, ServiceDownloadRevenue, ServiceOrdergetsingle } from '../../../../services/sellerservices/Seller_service_details';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import * as moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { toast } from 'react-toastify';

function Servicesorderview() {
    const { id } = useParams();
    const [datas, setDatas] = useState([]);
    const [invoiceloading, setInvoiceLoading] = useState(false);
    const [invoiceloading1, setInvoiceLoading1] = useState(false);
    const [billdata, setBillData] = useState("");
    const [billdata1, setBillData1] = useState("");

    useEffect(() => {
        getOrderservice();
    }, [])

    const getOrderservice = () => {
        let isCancelled = false;
        if (!isCancelled) {
            ServiceOrdergetsingle(id).then((res) => {
                setDatas(res?.data?.results[0]);
            }).catch((err) => {
                console.log(err);
            })
        }
        return () => {
            isCancelled = false;
        }

    }



    const ViewInvoicedownload = (orderids) => {
        setInvoiceLoading(true);
        ServiceDownloadBillInvoice(orderids).then((res) => {
            setBillData(res?.data?.url);
            if (res?.data?.url) {
                window.open(res?.data?.url);
            }
            else {
                toast.error("Server Error. Try after some time.")

            }
            setTimeout(() => {
                setInvoiceLoading(false);
            }, 600);
        }).catch((err) => {
            console.log(err);
            setInvoiceLoading(false);
        })
    }

    const ViewRevenuedownload = (orderids) => {
        setInvoiceLoading1(true);
        ServiceDownloadRevenue(orderids).then((res) => {
            setBillData1(res?.data?.url);
            if (res?.data?.url) {
                window.open(res?.data?.url);
            }
            else {
                toast.error("Server Error. Try after some time.")

            }
            setTimeout(() => {
                setInvoiceLoading1(false);
            }, 600);
        }).catch((err) => {
            console.log(err);
            setInvoiceLoading1(false);
        })
    }


    return (
        <div>
            <h3 className="mb-4">Service Booking Details </h3>
            <>
                <Row>
                    <Col>
                        <Card className="p-0 ">
                            <Card.Header className="d-flex justify-content-between">
                            </Card.Header>
                            <Card.Body className="m-0">
                                <div className="new-user-info">
                                    <h4>Customer Details</h4>
                                    <div className='mt-4 mb-3'>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6>Name:</h6>
                                                <p>{datas.customerDetails?.name}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <h6>Contact Number:</h6>
                                                <p>+61 {datas.customerDetails?.contactNumber}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6>Email:</h6>
                                                <p>{datas.customerDetails?.email}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <h6>Gender:</h6>
                                                <p>{datas.customerDetails?.gender}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="new-user-info">
                                    <h4>Service Details</h4>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h6>ServiceName:</h6>
                                            <p>{datas?.serviceName}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>Title:</h6>
                                            <p>{datas?.variationName}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h6>Duration:</h6>
                                            <p>{datas?.variationDetails?.durationDetails} hours</p>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>Varation Name:</h6>
                                            <p>{datas?.variationName}</p>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <h6>Start Date:</h6>

                                            <p>{moment(datas?.variationDetails?.startDate).format("LLLL")}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>End Date:</h6>

                                            <p>{moment(datas?.variationDetails?.endDate).format("LLLL")}</p>


                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <h6>Frequency:</h6>
                                            <p>{datas?.variationDetails?.frequency}</p>


                                        </div>
                                        <div className="col-md-6">
                                            <h6>FrequencyType:</h6>

                                            <p>{datas?.variationDetails?.frequencyType}</p>



                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-md-6">
                                            <h6>Is Cancellation Available
                                                :</h6>
                                            <p>{datas?.variationDetails?.isCancellationAvailable ? "Yes" : "No"}</p>


                                        </div>
                                        <div className="col-md-6">
                                            <h6>Link Description:</h6>

                                            <p>{datas?.variationDetails?.linkDescription}</p>



                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <h6>Number Of People Allowd:</h6>
                                            <p>{datas?.variationDetails?.numberOfPeopleAllowed}</p>


                                        </div>
                                        <div className="col-md-6">
                                            <h6>Payment Status</h6>
                                            <p>{datas?.paymentStatus}</p>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-6">
                                            <h6>Payment Method</h6>
                                            <p>{datas.paymentMethod}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>Item Price</h6>
                                            <p>A$ {datas.price}</p>
                                        </div>

                                    </div>
                                    <div className="row">


                                        <div className="col-md-6">
                                            <h6>Service Order GST</h6>
                                            <p>A$ {datas.gstAmount}</p>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-6">
                                            <h6>Service Order Status</h6>
                                            {datas.stateId === 1 && <p>New Order</p>}
                                            {datas.stateId === 2 && <p>Confirmed</p>}
                                            {datas.stateId === 3 && <p>In Progress</p>}
                                            {datas.stateId === 4 && <p>Cancelled by Buyer</p>}
                                            {datas.stateId === 5 && <p>Cancelled by Womenpreneurs</p>}
                                            {datas.stateId === 6 && <p>Delivered</p>}


                                        </div>

                                    </div>

                                    <div>
                                        <h5>Slot Booking Details</h5>
                                        <div className='row gap-1'>
                                            {/* {datas?.slotDetails?.map((item, index) => {
                                                return ( */}
                                            <div className='card col-lg-3'>
                                                <div>
                                                    <div>
                                                        {datas?.slotDetails?.slotTiming}
                                                    </div>
                                                    {/* <div className='mt-2'>

                                                                {item?.workingHours}
                                                            </div> */}
                                                </div>


                                            </div>
                                            {/* )/ */}
                                            {/* })} */}
                                        </div>
                                    </div>


                                    <div className="row">

                                        <div className="col-md-6">
                                            <h6>Total Amount</h6>
                                            <p>A$ {datas.totalOrderAmount}</p>
                                        </div>
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <div className="">

                    <div className="header-container">
                        <p className="header-btn">{datas?.orderId}</p>
                        <div className="d-flex gap-2">
                            <div className="rightside-btn">

                                <Button
                                    className="invoiceprint mx-5"
                                    onClick={() => ViewRevenuedownload(datas?.orderId)}
                                >
                                    {invoiceloading1 ? <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="ms-2">Loading...</span>
                                    </> : <> View Revenue</>}

                                </Button>
                            </div>

                            <div className="rightside-btn">
                                <Button
                                    className="invoiceprint mx-5"
                                    onClick={() => ViewInvoicedownload(datas?.orderId)}
                                >

                                    <div className="d-flex gap-3">
                                        <div>
                                            {invoiceloading ? <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className="ms-2">Loading...</span>
                                            </> : <> View Invoice</>}
                                        </div>
                                    </div>
                                </Button>
                            </div>

                            {datas.shippingDetails ? (
                                <div className="rightside-btn">
                                    {datas.shippingDetails.status === "completed" ? (
                                        <Button
                                            className="invoiceprint mx-5"
                                            onClick={() =>
                                                window.open(
                                                    datas.shippingDetails.label_download.png,
                                                    "_blank"
                                                )
                                            }
                                        >
                                            <div className="d-flex gap-2">
                                                <div>View Label</div>
                                            </div>
                                        </Button>
                                    ) : datas.shippingDetails.status !== "cancelled" ||
                                        datas.shippingDetails.status !== "completed" ? (
                                        <Button className="invoiceprint mx-5">
                                            <div className="d-flex gap-3">
                                                <div>Track Order</div>
                                            </div>
                                        </Button>
                                    ) : null}

                                </div>
                            ) : null}
                        </div>
                    </div>

                </div>
                {/* {datas.itemsOrdered.map((e) => {
                    return (
                        <>
                            <div className="mt-4">
                                <div className="content-container">
                                    <div>
                                        {e?.productThumbImage ? (
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_URL}/${e.productThumbImage}`}
                                                className="set-img"
                                            />
                                        ) : (
                                            <img
                                                src={
                                                    "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fae01.alicdn.com%2Fkf%2FHTB1mnnhaYYI8KJjy0Faq6zAiVXa4%2FKids-Party-Wear-Frocks-Kids-Party-Dress-for-Winter-Girls-Puffy-Dresses-Children-Vintage-Dresses-Big.jpg&f=1&nofb=1&ipt=8ebd3f3ecdf1134c57d9f35534468b857f033a925d6c6bef13ba529599b4ddfd&ipo=images"
                                                }
                                                className="set-img"
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <p className="header-text">Name</p>
                                        <p>{e.productName}</p>
                                        <p className="header-text">Seller</p>
                                        <span>{datas.sellerBusinessName}</span>

                                    </div>
                                    <div>
                                        <p className="header-text">Price</p>
                                        <p>A$ {e.price}</p>
                                        <p className="header-text">GST</p>
                                        <p>A$ {datas.itemsOrdered[0].salePriceGST}</p>
                                    </div>
                                    <div className="">
                                        <h6>Qunatity Ordered</h6>
                                        {datas.itemsOrdered?.map((items, index) => {
                                            return (
                                                <div>
                                                    {items?.quantity}
                                                </div>
                                            )
                                        })}

                                        <div className="mt-3">


                                            {e.variations ? (
                                                <>
                                                    {e.variations.map((datas) => {
                                                        return (
                                                            <div className="">
                                                                <p className="header-text">{datas?.name}:</p>
                                                                <p className="ms-1"> {datas?.value}</p>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            ) : null}
                                        </div>
                                    </div>


                                    <div>
                                        <p className="header-text">Expected Delivery</p>
                                        <p>
                                            {moment(
                                                datas.itemsOrdered[0].expectedDeliveryDate
                                            ).format("LLLL")}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </>
                    );
                })} */}

                <div className="footer-container mt-1 mb-5">
                    <div></div>
                    <p className="mb-0 fs-4">
                        Service Order Total :
                        <span className="text-black "> A$ {datas.totalOrderAmount}</span>
                    </p>
                </div>
            </>

        </div>
    )
}

export default Servicesorderview