import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import b1 from '../../../../../assets/blogs/b1.png';
import b2 from '../../../../../assets/blogs/b2.png';
import b3 from '../../../../../assets/blogs/b3.png';
import './styles/Blogcategory.scss';
import Form from 'react-bootstrap/Form';

function Blogcategory() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const datas = [1, 2, 3, 4, 5, 6, 7];
    return (
        <>
            <Card>
                <div>
                    <div className='d-flex justify-content-between p-2 blogbox'>
                        <div>
                            <h4> Blog Category</h4>
                        </div>
                        <div>
                            <button className='AddBlog' onClick={handleShow}>
                                + Add Blog Category
                            </button>
                        </div>
                    </div>
                </div>
                <div className='cards row mt-5'>
                    {datas?.map((item, index) => {
                        return (
                            <div className='card col-lg-3'>
                                <div className='inside-cardss'>
                                    <div className='leftblog'>
                                        blogs
                                    </div>
                                    <div className='blogiconsright'>

                                        <div>
                                            <img src={b1} alt="no image" className='blogsicons' />
                                        </div> <div>
                                            <img src={b2} alt="no image" className='blogsicons' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                </div>
            </Card>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Blog Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-lg-12 mt-2 mb-5'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Category name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Category name" />
                            <Form.Text className="text-muted">

                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div>
                        <div className="buttonsections">
                            <div >
                                <button className='savebutton'>Add</button>
                            </div>
                            <div>
                                <button className='cancelbutton' onClick={handleClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Blogcategory