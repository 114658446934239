import { Fragment, memo, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { FreeMode, Mousewheel, Navigation, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../../../components/Loader";
import Card from "../../../components/bootstrap/card";
import { ProductDetailsServices } from "../../../services/list/listServices";

const ProductDetails = memo(() => {
  const { id } = useParams();
  const [single, setSingle] = useState();
  const history = useNavigate();

  useEffect(() => {
    ProductDetailsServices(id)
      .then((res) => {
        setSingle(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  function Sweetalert() {
    Swal.fire({
      icon: "success",
      title: "Added!",
      text: "Your item has been Added to the cart.",
    });
  }

  if (!single) {
    return <Loader />;
  }



  return (
    <Fragment>
      <Row>
        <Card>
          <Card.Body>
            <Row>
              <Col lg="7">
                <div className="product-vertical-slider">
                  <div className="slider__flex">
                    <div className="slider__col">
                      <div className="slider__thumbs">
                        <Swiper
                          className="iq-swiper swiper-container gallery-top text-end"
                          direction={"vertical"}
                          onSwiper={setThumbsSwiper}
                          slidesPerView={3}
                          spaceBetween={20}
                          freeMode={true}
                          watchSlidesProgress={true}
                          modules={[FreeMode, Thumbs]}
                          loop={true}
                        >
                          {single?.productImages.map((e) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <div className="slider__image">
                                    <Image
                                      alt="product-15"
                                      src={`${process.env.REACT_APP_IMAGE_URL}/${e?.name}`}
                                      style={{ objectFit: "cover" }}
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                    <div className="slider__images">
                      <Swiper
                        className="swiper-container gallery-thumbs"
                        direction={"vertical"}
                        spaceBetween={10}
                        thumbs={{
                          swiper:
                            thumbsSwiper && !thumbsSwiper.destroyed
                              ? thumbsSwiper
                              : null,
                        }}
                        mousewheel={true}
                        modules={[FreeMode, Thumbs, Mousewheel]}
                        loop={true}
                      >
                        {single?.productImages.map((e) => {
                          return (
                            <>
                              <SwiperSlide>
                                <div className="slider__image">
                                  <Image
                                    alt="product-15"
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${e?.name}`}
                                    style={{ objectFit: "cover" }}
                                  />
                                </div>
                              </SwiperSlide>
                            </>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="5">
                <div className="border-bottoms">
                  <div className="d-flex flex-column justify-items-start mb-3">
                    <div className="d-flex justify-content-between mb-2">
                      <h1 className="mb-0 fs-1">{single?.productName}</h1>
                      <div className="d-flex justify-content-end "></div>
                    </div>

                  </div>
                  <div className="d-flex my-4">
                    <h4 className="mb-0"> Price:</h4>
                    <h4 className="text-primary mb-0 ms-2">
                      ${single?.salePrice}
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Tab.Container defaultActiveKey="first">
              <Nav className="tab-bottom-bordered">
                <div className="mb-0 nav nav-tabs" id="nav-tab1" role="tablist">
                  <Nav.Link
                    eventKey="first"
                    id="nav-description-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-description"
                    type="button"
                    role="tab"
                    aria-controls="nav-description"
                    aria-selected="true"
                  >
                    Description
                  </Nav.Link>
                  <Nav.Link
                    id="nav-info-tab"
                    eventKey="second"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-info"
                    type="button"
                    role="tab"
                    aria-controls="nav-info"
                    aria-selected="false"
                  >
                    Info
                  </Nav.Link>
                  <Nav.Link
                    id="nav-review-tab"
                    eventKey="thired"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-review"
                    type="button"
                    role="tab"
                    aria-controls="nav-review"
                    aria-selected="false"
                  >
                    Reviews(0)
                  </Nav.Link>
                </div>
              </Nav>
              <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                  eventKey="first"
                  id="nav-description"
                  role="tabpanel"
                  aria-labelledby="nav-description-tab"
                >
                  <div className="d-flex flex-column">
                    <p style={{ color: "black" }}>
                      {single?.productDescription}
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  id="nav-info"
                  role="tabpanel"
                  aria-labelledby="nav-info-tab"
                >
                  <Table bordered className="mb-0">
                    <tbody>
                      <tr>
                        <th>Brand Name</th>
                        <td>{single?.brandName}</td>
                      </tr>
                      <tr>
                        <th>Model Number</th>
                        <td>{single?.modelName}</td>
                      </tr>
                      <tr>
                        <th>Style Name</th>
                        <td>{single?.styleName}</td>
                      </tr>
                      <tr>
                        <th>Manufacture's Name</th>
                        <td>{single?.manufacturerName}</td>
                      </tr>
                      <tr>
                        <th>Quantity</th>
                        <td>{single?.quantityLeft}</td>
                      </tr>
                      <tr>
                        <th>Actual Price</th>
                        <td>{single?.actualPrice}</td>
                      </tr>
                      <tr>
                        <th>Offer Percentage</th>
                        <td>{single?.offerPercentag} %</td>
                      </tr>
                      <tr>
                        <th>Selling Price</th>
                        <td>{single?.salePrice}</td>
                      </tr>
                      <tr>
                        <th>Target Gender</th>
                        <td>{single?.targetGender}</td>
                      </tr>
                      <tr>
                        <th>Is Shipping Required</th>
                        <td>
                          {single?.isShippingRequired === 1
                            ? "Myself"
                            : single?.isShippingRequired === 2
                              ? "Womeyn"
                              : null}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="thired"
                  id="nav-review"
                  role="tabpanel"
                  aria-labelledby="nav-review-tab"
                >
                  <h2 className="mb-0 border-bottom pb-3">Review</h2>
                  <div>
                    <Card className="shadow-none bg-transparent border">
                      <Card.Body>
                        No Review!!!
                      </Card.Body>
                    </Card>

                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
        <Col lg="12" md="12">
          <Row className="row-cols-1">
            <div
              className="overflow-hidden slider-circle-btn"
              id="ecommerce-slider"
            >
              <Swiper
                className="p-0 m-0 mb-4 swiper-wrapper list-inline"
                slidesPerView={3}
                spaceBetween={32}
                modules={[Navigation]}
                navigation={{
                  nextEl: "#swiper-button-next1",
                  prevEl: "#swiper-button-prev1",
                }}
                breakpoints={{
                  320: { slidesPerView: 1 },
                  550: { slidesPerView: 2 },
                  991: { slidesPerView: 3 },
                  1400: { slidesPerView: 3 },
                  1500: { slidesPerView: 3 },
                  1920: { slidesPerView: 4 },
                  2040: { slidesPerView: 4 },
                  2440: { slidesPerView: 4 },
                }}
              ></Swiper>
              <div
                id="swiper-button-next1"
                className="swiper-button swiper-button-next"
              ></div>
              <div
                id="swiper-button-prev1"
                className="swiper-button swiper-button-prev"
              ></div>
            </div>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
});
ProductDetails.displayName = "ProductDetails";
export default ProductDetails;
