import React from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import ProductBanners from "./productbanners/ProductBanners";
import Servicebanners from "./servicebanners/Servicebanners";
import Womeynsellerbanners from "./womeynsellersbanners/Womeynsellerbanners";
function Banner() {
  return (
    <div>
      <Card>
        <div>
          <Tab.Container defaultActiveKey="1">
            <Nav className="tab-bottom-bordered">
              <div className="mb-0 nav nav-tabs" id="nav-tab1" role="tablist">
                <Nav.Link
                  eventKey="1"
                  id="nav-admin"
                  data-bs-target="#tab-admin"
                  aria-controls="pills-admin"
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  <span className="iq-mail-section">Products</span>
                </Nav.Link>
                <Nav.Link
                  eventKey="2"
                  id="nav-sellers"
                  data-bs-target="#tab-sellers"
                  aria-controls="pills-sellers"
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  aria-selected="false"
                >
                  <span className="iq-mail-section">Service</span>
                </Nav.Link>
                <Nav.Link
                  eventKey="3"
                  id="nav-endc"
                  data-bs-target="#tab-endc"
                  aria-controls="pills-endc"
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  aria-selected="false"
                >
                  <span className="iq-mail-section">WomeynPreneur</span>
                </Nav.Link>
              </div>
            </Nav>
            <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
              <Tab.Pane
                eventKey="1"
                id="nav-admin"
                role="tab"
                aria-labelledby="nav-admin-tab"
              >
                <ProductBanners />
              </Tab.Pane>
              <Tab.Pane
                eventKey="2"
                id="nav-sellers"
                role="tab"
                aria-labelledby="nav-sellers-tab"
              >
                <Servicebanners />
              </Tab.Pane>
              <Tab.Pane
                eventKey="3"
                id="nav-endc"
                role="tab"
                aria-labelledby="nav-endc-tab"
              >
                <Womeynsellerbanners />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Card>
    </div>
  );
}

export default Banner;
