import { memo } from "react";

//router
import { Route, Routes } from "react-router-dom";
import Default from "../layout/default";

//Pages
import SignIn from "../pages/auth/sign-in";
import Index from "../pages/views/dashboard";
import EditUser from "../pages/views/editUser/editUser";
import ForgetPassword from "../pages/views/forgotPassword/forgotPassword";
import UpdateForgotPassword from "../pages/views/passwordChange/updateForgotPassword";
import ProductList from "../pages/views/productList/productList";
import ProductPage from "../pages/views/productsPage/productsPage";
import SellerApproval from "../pages/views/sellerApproval/sellerApproval";
import SellerProfile from "../pages/views/sellerProfile/sellerProfile";
import ServiceList from "../pages/views/serviceList/serviceList";
import UserManagement from "../pages/views/userManagement/userManagement";
import ProtectedRoutes from "./protected-routes";
// import  from "../pages/views/productsPage/subCategoryList";
import Banner from "../pages/views/Banner/banner";
import CouponList from "../pages/views/couponCreation/couponList";
import AddPlacement from "../pages/views/placement/addPlacement";
import PlacementList from "../pages/views/placement/placementList";
import ProductDetails from "../pages/views/productList/productDetails";
import SubCategory from "../pages/views/productsPage/subCategoryList";
import SubCategoryPage from "../pages/views/productsPage/subCategoryPage";

//Users
import AddProduct from "../pages/views/addProduct/addProduct";
import AddService from "../pages/views/addService/addService";

import CouponCreation from "../pages/views/couponCreation/couponCreation";
import EditCoupon from "../pages/views/couponCreation/editCoupon";
// import OrderInvoice from "../pages/views/orders-list/allorderdetails/orderInvoice";
import Subscribers from "../pages/views/Subscribers/Subscribers";
import Transactions from "../pages/views/Transaction/Transactions";
import ContactUSList from "../pages/views/contactUs/contactUsList";
import Order from "../pages/views/orders-list/OrderDetails";
import ViewOrderDetails from "../pages/views/orders-list/allorderdetails/ViewOrderDetails";
import OrderInvoice from "../pages/views/orders-list/allorderdetails/orderInvoice";
import Payment from "../pages/views/payment/payment";
import PaymentDetails from "../pages/views/payment/paymentList/paymentDetails";
import PlacementProperties from "../pages/views/placement/placementProperties";
import ScriptProperties from "../pages/views/placement/scriptProperties";
import ServicePage from "../pages/views/servicePage/servicePage";
import ChatSystem from "../pages/views/ticketQuery";
import Tickets from "../pages/views/tickets/Tickets";
import Ticketsview from "../pages/views/tickets/ticketsview/Ticketsview";
import UserProfile from "../pages/views/userProfile/userProfile";

import Blogs from "../pages/views/cmsPages/Blogsandevnets/Blogs";
import Blogcategory from "../pages/views/cmsPages/Blogsandevnets/blogcategory/Blogcategory";
import CreateBlog from "../pages/views/cmsPages/Blogsandevnets/createblog/CreateBlog";
import Contacts from "../pages/views/cmsPages/contact/Contact";
import Subscribeplans from "../pages/views/cmsPages/subscribeplans/Subscribeplans";
import Productserviceform from "../pages/views/cmsPages/subscribeplans/productandserviceplans/Productserviceform";
import Productforms from "../pages/views/cmsPages/subscribeplans/productplans/Productforms";
import Serviceforms from "../pages/views/cmsPages/subscribeplans/serviceplans/Serviceforms";
import Banners from "./../pages/views/cmsPages/Banners/Banner";

import Viewblog from "../pages/views/cmsPages/Blogsandevnets/viewblog/Viewblog";
import Events from "../pages/views/cmsPages/Events/Events";
import Createevents from "../pages/views/cmsPages/Events/createevents/Createevents";
import Viewevents from "../pages/views/cmsPages/Events/viewevents/Viewevents";
import ProductServiceEditForm from "../pages/views/cmsPages/subscribeplans/productandserviceplans/ProductServiceEditForm";
import ProductEditForm from "../pages/views/cmsPages/subscribeplans/productplans/productEditForm";
import ServiceEditForm from "../pages/views/cmsPages/subscribeplans/serviceplans/serviceEditForm";
import Termsandconditions from "../pages/views/cmsPages/termsandconditions/Termsandconditions";
import CreateenduserEventsandupdate from "../pages/views/cmsPages/termsandconditions/components/endusereventsandupdates/createsellerterms/CreateenduserEventsandupdate";
import CreateenduserFaq from "../pages/views/cmsPages/termsandconditions/components/enduserfaq/createsellerterms/CreateenduserFaq";
import CreatePrivacyEnduser from "../pages/views/cmsPages/termsandconditions/components/endusertermsandconditions/enduserprivacy/createsellerterms/CreatePrivacyEnduser";
import CreateTermsEnduser from "../pages/views/cmsPages/termsandconditions/components/endusertermsandconditions/enduserterms/createsellerterms/CreateTermsEnduser";
import CreateTerms from "../pages/views/cmsPages/termsandconditions/components/sellertermsandconsitions/createsellerterms/CreateTerms";
import ServiceBookingList from "../pages/views/serviceBookings/serviceBookingList";
import AddUser from "../pages/views/adduser/AddUser";
import ViewServicedScreen from "../pages/views/serviceList/ViewServicedScreen";
import Servicesorderview from './../pages/views/serviceList/serviceorderview/Servicesorderview';
import AllSubscribers from "../pages/views/allsubscribers/AllSubscribers";
import SellerPrivacypolicy from "../pages/views/cmsPages/termsandconditions/components/sellertermsandconsitions/createsellerprivacypolicy/SellerPrivacypolicy";
import CreatePrivacypolicy from "../pages/views/cmsPages/termsandconditions/components/sellertermsandconsitions/createsellerprivacypolicy/CreatePrivacypolicy";
import TicketsCustomer from "../pages/views/customer-tickets/TicketsCustomer";
import Ticketsviews from './../pages/views/customer-tickets/ticketsview/Ticketsview';
const IndexRouters = memo(() => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />}></Route>
      <Route path="/forgot-password" element={<ForgetPassword />}></Route>
      <Route path="/reset-password" element={<UpdateForgotPassword />}></Route>
      <Route element={<ProtectedRoutes></ProtectedRoutes>}>
        <Route path="womeyn" element={<Default />}>
          <Route
            path="user-profile"
            element={<UserProfile></UserProfile>}
          ></Route>
          <Route path="dashboard" element={<Index></Index>}></Route>
          <Route
            path="seller-approval"
            element={<SellerApproval></SellerApproval>}
          ></Route>
          <Route
            path="seller-profile/:id"
            element={<SellerProfile></SellerProfile>}
          ></Route>
          <Route
            path="seller-approval/edit/:id"
            element={<EditUser></EditUser>}
          ></Route>
          <Route
            path="user-management"
            element={<UserManagement></UserManagement>}
          ></Route>
          <Route path="user-add" element={<AddUser />}></Route>
          <Route
            path="product-category"
            element={<ProductPage></ProductPage>}
          ></Route>
          <Route path="product-add" element={<AddProduct></AddProduct>}></Route>
          <Route
            path="category/:categoryId/:name"
            element={<SubCategory></SubCategory>}
          ></Route>
          <Route
            path="sub-category/:categoryId/:name"
            element={<SubCategoryPage></SubCategoryPage>}
          ></Route>
          <Route
            path="service-category"
            element={<ServicePage></ServicePage>}
          ></Route>
          <Route path="service-add" element={<AddService></AddService>}></Route>
          <Route
            path="product-list"
            element={<ProductList></ProductList>}
          ></Route>
          <Route
            path="product-details/:id"
            element={<ProductDetails></ProductDetails>}
          ></Route>
          <Route
            path="service-list"
            element={<ServiceList></ServiceList>}
          ></Route>
          <Route
            path="placement-list"
            element={<PlacementList></PlacementList>}
          ></Route>
          <Route
            path="add-placement"
            element={<AddPlacement></AddPlacement>}
          ></Route>
          <Route path="banner" element={<Banner></Banner>}></Route>
          <Route path="orders" element={<Order></Order>}></Route>
          <Route
            path="view-order/:id"
            element={<ViewOrderDetails></ViewOrderDetails>}
          ></Route>
          <Route
            path="order-invoice"
            element={<OrderInvoice></OrderInvoice>}
          ></Route>
          <Route
            path="transactions"
            element={<Transactions></Transactions>}
          ></Route>
          <Route
            path="emailsubscribers"
            element={<AllSubscribers></AllSubscribers>}
          ></Route>
          <Route
            path="subscribers"
            element={<Subscribers></Subscribers>}
          ></Route>
          <Route path="services" element={<ServiceList></ServiceList>}></Route>
          <Route path="services/:id" element={<ViewServicedScreen></ViewServicedScreen>}></Route>

          <Route
            path="placement-properties/:id"
            element={<PlacementProperties></PlacementProperties>}
          ></Route>
          <Route
            path="placement-script-properties/:id"
            element={<ScriptProperties></ScriptProperties>}
          ></Route>
          <Route path="coupons" element={<CouponList></CouponList>}></Route>
          <Route
            path="coupon-creation"
            element={<CouponCreation></CouponCreation>}
          ></Route>
          <Route
            // path="coupon/edit-coupon/:id"
            path="coupon/edit-coupon/:id"
            element={<EditCoupon></EditCoupon>}
          ></Route>
          {/* <Route
            // path="coupon/edit-coupon/:id"
            path="chat"
            element={<ChatSystem></ChatSystem>}
          ></Route> */}
          <Route path="tickets" element={<Tickets></Tickets>}></Route>
          <Route
            path="ticket-view/:id"
            element={<Ticketsview></Ticketsview>}
          ></Route>
            <Route path="tickets/customer" element={<TicketsCustomer></TicketsCustomer>}></Route>
          <Route
            path="ticket-view-customer/:id"
            element={<Ticketsviews></Ticketsviews>}
          ></Route>
          <Route path="payment" element={<Payment></Payment>}></Route>
          <Route
            path="payment-view/:id"
            element={<PaymentDetails></PaymentDetails>}
          ></Route>
          <Route
            path="contactUs"
            element={<ContactUSList></ContactUSList>}
          ></Route>

          <Route path="blog" element={<Blogs></Blogs>}></Route>

          <Route path="blog/create" element={<CreateBlog></CreateBlog>}></Route>
          <Route
            path="blog/create/:id"
            element={<CreateBlog></CreateBlog>}
          ></Route>
          <Route path="blog/:id" element={<Viewblog></Viewblog>}></Route>
          {/* <Route
            path="blogcategory"
            element={<Blogcategory></Blogcategory>}
          ></Route> */}
          <Route path="contact" element={<Contacts />}></Route>
          <Route path="subscribeplans" element={<Subscribeplans />}></Route>
          <Route path="productplan" element={<Productforms />}></Route>
          <Route path="productplan" element={<Productforms />}></Route>
          <Route path="serviceplan" element={<Serviceforms />}></Route>
          <Route
            path="productandserviceplan"
            element={<Productserviceform />}
          ></Route>
          <Route path="productplan/:id" element={<ProductEditForm />}></Route>
          <Route path="serviceplan/:id" element={<ServiceEditForm />}></Route>
          <Route path="serviceorder-view/:id" element={<Servicesorderview />}></Route>

          <Route
            path="productandserviceplan/:id"
            element={<ProductServiceEditForm />}
          ></Route>
          <Route path="banners" element={<Banners />}></Route>
          <Route path="events" element={<Events />}></Route>
          <Route path="events/create" element={<Createevents />}></Route>
          <Route path="events/create/:id" element={<Createevents />}></Route>
          <Route path="events/:id" element={<Viewevents />}></Route>
          <Route
            path="service-booking-list"
            element={<ServiceBookingList />}
          ></Route>
          <Route
            path="termsandconditions"
            element={<Termsandconditions />}
          ></Route>
          <Route path="terms/create" element={<CreateTerms />}></Route>
          <Route path="terms/create/:id" element={<CreateTerms />}></Route>
          <Route path="privacy/create" element={<CreatePrivacypolicy />}></Route>
          <Route path="privacy/create/:id" element={<CreatePrivacypolicy />}></Route>
          <Route
            path="customerterms/create"
            element={<CreateTermsEnduser />}
          ></Route>
          <Route
            path="customerterms/create/:id"
            element={<CreateTermsEnduser />}
          ></Route>

          <Route
            path="customerprivacy/create"
            element={<CreatePrivacyEnduser />}
          ></Route>
          <Route
            path="customerprivacy/create/:id"
            element={<CreatePrivacyEnduser />}
          ></Route>

          <Route
            path="customerfaq/create"
            element={<CreateenduserFaq />}
          ></Route>
          <Route
            path="customerfaq/create/:id"
            element={<CreateenduserFaq />}
          ></Route>

          <Route
            path="events&update/create"
            element={<CreateenduserEventsandupdate />}
          ></Route>
          <Route
            path="events&update/create/:id"
            element={<CreateenduserEventsandupdate />}
          ></Route>
        </Route>
      </Route>
    </Routes>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
