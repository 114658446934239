import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card } from 'react-bootstrap';
import ReactHtmlParser from "react-html-parser";

import './styles/Viewblog.scss';
import { singleEvent } from '../../../../../services/eventsservices/events_services';
function Viewevents() {
  const { id } = useParams();
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    singleEvent(id).then((res) => {
      setBlogs(res?.data);

    }).catch((err) => {
      console.log(err);
    })
  }, [])
  return (
    <div>
      <Card>
        <div className='d-flex align-items-center justify-content-center'>
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/${blogs?.eventImageName}`}
            alt="no image"
            className="imageblogss"
          />
          <div>
          </div>
        </div>


        <div className='mt-4 ms-1'>
          <div className='d-flex gap-5'>
          <span>StartDate : {blogs?.startDate}</span>
          <span>EndDate : {blogs?.endDate}</span> 
          </div>
          <div className='ms-3 mt-3 mb-1'>
            {blogs?.title}
          </div>
        </div>

        <div className='mt-5 mb-5'>
          <div className='mb-4'>
            {/* <h4>Content :</h4> */}
          </div>
          <div className='ms-3'>{ReactHtmlParser(blogs?.eventContent)?.slice(0, 30)}</div>
        </div>

        <div>
          <div className='ms-1'>
            {/* <h4> Description :</h4> */}
          </div>
          <div className='ms-3 mt-3 mb-4'>
            {blogs?.description}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Viewevents