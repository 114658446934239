import Pagination from "rc-pagination";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import arrowleft from "../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../assets/loginLogos/rightarrow.png";
import Loader from "../../../components/Loader";
import {
  GetContactUsDetails,
  GetContactUsList,
} from "../../../services/list/listServices";
import {
  getTicketList,
  getTicketListByStateId,
  getTicketListContact,
} from "../../../services/tickets/ticketsServices";
import "../tickets/styles/Tickets.scss";
function ContactUSList() {
  const history = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [tables, setTables] = useState([]);
  const [messageDetails, setMessageDetails] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [newData, setNewData] = useState([]);
  const [open, setOpen] = useState([]);
  const [closed, setClosed] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState();
  const [current, setCurrent] = useState(1);

  const headertexts = [
    {
      headers: "WomeynPatron",
    },
    {
      headers: "Reason",
    },
    {
      headers: "Email",
    },
    {
      headers: "Contact Number",
    },
  ];

  useEffect(() => {
    contactUs();
  }, [current]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const search = () => {
    setPage(0);
    // getTicketList(page, searchText)
    //   .then((res) => {
    //     const result = res && res?.data?.map((e) => e.results);
    //     const table = result.map((t) => t);
    //     const resData = table[0];
    //     const nData = resData.filter((data) => data.stateId === 1);
    //     setNewData(nData);
    //     const openDAta = resData.filter((data) => data.stateId === 2);
    //     setOpen(openDAta);
    //     const closedData = resData.filter((data) => data.stateId === 3);
    //     setClosed(closedData);
    //     setTables(table[0]);
    //   })
    //   .catch((err) => console.log(err));

    getTicketListContact(searchText).then((res)=>{
      setTables(res?.data?.results);

    }).catch((err)=>{
      console.log(err)
    })
  };

  const handleShow = (id) => {
    GetContactUsDetails(id)
      .then((res) => {
        setShow(true);
        setMessageDetails(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  const contactUs = () => {
    GetContactUsList(current)
      .then((res) => {
        setTables(res.data.results);
        setLimit(res.data?.totalPages);
      })
      .catch((err) => console.log(err));
  };

  const fetchCurrentData = async (current) => {
    const resdata = await GetContactUsList(current);
    setTables(resdata.data.results);

  };
  const handleAdminChangePagecount = async (e) => {
    setCurrent(e);
    const current = e;
    await fetchCurrentData(current);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };

  if (!tables) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div className="mb-3 mt-3">
        <h3>Contact Us</h3>
      </div>
      <Card className="col-lg-12">
        <div className="d-flex justify-content-end gap-3">
          <Col lg={6}>
            <div className="py-0 col-lg-10">
              <div className="form-group input-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here to search for messages... "
                  onChange={handleSearch}
                />
                <span
                  className="input-group-text"
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    className="icon-20"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={search}
                  >
                    <circle
                      cx="11.7669"
                      cy="11.7666"
                      r="8.98856"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></circle>
                    <path
                      d="M18.0186 18.4851L21.5426 22"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </Col>
        </div>
        <Card.Body className="m-0 p-0">
          <Table hover className="cursor-texts mt-5" striped>
            <thead>
              <tr>
                {headertexts.map((item) => {
                  return (
                    <>
                      <th>{item.headers}</th>
                    </>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tables.map((item, index) => {
                return (
                  <>
                    <tr onClick={() => handleShow(item?.id)}>
                      <td>
                        {item?.firstName} {item?.lastName}
                      </td>
                      <td>
                        <span className="mb-0 col">
                          {item?.contactReasons.length > 20
                            ? `${item?.contactReasons.slice(0, 20)}...`
                            : `${item?.contactReasons}`}
                        </span>
                      </td>
                      <td>{item?.email}</td>

                      <td>{item?.contactNo}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            {messageDetails?.map((e) => {
              return (
                <>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {e?.firstName} {e?.lastName}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <h6>Reason:</h6>

                      <span>{e?.contactReasons}</span>
                    </div>
                    <div className="mt-4">
                      <h6>Message:</h6>
                      <span>{e?.message} </span>
                    </div>
                    <div className="mt-4">
                      <h6>Email:</h6>
                      <span>{e?.email} </span>
                    </div>
                    <div className="mt-4">
                      <h6>Contact Number:</h6>
                      <span>{e?.contactNo} </span>
                    </div>
                  </Modal.Body>
                </>
              );
            })}
          </Modal>

          <Pagination
            className="pagination-data mt-4"
            total={limit * 10}
            onChange={handleAdminChangePagecount}
            current={current}
            itemRender={PrevNextArrow}
            breakLabel="..."
          />
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default ContactUSList;
