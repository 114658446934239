import React, { useEffect, useState } from 'react'
import './styles/Contact.scss';
import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { createContact, getSingleContact } from '../../../../services/contactservices/contact-services';
import { toast } from 'react-toastify';
function Contact() {
    const [contactlist, setContactList] = useState({
        content: "", contactNo: "", email: ""
    })
    const [contacterror, setContactError] = useState(false);
    const { content, email, contactNo } = contactlist;

    const handleChange = (e) => {
        setContactList({ ...contactlist, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (content?.length === 0 || email?.length === 0 || contactNo?.length === 0) {
            setContactError(true);
        }

        getSingleContact().then((res) => {
            if (res) {
                setContactList(res?.data[0]);
            }
            else {
                setContactList({});
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const Submits = () => {
        if (content?.length === 0 || email?.length === 0 || contactNo?.length === 0) {
            setContactError(true);

        }

        if (content && email && contactNo) {
            const formsData = new FormData();
            formsData.append("content", content);
            formsData.append("email", email);
            formsData.append("contactNo", contactNo);
            createContact(formsData).then((res) => {
                toast.success(res?.data?.message)
            }).catch((err) => {
                console.log(err);
            })
        }
    }


    return (
        <div>
            <Card>
                <Card.Body className='m-0 p-0'>
                    <div className='p-2'>
                        <h4>Contact Information</h4>
                    </div>
                    <div className='col-lg-6 mt-2 mb-5'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Content</Form.Label>
                            <div className='col-lg-12'>
                                <Form.Control
                                    as="textarea"
                                    placeholder="content"
                                    name="content"
                                    value={content}
                                    style={{ height: '100px' }}
                                    onChange={handleChange}
                                />
                            </div>
                            <Form.Text className="text-muted">
                                {contacterror && content.length <= 0 ? <span className='text-danger mt-2'>Content is Required!</span> : null}
                            </Form.Text>
                        </Form.Group>
                    </div>


                    <div className="field-container">
                        <Form.Label htmlFor="validationServer01">
                            Contact Details
                        </Form.Label>
                        <div className="d-flex numbercontacts">
                            <div className="numbercontactss">
                                +61
                            </div>
                            <div className="inputnumbercontact">
                                <Form.Control
                                    name="contactNo"
                                    value={contactNo}
                                    type="text"
                                    onChange={handleChange}
                                    required
                                    style={{ color: "black" }}
                                    maxLength={9}
                                />
                            </div>

                        </div>

                        <Form.Text className="text-muted">
                            {contacterror && contactNo.length <= 0 ? <span className='text-danger mt-2'>Contact No is Required!</span> : null}
                        </Form.Text>


                    </div>


                    <div className='col-lg-6 mb-5'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Enter email"
                                name="email"
                                value={email}
                                onChange={handleChange}
                            />

                            <Form.Text className="text-muted">
                                {contacterror && email.length <= 0 ? <span className='text-danger mt-2'>Email is Required!</span> : null}
                            </Form.Text>
                        </Form.Group>
                    </div>


                    <div className="buttonsections">
                        <div >
                            <button className='savebutton' onClick={Submits}>Save</button>
                        </div>
                        <div>
                            <button className='cancelbutton'>Cancel</button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Contact