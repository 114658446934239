import { HttpInterceptor } from "../auth/http-interceptors";

export function getServiceBooking(page) {
  return HttpInterceptor.get(`/admin/service-bookings?sortBy=updatedAt:desc&&limit=10&&page=${page}`);
}
export function getServiceBooking1(page) {
  return HttpInterceptor.get(`/admin/service-bookings`);
}

export function getServiceBookingByQuery(value) {
  return HttpInterceptor.get(
    `/admin/service-bookings?limit=100&search=${value}`
  );
}
