import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Stripe from "../../../../assets/Stripe_Logo.png";
import paypal_logo from "../../../../assets/paypal.webp";
import {
  FilterOrder,
  OrdersList,
  OrdersListByQuery,
  OrdersListByStatus,
} from "../../../../services/list/listServices";
import "./styles/Allorders.scss";

import Pagination from "rc-pagination";
import arrowleft from "../../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../../assets/loginLogos/rightarrow.png";

const options = [
  { id: 0, value: "All", label: "All" },
  { id: 1, value: "New Order", label: "New Order" },
  { id: 2, value: "Confirmed", label: "Confirmed" },
  { id: 3, value: "In Progress", label: "In Progress" },
  { id: 4, value: "Cancelled by Buyer", label: "Cancelled by Buyer" },
  { id: 5, value: "Cancelled by Seller", label: "Cancelled by Seller" },
  { id: 6, value: "Delivered", label: "Delivered" },
];

function Allorder() {
  const history = useNavigate();

  const [selectedOption, setSelectedOption] = useState(0);
  const [value, onChange] = useState();
  const [value1, onChange1] = useState();

  const [orderLimit, setOrderLimit] = useState(10);

  const [limit, setLimit] = useState([]);
  const [current, setCurrent] = useState(1);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption.id);
  };

  const tableheaders = [
    {
      header: "Order Id",
    },
    {
      header: "WomeynPatron",
    },
    {
      header: "Order Date",
    },
    {
      header: "WomeynPreneur",
    },
    {
      header: "Order",
    },
    {
      header: "Payment Method",
    },
    {
      header: "Order Amount",
    },
    {
      header: "Order Status",
    },
  ];

  const [tables, setTables] = useState([]);

  useEffect(() => {
    getOrdersList();
  }, [selectedOption, current]);

  const getOrdersList = () => {
    OrdersList(current)
      .then((res) => {
        setTables(res.data.results);
        setLimit(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchCurrentData = async (current) => {
    const resdata = await OrdersList(current);
    setLimit(resdata?.data);
  };
  const handleAdminChangePagecount = async (e) => {
    setCurrent(e);
    const current = e;
    await fetchCurrentData(current);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const orderSearch = () => {
    OrdersListByQuery(searchText)
      .then((res) => {
        setTables(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  const getOrdersListByStatus = (id) => {
    OrdersListByStatus(selectedOption)
      .then((res) => {
        setTables(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  const loadMoreOrders = () => {
    setOrderLimit(orderLimit + 10);
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const filterOrderByDate = () => {
    let sDate = moment(startDate).format().slice(0, 10);
    let eDate = moment(endDate).format().slice(0, 10);

    FilterOrder(0, sDate, eDate)
      .then((res) => {
        setTables(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  console.log(tables,'tables')

  return (
    <div>
      <div className="mt-4 mb-5">
        <Row>
          <div className="d-flex justify-content-end">
            <Col lg={3}>
              <div className="py-0 col-lg-10">
                <div className="form-group input-group mb-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for order "
                    onChange={handleSearch}
                  />
                  <span
                    className="input-group-text"
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      className="icon-20"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={orderSearch}
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="py-0">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  showIcon
                  placeholderText="Select Start Date"
                />
              </div>
            </Col>
            <Col lg={3}>
              <div>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  showIcon
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="Select End Date"
                />
              </div>
            </Col>
            <Col lg={3}>
              <div>
                <Button
                  style={{ padding: 1, width: "40%" }}
                  onClick={filterOrderByDate}
                // onClick={() => history(`/womeyn/order-invoice`)}
                >
                  <div>
                    <div>Filter</div>
                  </div>
                </Button>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <Table hover responsive>
        <thead>
          <tr>
            {tableheaders.map((item, index) => {
              return <th>{item.header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tables.map((item, index) => {
            return (
              <>
                <tr
                  className="cursor-texts"
                  onClick={() => history(`/womeyn/view-order/${item.orderId}`)}
                >
                  <td>{item?.orderId} </td>
                  <td>{item.customerName?item.customerName:"-"}</td>
                  <td>{moment(item?.orderedDate).format("LL")}</td>
                  <td>{item.sellerBusinessName}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {item.itemsOrdered[0].productThumbImage ? (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/${item.itemsOrdered[0].productThumbImage}`}
                          alt="no image"
                          className="image-circle"
                        />
                      ) : (
                        <img
                          src={
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo13dMxSP1rF3oMPw_0yGj892NadEqEnQ_Uw&usqp=CAU"
                          }
                          alt="no image"
                          width={30}
                          height={30}
                          style={{ borderRadius: "20%" }}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {item?.paymentMethod === "Stripe" ? (
                        <img
                          src={Stripe}
                          alt="no image"
                          width={70}
                          // height={30}
                          style={{ borderRadius: "20%" }}
                        />
                      ) : item?.paymentMethod === "PayPal" ? (
                        <img
                          src={paypal_logo}
                          alt="no image"
                          width={70}
                          // height={30}
                          style={{ borderRadius: "20%" }}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>A$ {item.totalOrderAmount}</td>
                  <td>
                    {item.stateId === 1 && (
                      <button
                        className="text-light box-shdows"
                        style={{ backgroundColor: "#B3B3FF" }}
                      >
                        New Order
                      </button>
                    )}
                    {item.stateId === 2 && (
                      <button
                        className="text-light box-shdows"
                        style={{ backgroundColor: "#3572EF" }}
                      >
                        Confirmed
                      </button>
                    )}
                    {item.stateId === 3 && (
                      <button
                        className="text-light box-shdows"
                        style={{ backgroundColor: "#ffff00" }}
                      >
                        In Progress
                      </button>
                    )}
                    {item.stateId === 4 && (
                      <button
                        className="text-light box-shdows"
                        style={{ backgroundColor: "#ff1a1a" }}
                      >
                        Cancelled by WomeynPatron
                      </button>
                    )}
                    {item.stateId === 5 && (
                      <button
                        className="text-light box-shdows"
                        style={{ backgroundColor: "#ff1a1a" }}
                      >
                        Cancelled by WomeynPreneur
                      </button>
                    )}
                    {item.stateId === 6 && (
                      <button
                        className="text-light box-shdows"
                        style={{ backgroundColor: "#28a745" }}
                      >
                        Delivered
                      </button>
                    )}
                    {item.stateId === 7 && <span className="progresss">Packing Completed</span>}
                    {item.stateId === 8 && <span className="progresss">Shipping Completed</span>}
                    {item.stateId === 9 && <span style={{ backgroundColor: "#28a745" }} className="text-light box-shdows">Out for Delivery</span>}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        className="pagination-data mt-4"
        total={limit.totalPages * 10}
        onChange={handleAdminChangePagecount}
        current={current}
        itemRender={PrevNextArrow}
        breakLabel="..."
      />
      {/* <div className="loadMore-div">
        <Button className="mt-5 loadMore-button" onClick={loadMoreOrders}>
          Load More
        </Button>
      </div> */}
    </div>
  );
}

export default Allorder;
