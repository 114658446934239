import { Fragment, memo, useEffect, useMemo, useState } from "react";
// Router
import { Link, useNavigate } from "react-router-dom";

// React-bootstrap
import {
  Button,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";

//Components
import Card from "../../../components/bootstrap/card";

import {
  createAdmin,
  getAdminServices,
  getAdminServicesForChecking,
} from "../../../services/admin/adminServices";
import { PermissionListServices } from "../../../services/permission/permissionServices";

import "./styles/Adduser.scss";
import { toast } from 'react-toastify';

const MyCheckBoxList = [
  {
    active: true,
    name: "Select All",
    id: 0,
  },
  {
    active: true,
    name: "Dashboard",
    id: 1,
  },
  {
    active: true,
    name: "User Management",
    id: 2,
  },
  {
    active: true,
    name: "Coupons",
    id: 3,
  },
  {
    active: true,
    name: "Ticket and Query",
    id: 4,
  },
  {
    active: true,
    name: "Contact Us Messages",
    id: 5,
  },
  {
    active: true,
    name: "WomeynPreneur Approval",
    id: 6,
  },
  {
    active: true,
    name: "Products",
    id: 7,
  },
  {
    active: true,
    name: "Services",
    id: 8,
  },
  {
    active: true,
    name: "Categories",
    id: 9,
  },
  {
    active: true,
    name: "Manage Banners",
    id: 10,
  },
  {
    active: true,
    name: "Recommendations",
    id: 11,
  },
  {
    active: true,
    name: "Orders",
    id: 12,
  },
  {
    active: true,
    name: "Service Bookings",
    id: 13,
  },
  {
    active: true,
    name: "Payments",
    id: 14,
  },
  {
    active: true,
    name: "Transactions",
    id: 15,
  },
  {
    active: true,
    name: "Subscribers",
    id: 16,
  },
  {
    active: true,
    name: "Blogs",
    id: 17,
  },
  {
    active: true,
    name: "Events",
    id: 18,
  },
  {
    active: true,
    name: "Contact Us Info",
    id: 19,
  },
  {
    active: true,
    name: "Subscription",
    id: 20,
  },
  {
    active: true,
    name: "Terms & Conditions",
    id: 21,
  },
];

const AddUser = memo((props) => {
  const [data, setData] = useState(MyCheckBoxList.sort((a, b) => a.id - b.id));

  const [selectAll, setSelectAll] = useState(false);

  const Checkbox = ({ obj, onChange }) => {
    return (
      <>
        <input
          type="checkbox"
          id={`custom-checkbox-${obj.index}`}
          name={obj.name}
          value={obj.checked}
          checked={selectAll || obj.checked}
          onChange={() => onChange({ ...obj, checked: !obj.checked })}
          style={{ marginRight: 10 }}
        />
        {obj.name}
      </>
    );
  };


  const isVerified = useMemo(() => {
    return data.every((d) => d.checked);
  }, [data]);

  const navigate = useNavigate();

  const [permission, setPermission] = useState([]);
  const [permissionId, setPermissionId] = useState([]);
  const [permissionName, setPermissionName] = useState([]);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [availableEmail, setAvailableEmail] = useState([]);
  const [availableContact, setAvailableContact] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [contactError, setContactError] = useState(false);

  const [error, setError] = useState(false);

  useEffect(() => {
    PermissionListServices()
      .then((res) => {
        setPermission(res?.data?.results);
      })
      .catch((err) => console.log(err));

    getAdminServicesForChecking()
      .then((res) => {
       
        
        setAvailableEmail(res?.data?.results.map((e) => e.email));
        setAvailableContact(res?.data?.results.map((e) => e.contactNumber));
      })
      .catch((err) => console.log(err));

    setData((prevData) =>
      prevData.map((checkbox) =>
        checkbox.id === 0 ? { ...checkbox, checked: selectAll } : checkbox
      )
    );
  }, [selectAll]);

  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    one: null,
    two: null,
    three: null,
    four: null,
  });

  const { name, email, number, one, two, three, four } = form;

  const handleSubmit = () => {
    if (name.length === 0 || email.length === 0 || number.length === 0) {
     
        setError(true);
     
    } else if (availableEmail.includes(`${email}`) === true) {
      setEmailError(true);
    } else if (availableContact.includes(`${number}`)) {
      setContactError(true);
    } else if (name && email && number) {
      const reg =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      
      if(reg.test(email))
      {
      stmacess();

      }
      else{
      setError(false);
      toast.error("Please Enter Valid Email Id")

      }
    }
  };

  const handleChange = (item) => {
    setData((prevData) =>
      prevData.map((checkbox) =>
        checkbox.id === item.id
          ? { ...checkbox, checked: item.checked }
          : checkbox
      )
    );
  };

  const handleContinue = () => {
    let id = [];
    let name = [];

    if (selectAll) {
      MyCheckBoxList.slice(1).forEach((checkbox) => {
        id.push(checkbox.id);
        name.push(checkbox.name);
      });
    } else {
      data.forEach((checkbox) => {
        if (checkbox.checked) {
          id.push(checkbox.id);
          name.push(checkbox.name);
        }
      });
    }

    setPermissionId(id);
    setPermissionName(name);

    if (id.length === 0) {
      setError(true);
    } else {
      confirm();
      setError(false);

    }
  };


  useEffect(()=>{
if(data?.length>0)
{
  setError(false);

}
  },[data])

  const stmacess = () => {
    if (name.length === 0 || email.length === 0 || number.length === 0) {
      setError(true);
    }

    document.getElementById("basic").classList.remove("show");
    document.getElementById("stmacs").classList.add("show");
    document.getElementById("iq-tracker-position-1").classList.remove("active");
    document.getElementById("iq-tracker-position-1").classList.add("done");
    document.getElementById("iq-tracker-position-2").classList.add("active");
  };
  const confirm = () => {
    document.getElementById("stmacs").classList.remove("show");
    document.getElementById("confirm").classList.add("show");
    document.getElementById("iq-tracker-position-2").classList.remove("active");
    document.getElementById("iq-tracker-position-2").classList.add("done");
    document.getElementById("iq-tracker-position-3").classList.add("active");
  };

  const goBack = () => {
    document.getElementById("basic").classList.add("show");
    document.getElementById("stmacs").classList.remove("show");
    document.getElementById("iq-tracker-position-2").classList.remove("active");
    document.getElementById("iq-tracker-position-2").classList.add("done");
    document.getElementById("iq-tracker-position-1").classList.add("active");
  };

  const goBack2 = () => {
    document.getElementById("stmacs").classList.add("show");
    document.getElementById("confirm").classList.remove("show");
    document.getElementById("iq-tracker-position-3").classList.remove("active");
    document.getElementById("iq-tracker-position-3").classList.add("done");
    document.getElementById("iq-tracker-position-2").classList.add("active");
  };

  const createUser = () => {
    let pId = permissionId.toString();
    let pName = permissionName.toString();
    const reg =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
    if(reg.test(form.email[0]))
    {
      const data = {
        email: form.email[0],
        gender: "male",
        password: "abc123=00",
        firstName: form.name[0],
        lastName: "admin",
        contactNumber: form.number[0],
        permissionIds: pId,
        permissionNames: pName,
      };

      createAdmin(data)
        .then(async (result) => {
      toast.success("User Added Successfully")
          setTimeout(() => {
            navigate("/womeyn/user-management");
          }, 500);
          

        })
        .catch((err) => {
          if (err) {
            toast.error(err?.response?.data?.message)
          }
        });
    }
    else{
      toast.error("Please Enter Valid Email Id")
    }
    
   
  };

  const getData = () => {
    let a = [];
    const selectedData = data.map((e) => (e.checked ? a.push(e.order) : null));
  };


  return (
    <Fragment>
      <div className="title-with-icon">
        <h3>Add User</h3>
      </div>
      <Row>
        <Col sm="12">
          <ul
            className="text-center iq-product-tracker mb-0 py-4"
            id="progressbar"
          >
            <li
              className="active iq-tracker-position-0"
              id="iq-tracker-position-1"
            >
              User Info
            </li>
            <li className="iq-tracker-position-0" id="iq-tracker-position-2">
              System Access
            </li>
            <li className="iq-tracker-position-0" id="iq-tracker-position-3">
              Confirm
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mx-auto w-100">
        <Col lg="10">
          <Card>
            <Card.Body className="p-0">
              <div id="basic" className="iq-product-tracker-card show b-0">
                <Form>
                  <div className="field-container">
                    <Form.Label htmlFor="validationServer01">Name</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      className={true ? "" : "is-valid"}
                      id="name"
                      value={name}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [e.target.name]: [
                            e.target.value.replace(/[^a-z + $]/gi, ""),
                          ],
                        });
                      }}
                      required
                      style={{ color: "black" }}
                    />
                    <div>
                      {error && name.length === 0 ? (
                        <div className="text-danger">Name is required</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="field-container">
                    <Form.Label htmlFor="validationServer01">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      name="email"
                      type="email"
                      className={true ? "" : "is-valid"}
                      id="email"
                      value={email}
         
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [e.target.name]: [e.target.value],
                        });
                      }}
                      required
                      style={{ color: "black" }}
                    />
                    <div>
                      {error && email.length === 0 ? (
                        <div className="text-danger">Email is required</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      {emailError ? (
                        <div className="text-danger">
                          Email is already exists
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="field-container">
                    <Form.Label htmlFor="validationServer01">
                      Contact Number
                    </Form.Label>
                    <div className="d-flex numbercontacts">
                      <div className="numbercontactss">+61</div>
                      <div className="inputnumbercontact">
                        <Form.Control
                          name="number"
                          type="text"
                          className={true ? "" : "is-valid"}
                          id="number"
                          value={number}
                          //   defaultValue=""
                          // onChange={handleChanges}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              [e.target.name]: [
                                e.target.value.replace(/[^0-9]/gi, ""),
                              ],
                            });
                          }}
                          required
                          style={{ color: "black" }}
                          maxLength={9}
                        />
                      </div>
                    </div>

                    <div>
                      {error && number.length === 0 ? (
                        <div className="text-danger">Number is required</div>
                      ) : contactError ? (
                        <div className="text-danger">
                          Contact number is already exists
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr className="hr-horizontal" />
                  <div>
                    <Button variant="secondary" onClick={() => navigate(-1)}>
                      Back
                    </Button>{" "}
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                     
                      className="margin-left-button "
                    >
                      Continue
                    </Button>{" "}
                  </div>
                </Form>
              </div>
              <div id="stmacs" className="iq-product-tracker-card  b-0">
                <div className="field-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="p-1" style={{ width: "50%" }}>
                      <Checkbox
                        obj={data[0]}
                        onChange={(item) => {
                          // Update the state of the "Select All" checkbox
                          setSelectAll(item.checked);
                          // Update the state of all checkboxes based on the "Select All" checkbox selection
                          setData((prevData) =>
                            prevData.map((checkbox) =>
                              checkbox.id === 0
                                ? { ...checkbox, checked: item.checked }
                                : checkbox
                            )
                          );
                        }}
                      />
                    </div>
                    {data
                      .filter((checkbox) => checkbox.id !== 0)
                      .map((checkbox) => (
                        <div
                          key={checkbox.id}
                          className="p-1"
                        
                          style={{ width: "50%" }}
                        >
                          <Checkbox obj={checkbox} onChange={handleChange} />
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  {error ? (
                    <div className="text-danger">
                      Please select at least one field
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <hr className="hr-horizontal" />
                <div>
                  <Button variant="secondary" onClick={goBack}>
                    Back
                  </Button>{" "}
                  <Button
                    variant="primary"
                    onClick={handleContinue}
                    className="margin-left-button "
                  >
                    Continue
                  </Button>{" "}
                </div>
              </div>
              <div id="confirm" className="iq-product-tracker-card  b-0">
                <Form>
                  <div className="field-container">
                    <Form.Label htmlFor="validationServer01">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={true ? "" : "is-valid"}
                      id="validationServer01"
                      value={form.email}
                      required
                      style={{ color: "black" }}
                      disabled
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </div>
                  <div className="user-card-content field-container">
                    <Form.Label htmlFor="validationServer01">
                      Permissions Given
                    </Form.Label>
                    <div className="d-flex flex-wrap">
                      {permissionName.map((item) => {
                        return (
                          <div className="permission flex-container mt-1 flex-wrap">
                            <span>{item}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <hr className="hr-horizontal" />
                  <Button variant="secondary" onClick={goBack2}>
                    Back
                  </Button>{" "}
                  <Button
                    variant="primary"
                    onClick={createUser}
                    className="margin-left-button "
                  >
                    Save
                  </Button>{" "}
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

export default AddUser;
