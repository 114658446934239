import { HttpInterceptor } from "../auth/http-interceptors";

export function getSingleSellerServices(data) {
    return HttpInterceptor.get(
        `/admin/services/${data}`
    );
}

export function ServiceOrdergetsingle(serviceorderid) {
    return HttpInterceptor.get(`/admin/service-bookings?orderId=${serviceorderid}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

export function ServiceDownloadBillInvoice(serviceorderid) {
    return HttpInterceptor.get(`/admin/service/invoice/${serviceorderid}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

export function ServiceDownloadRevenue(serviceorderid) {
    return HttpInterceptor.get(`/admin/service/revenue/${serviceorderid}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}