import React from 'react'
import { Card } from 'react-bootstrap'
import { Button, Col, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import Productplans from './productplans/Productplans';
import Serviceplans from './serviceplans/Serviceplans';
import Productandserviceplans from './productandserviceplans/Productandserviceplans';

function Subscribeplans() {



    return (
        <div>
            <Card>
                <div>
                    <Tab.Container defaultActiveKey="1">
                        <Nav className="tab-bottom-bordered">
                            <div
                                className="mb-0 nav nav-tabs"
                                id="nav-tab1"
                                role="tablist"
                            >
                                <Nav.Link
                                    eventKey="1"
                                    id="nav-admin"
                                    data-bs-target="#tab-admin"
                                    aria-controls="pills-admin"
                                    data-bs-toggle="tab"
                                    type="button"
                                    role="tab"
                                    aria-selected="true"
                                >
                                    <span className="iq-mail-section">
                                        Products
                                    </span>
                                </Nav.Link>
                                <Nav.Link
                                    eventKey="2"
                                    id="nav-sellers"
                                    data-bs-target="#tab-sellers"
                                    aria-controls="pills-sellers"
                                    data-bs-toggle="tab"
                                    type="button"
                                    role="tab"
                                    aria-selected="false"
                                >
                                    <span className="iq-mail-section">
                                        Services
                                    </span>
                                </Nav.Link>
                                <Nav.Link
                                    eventKey="3"
                                    id="nav-endc"
                                    data-bs-target="#tab-endc"
                                    aria-controls="pills-endc"
                                    data-bs-toggle="tab"
                                    type="button"
                                    role="tab"
                                    aria-selected="false"
                                >
                                    <span className="iq-mail-section">
                                        Products and Services
                                    </span>
                                </Nav.Link>
                            </div>
                        </Nav>
                        <Tab.Content
                            className="mt-4 iq-tab-fade-up"
                            id="nav-tabContent"
                        >
                            <Tab.Pane
                                eventKey="1"
                                id="nav-admin"
                                role="tab"
                                aria-labelledby="nav-admin-tab"
                            >


                                <Productplans />
                            </Tab.Pane>
                            <Tab.Pane
                                eventKey="2"
                                id="nav-sellers"
                                role="tab"
                                aria-labelledby="nav-sellers-tab"
                            >
                                <Serviceplans />
                            </Tab.Pane>
                            <Tab.Pane
                                eventKey="3"
                                id="nav-endc"
                                role="tab"
                                aria-labelledby="nav-endc-tab"
                            >
                                <Productandserviceplans />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Card>
        </div>
    )
}

export default Subscribeplans