
import { useNavigate, useParams } from "react-router-dom";
import {
  addSubscription,
} from "../../../../../services/subscription/subscriptionServices";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";
import { Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
export default function Productforms() {
  const [content, setContent] = useState("");
  const [logs, setLogs] = useState([]);
  const appendLog = useCallback(
    (message) => {
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter Plan Details"

    }),
    []
  );

  const onChange = useCallback(
    (newContent) => {
      appendLog(`onChange triggered with ${newContent}`);
    },
    [appendLog]
  );

  useEffect(() => {
  }, [onChange]);

  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );
  const router = useNavigate();
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [productListing, setproductListing] = useState("");
  const [description, setDescription] = useState("");
  const [flatPercentage, setFlatPercentage] = useState("");
  const [durationMonth, setDurationMonth] = useState("");


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const createPlan = () => {
    setLoading(true);
    if (name?.length === 0 || amount?.length === 0 || productListing?.length === 0 || description?.length === 0 || content?.length === 0 || durationMonth?.length === 0 || flatPercentage?.length === 0) {
      setError(true);
    }

    if (name && amount && productListing && description && content && durationMonth && flatPercentage) {
      let data = {
        planTypeId: 1,
        planName: name,
        planPrice: amount,
        allowedProducts: productListing,
        planDescription: description,
        allowedServices: null,
        planDetails: content,
        flatPercentage: Number(flatPercentage),
        durationMonth: durationMonth,
      };


      toast.success("Created Success")

      addSubscription(data)
        .then((res) => {
          setLoading(false);
          BackPage();
        })
        .catch((err) => console.log(err));
    }


  };

  const BackPage = () => {
    router("/womeyn/subscribeplans");
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Row className="mb-3 mt-2">
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="planName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    {error && name?.length <= 0 ? <div className="text-danger">Name is Required</div> : null}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Package Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Amount"
                    name="amount"
                    value={amount}

                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Form.Text className="text-muted text-danger">
                    {error && amount?.length <= 0 ? <div className="text-danger">Amount is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>


          <Row className="mb-3 mt-2">
            <Col xs={6}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Products Listing</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter number of products"
                    name="productListing"
                    value={productListing}
                    onChange={(e) => setproductListing(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    {error && productListing?.length <= 0 ? <div className="text-danger">ProductListing is Required</div> : null}
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={10}
                  />
                  <Form.Text className="text-muted">
                    {error && description?.length <= 0 ? <div className="text-danger">Description is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row className="mb-3 mt-2">
            <Col xs={6}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Flat Percentage</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter number of Flat Percentage"
                    name="flatPercentage"
                    value={flatPercentage}
                    onChange={(e) => setFlatPercentage(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    {error && flatPercentage?.length <= 0 ? <div className="text-danger">FlatPercentage is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Duration Month</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter duration Month"
                    name="durationMonth"
                    onChange={(e) => setDurationMonth(e.target.value)}
                    rows={10}
                  />
                  <Form.Text className="text-muted">
                    {error && durationMonth?.length <= 0 ? <div className="text-danger">DurationMonth is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <div>
            <Form.Label>Plan Details</Form.Label>
          </div>
          <JoditEditor
            value={content}
            config={config}
            tabIndex={1}
            onBlur={onBlur}
            onChange={onChange}
          />


          <Form.Text className="text-muted">
            {error && content?.length <= 0 ? <div className="text-danger">Content is Required</div> : null}

          </Form.Text>
          <div>
            <div className="buttonsections">
              <div>
                <button className="savebutton" onClick={createPlan}>
                  Save
                </button>
              </div>
              <div>
                <button className="cancelbutton" onClick={BackPage}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
