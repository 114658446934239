import JoditEditor from "jodit-react";
import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import {
  addSubscription,
  getSubscriptionById,
} from "../../../../../services/subscription/subscriptionServices";
import { toast } from "react-toastify";
function ProductServiceEditForm() {
  const [content, setContent] = useState("");
  const [logs, setLogs] = useState([]);
  const appendLog = useCallback(
    (message) => {
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter Plan Description"
    }),
    []
  );

  const onChange = useCallback(
    (newContent) => {
    },
    [appendLog]
  );

  useEffect(() => {
  }, [onChange]);

  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );

  const router = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [subscriptioinforms, setSubscriptionForms] = useState({
    planName: "",
    planPrice: 0,
    allowedProducts: 0,
    allowedServices: 0,
    planDescription: "",
    flatPercentage: 0,
    durationMonth: 0,
  });

  const {
    planName,
    planPrice,
    allowedServices,
    planDescription,
    flatPercentage,
    durationMonth,
    allowedProducts,
  } = subscriptioinforms;



  const handleChange = (e) => {
    setSubscriptionForms({
      ...subscriptioinforms,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setLoading(false);
    if (id) {
      getProductPlan();
    }
    else {
      setSubscriptionForms({})
    }
  }, [loading]);

  const getProductPlan = () => {
    getSubscriptionById(id)
      .then((res) => {
        setSubscriptionForms(res?.data);
        setContent(res?.data?.planDetails.replace(/&lt;/g, "<"));
      })
      .catch((err) => console.log(err));
  };




  const createPlan = () => {
    setLoading(true);


    if (planName?.length === 0 || planPrice?.length === 0 || allowedProducts?.length === 0 || planDescription?.length === 0 || content?.length === 0 || flatPercentage?.length === 0 || allowedServices?.length === 0 || durationMonth?.length === 0) {
      setError(true);
    }

    if (
      planName &&
        planPrice?.length > 0 ? planPrice : 0 &&
          allowedProducts?.length > 0 ? allowedProducts : 0 &&
            planDescription &&
            flatPercentage?.length > 0 ? flatPercentage : 0 &&
              durationMonth?.length > 0 ? durationMonth : 0 && content
                &&
                allowedServices?.length > 0 ? allowedServices : 0
    ) {
      let data = {
        planId: id,
        planTypeId: 3,
        planName: planName,
        planPrice: planPrice,
        allowedProducts: allowedProducts,
        planDescription: planDescription,
        allowedServices: allowedServices,
        planDetails: content,
        flatPercentage: Number(flatPercentage),
        durationMonth: durationMonth,
      };

      addSubscription(data)
        .then((res) => {
          toast.success("Updated Success")
          setLoading(false);
          BackPage();
        })
        .catch((err) => console.log(err));
    }

  };

  const BackPage = () => {
    router("/womeyn/subscribeplans");
  };

  return (
    <div>
      <Card>
        <div className="fs-5 ms-4 mt-4">Product and Service Plans</div>
        <Card.Body>
          <Row className="mb-3 mt-2">
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="planName"
                    onChange={handleChange}
                    value={planName}
                    required
                  />
                  <Form.Text className="text-muted">
                    {error && planName?.length <= 0 ? <div className="text-danger">PlanName is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Package Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Amount"
                    name="planPrice"
                    onChange={handleChange}
                    value={planPrice}
                    required

                  />
                  <Form.Text className="text-muted">
                    {error && planPrice?.length <= 0 ? <div className="text-danger">PlanPrice is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row className="mb-3 mt-2">
            <Col xs={6}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Products Listing</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of products"
                    name="allowedProducts"
                    value={allowedProducts}
                    onChange={handleChange}
                    required

                  />
                  <Form.Text className="text-muted">
                    {error && allowedProducts?.length <= 0 ? <div className="text-danger">AllowedProducts is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col xs={6}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Services Listing</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of products"
                    name="allowedServices"
                    value={allowedServices}
                    onChange={handleChange}
                    required

                  />
                  <Form.Text className="text-muted">
                    {error && allowedServices?.length <= 0 ? <div className="text-danger">AllowedServices is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row className="mb-3 mt-2">
            <Col xs={6}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="planDescription"
                    value={planDescription}
                    onChange={handleChange}
                    rows={5}
                    required

                  />
                  <Form.Text className="text-muted">
                    {error && planDescription?.length <= 0 ? <div className="text-danger">PlanDescription is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col xs={6}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Flat Percentage</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter number of Flat Percentage"
                    name="flatPercentage"
                    value={flatPercentage}
                    onChange={handleChange}
                    required

                  />
                  <Form.Text className="text-muted">
                    {error && flatPercentage?.length <= 0 ? <div className="text-danger">FlatPercentage is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row className="mb-3 mt-2">
            <Col lg="6">
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Duration Month</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter duration Month"
                    name="durationMonth"
                    value={durationMonth}
                    onChange={handleChange}
                    rows={10}
                    required

                  />
                  <Form.Text className="text-muted">
                    {error && durationMonth?.length <= 0 ? <div className="text-danger">DurationMonth is Required</div> : null}

                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <div>
            <Form.Label>Plan Details</Form.Label>
          </div>
          <JoditEditor
            value={content}
            config={config}
            tabIndex={1}
            onBlur={onBlur}
            onChange={onChange}
            required

          />

          <Form.Text className="text-muted">
            {error && content?.length <= 0 ? <div className="text-danger">Content is Required</div> : null}

          </Form.Text>
          <div>
            <div className="buttonsections">
              <div>
                <button className="savebutton" onClick={createPlan}>
                  Save
                </button>
              </div>
              <div>
                <button className="cancelbutton" onClick={BackPage}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProductServiceEditForm;
