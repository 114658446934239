import { HttpInterceptor } from "../auth/http-interceptors";

export function getSellerServices(page, id, stateId) {
  return HttpInterceptor.get(
    `/admin/sellers?sortBy=updatedAt:desc&&limit=10&&page=${page}&&typeId=${id}&&stateId=${stateId}`
  );
}

export function getApprovedSellerServices(page) {
  return HttpInterceptor.get(
    `/admin/sellers?sortBy=updatedAt:desc&&limit=10&&typeId=${0}&&stateId=${1}&&page=${page}`
  );
}

export function getSellerServicesByQuery(id, value) {
  return HttpInterceptor.get(
    `/admin/sellers?sortBy=updatedAt:desc&&limit=100&&typeId=${id}&&search=${value}`
  );
}

export function getSellerByIdServices(id) {
  return HttpInterceptor.get(`/admin/seller/profile/${id}`);
}

export function sellerApprovalServices(id, data) {
  return HttpInterceptor.patch(`/admin/sellers/${id}`, data);
}

export function getContentUpdateServices(page) {
  return HttpInterceptor.get(
    `/admin/sellers/content-updated?sortBy=updatedAt:desc&&typeId=0&&limit=10&&page=${page}`
  );
}

export function getContentUpdateServicesByQuery(value) {
  return HttpInterceptor.get(
    `/admin/sellers/content-updated?sortBy=updatedAt:desc&&typeId=0&&search=${value}`
  );
}

export function getRecommendedSellers() {
  return HttpInterceptor.get(
    `/admin/sellers?sortBy=_id:desc&&limit=100&&typeId=0&&stateId=1`
  );
}
