import React from 'react'
import b2 from '../../../../../assets/blogs/b2.png';
import b3 from '../../../../../assets/blogs/b1.png';
function Servicebanners() {
    const data = [1, 2, 3, 4]

  return (
    <div>
         <div className='d-flex justify-content-end align-items-center mb-5 mt-3'>
                {data?.length <= 4 && <button className='AddBlog' >+ Add Service Banner</button>}
            </div>

            <div className='cards row '>
                {data?.map((item, index) => {
                    return (
                        <div className='card col-lg-4'>
                            <div>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTArFtEHwyfoyHGhGSaSgC54UgXu4tueoKrIQ&usqp=CAU" alt="no image"
                                    style={{
                                        borderRadius: "10px",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                />
                            </div>
                            <div className='productplaniconssections mt-5 mb-3'>
                                <div>
                                    <img src={b3} alt="no image" className='iconedits' />
                                </div>
                                <div>
                                    <img src={b2} alt="no image" className='iconedits' />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
    </div>
  )
}

export default Servicebanners