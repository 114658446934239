import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import * as moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { getSingleSellerServices } from '../../../services/sellerservices/Seller_service_details';
import './styles.scss';
function ViewServiceScreen() {
    const { id } = useParams();
    const [datas, setDatas] = useState([]);
    const [invoiceloading, setInvoiceLoading] = useState(false);
    const [invoiceloading1, setInvoiceLoading1] = useState(false);
    const [billdata, setBillData] = useState("");
    const [billdata1, setBillData1] = useState("");

    useEffect(() => {
        getOrderservice();
    }, [])

    const getOrderservice = () => {
        let isCancelled = false;
        if (!isCancelled) {
            getSingleSellerServices(id).then((res) => {
                setDatas(res?.data);
            }).catch((err) => {
                console.log(err);
            })
        }
        return () => {
            isCancelled = false;
        }

    }




    return (
        <div>
            <h3 className="mb-4">Service Details </h3>

            <>
                <Row>
                    <Col>
                        <Card className="p-0 ">
                            <Card.Header className="d-flex justify-content-between">
                            </Card.Header>
                            <Card.Body className="m-0">
                                <div className="new-user-info">
                                    <h4>WomeynPreneur Details</h4>
                                    <div className='mt-4 mb-3'>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6>Profile:</h6>
                                                {datas.sellerInformation?.profileImageName ? <>
                                                    <img
                                                        src={`${process.env.REACT_APP_IMAGE_URL}/${datas.sellerInformation?.profileImageName}`}
                                                        style={{
                                                            width: "100px",
                                                            height: "100px",
                                                            borderRadius: "50%"
                                                        }}
                                                    />
                                                </> : <>
                                                    <img src="https://media.istockphoto.com/id/1451587807/vector/user-profile-icon-vector-avatar-or-person-icon-profile-picture-portrait-symbol-vector.jpg?s=612x612&w=0&k=20&c=yDJ4ITX1cHMh25Lt1vI1zBn2cAKKAlByHBvPJ8gEiIg="
                                                        style={{
                                                            width: "100px",
                                                            height: "100px",
                                                            borderRadius: "50%"
                                                        }}
                                                    />
                                                </>}

                                            </div>
                                            <div className="col-md-6">
                                                <h6>Name:</h6>
                                                <p>{datas.sellerInformation?.firstName} </p>
                                            </div>

                                        </div>
                                        <div className="row mt-4">

                                            <div className="col-md-6">
                                                <h6>Last Name:</h6>
                                                <p>{datas.sellerInformation?.lastName}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <h6>BusinessName</h6>
                                                <p>{datas.sellerInformation?.businessName}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="new-user-info">
                                    <h4>Service Details</h4>



                                    <div className='container'>

                                        {datas?.serviceDetails?.map((item, index) => {
                                            return (
                                                <div key={index}>

                                                    <div className='row gap-1'>
                                                        {item?.serviceVariations?.map((items, index) => {
                                                            return (
                                                                <div className='card12 col-lg-6 col-md-10 col-xs-10 col-sm-10 ' key={index}

                                                                >

                                                                    <div className="row mt-4">
                                                                        <div className="col-md-6">
                                                                            <h6>Title</h6>
                                                                            <p>{items?.title}</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <h6>Duration</h6>
                                                                            <p>{items?.durationDetails}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-4">
                                                                        <div className="col-md-6">
                                                                            <h6>Start Date</h6>
                                                                            <p>{moment(items?.startDate).format("LLLL")}</p>

                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <h6>End Date</h6>
                                                                            <p>{moment(items?.endDate).format("LLLL")}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-4">
                                                                        <div className="col-md-6">
                                                                            <h6>NumberOfPeopleAllowed</h6>
                                                                            <p>{items?.numberOfPeopleAllowed}</p>

                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <h6>LinkDescription</h6>
                                                                            <p>{items?.linkDescription ? items?.linkDescription : "-"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-4">
                                                                        <div className="col-md-6">
                                                                            <h6>Address</h6>
                                                                            <p>{items?.address ? items?.address : "-"}</p>

                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <h6>IsCancellationAvailable</h6>
                                                                            <p>{items?.isCancellationAvailable ? "CancellationAvailable" : null}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-4">
                                                                        <div className="col-md-6">
                                                                            <h6>Price</h6>
                                                                            <p>A$ {items?.price}</p>

                                                                        </div>
                                                                        <div className="col-md-6">

                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h4>workingDays</h4>
                                                                        {items?.workingDays?.map((itemss, index) => {
                                                                            return (
                                                                                <div className='card23 '

                                                                                    key={index}>
                                                                                    <div className="row mt-4">
                                                                                        <div className="col-md-6">
                                                                                            <h6>DayName</h6>
                                                                                            <p>{itemss?.dayName}</p>

                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <h6>WorkingHours</h6>
                                                                                            <p>{itemss?.workingHours}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>





                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </>

        </div>
    )
}

export default ViewServiceScreen;