import { HttpInterceptor } from "../auth/http-interceptors";

export function getSubscription() {
  return HttpInterceptor.get(`/admin/plan?limit=100`);
}

export function addSubscription(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post("/admin/plan", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSubscriptionById(id) {
  return HttpInterceptor.get(`/admin/plan/${id}`);
}
