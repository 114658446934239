import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import {
  getTransaction,
  getTransactionByQuery,
} from "../../../../services/transactions/transaction";
import "./styles/Allorders.scss";

import Pagination from "rc-pagination";
import arrowleft from "../../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../../assets/loginLogos/rightarrow.png";

function UnpaidTransactions() {
  //   const history = useNavigate();

  const tableheaders = [
    {
      header: "Payment Id",
    },
    {
      header: "WomeynPreneurs Id",
    },
    {
      header: "WomeynPreneur",
    },
    {
      header: "Amount",
    },
    {
      header: "Payment Status",
    },
  ];

  const [tables, setTables] = useState([]);
  const [limit, setLimit] = useState([]);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    getTransaction()
      .then((res) => {

        let paid = res?.data?.results.filter(
          (e) => e.payment_status === "unpaid"
        );
        setTables(paid);
        setLimit(res?.data);
      })
      .catch((err) => console.log(err));
  }, [current]);

  const fetchCurrentData = async (current) => {
    const resdata = await getTransaction(current);
    setLimit(resdata?.data);
  };
  const handleAdminChangePagecount = async (e) => {
    setCurrent(e);
    const current = e;
    await fetchCurrentData(current);
  };

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const paymentSearch = () => {
    getTransactionByQuery(searchText)
      .then((res) => {
        let paid = res?.data?.results.filter(
          (e) => e.payment_status === "unpaid"
        );
        setTables(paid);
      })
      .catch((err) => console.log(err));
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };

  return (
    <div>
      <div className="mt-4 mb-5">
        <Row>
          <Col lg={6}>
            <div className="py-0 col-lg-10">
              <div className="form-group input-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for transaction "
                  onChange={handleSearch}
                />
                <span
                  className="input-group-text"
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    className="icon-20"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={paymentSearch}
                  >
                    <circle
                      cx="11.7669"
                      cy="11.7666"
                      r="8.98856"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></circle>
                    <path
                      d="M18.0186 18.4851L21.5426 22"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Table hover>
        <thead>
          <tr>
            {tableheaders.map((item, index) => {
              return <th>{item.header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tables.map((item, index) => {
            return (
              <>
                <tr
                  className="cursor-texts"
                  //   onClick={() => history(`/womeyn/order-view/${index + 1}`)}
                >
                  <td>{item.orderId}</td>
                  <td>{item.client_reference_id}</td>
                  <td>{item.businessName}</td>
                  <td>${item.amount_total}</td>
                  <td>
                    {/* {item.status === "Pending" && <span className='text-i box-shdow' style={{ backgroundColor: "#B3B3FF" }}>Pending</span>} */}
                    {item.payment_status === "unpaid" && (
                      <span
                        className="text-danger box-shdow"
                        style={{ backgroundColor: "#FF9999" }}
                      >
                        Unpaid
                      </span>
                    )}
                    {item.payment_status === "paid" && (
                      <span
                        className="text-success box-shdow"
                        style={{
                          backgroundColor: "#CCFFEE",
                          fontWeight: "600",
                        }}
                      >
                        Paid
                      </span>
                    )}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
      <div>
        <Pagination
          className="pagination-data mt-4"
          total={limit.totalPages * 10}
          onChange={handleAdminChangePagecount}
          current={current}
          itemRender={PrevNextArrow}
          breakLabel="..."
        />
      </div>
    </div>
  );
}

export default UnpaidTransactions;
