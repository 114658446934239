import { HttpInterceptor } from "../auth/http-interceptors";

export function getTicketList(page) {
  return HttpInterceptor.get(
    `/admin/customer/tickets`
  );
}

export function getTicketListContact(search) {
  return HttpInterceptor.get(
    `/admin/customer/contactus?search=${search}`
  );
}







export function getTicketListByStateId(id) {
  return HttpInterceptor.get(`/admin/customer/tickets?State=${id}`);
}

export function getTicketView(id) {
  return HttpInterceptor.get(`/admin/customer/ticket-view/${id}`);
}

export function addReply(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/admin/customer/tickets`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
