import { HttpInterceptor } from "../auth/http-interceptors";

export function Termscreateseller(data) {
    return new Promise((resolve, reject) => {
        HttpInterceptor.post("/admin/static-info", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function Termsupdateeseller(data) {
    return new Promise((resolve, reject) => {
        HttpInterceptor.post("/admin/static-info", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function Termsgetsingleeseller(data) {
    return new Promise((resolve, reject) => {
        HttpInterceptor.get(`/admin/static-info?typeId=${data}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}