import * as moment from "moment";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import arrowleft from "../../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../../assets/loginLogos/rightarrow.png";
import Loader from "../../../../components/Loader";
import {
  FilterOrder,
  OrdersListByStatus,
  OrdersListByStatusByQuery,
} from "../../../../services/list/listServices";
import "./styles/Allorders.scss";

function CancelledByBuyer() {
  const history = useNavigate();

  const [orderLimit, setOrderLimit] = useState(10);

  const [limit, setLimit] = useState([]);
  const [current, setCurrent] = useState(1);

  const tableheaders = [
    {
      header: "Order Id",
    },
    {
      header: "WomeynPatron",
    },
    
    {
      header: "Order Date",
    },
    {
      header: "WomeynPreneur",
    },
    {
      header: "Order",
    },
    {
      header: "Cancelled Date",
    },
    {
      header: "Order Amount",
    },
    {
      header: "Order Status",
    },
  ];

  const [tables, setTables] = useState([]);

  useEffect(() => {
    getOrdersListByStatus();
  }, [current]);

  const getOrdersListByStatus = () => {
    OrdersListByStatus(4, current)
      .then((res) => {
        setTables(res.data.results);
        setLimit(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchCurrentData = async (current) => {
    const resdata = await OrdersListByStatus(4, current);
    setLimit(resdata?.data);
  };
  const handleAdminChangePagecount = async (e) => {
    setCurrent(e);
    const current = e;
    await fetchCurrentData(current);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const orderSearch = () => {
    OrdersListByStatusByQuery(4, searchText)
      .then((res) => {
        setTables(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const filterOrderByDate = () => {
    let sDate = moment(startDate).format().slice(0, 10);
    let eDate = moment(endDate).format().slice(0, 10);

    FilterOrder(4, sDate, eDate)
      .then((res) => {
        setTables(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  const loadMoreOrders = () => {
    setOrderLimit(orderLimit + 10);
  };
  if (!tables) {
    return <Loader />;
  }
  return (
    <div>
      <div className="mt-4 mb-5">
        <Row>
          <div className="d-flex gap-5">
            <Col lg={6}>
              <div className="py-0 col-lg-10">
                <div className="form-group input-group mb-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for order "
                    onChange={handleSearch}
                  />
                  <span
                    className="input-group-text"
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      className="icon-20"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={orderSearch}
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
            </Col>
            <div className="d-flex align-items-center justify-content-center ms-3">
              <Col lg={6}>
                <div className="py-0">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    showIcon
                    placeholderText="Select Start Date"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    showIcon
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select End Date"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Button
                    style={{ padding: 8, width: "70%" }}
                    onClick={filterOrderByDate}
                  // onClick={() => history(`/womeyn/order-invoice`)}
                  >
                    <div>
                      <div>Filter</div>
                    </div>
                  </Button>
                </div>
              </Col>
            </div>
          </div>
        </Row>
      </div>
      <Table hover>
        <thead>
          <tr>
            {tableheaders.map((item, index) => {
              return <th>{item.header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tables.map((item, index) => {
            return (
              <>
                <tr
                  className="cursor-texts"
                  onClick={() => history(`/womeyn/view-order/${item.orderId}`)}
                >
                  <td>{item?.orderId} </td>
                  <td>{item.customerName?item.customerName:"-"}</td>
                  <td>{moment(item?.orderedDate).format("LL")}</td>
                  <td>{item.sellerBusinessName}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {item.itemsOrdered[0].productImageName ? (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/${item.itemsOrdered[0].productImageName}`}
                          alt="no image"
                          width={30}
                          height={30}
                          style={{ borderRadius: "20%" }}
                        />
                      ) : (
                        <img
                          src={
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo13dMxSP1rF3oMPw_0yGj892NadEqEnQ_Uw&usqp=CAU"
                          }
                          alt="no image"
                          width={30}
                          height={30}
                          style={{ borderRadius: "20%" }}
                        />
                      )}
                    </div>
                  </td>
                  <td>{item?.cancelledDate.slice(0, 10)}</td>
                  <td>A$ {item.totalOrderAmount}</td>
                  <td>
                    {item.stateId === 4 && (
                      <div
                        className="box-shadow"
                        style={{ backgroundColor: "#ff1a1a",fontSize:"12px" }}
                      >
                        Cancelled by WomeynPatron
                      </div>
                    )}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        className="pagination-data mt-4"
        total={limit.totalPages * 10}
        onChange={handleAdminChangePagecount}
        current={current}
        itemRender={PrevNextArrow}
        breakLabel="..."
      />
    </div>
  );
}

export default CancelledByBuyer;
