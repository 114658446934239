import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { EmailSubscriberPagination, EmailSubscribers } from '../../../services/emailsubscriber_services/emailsubscriber_services';
import moment from 'moment';
import Pagination from "rc-pagination";

import arrowleft from "../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../assets/loginLogos/rightarrow.png";

function AllSubscribers() {

    const [sellerLimit, setSellerLimit] = useState([]);
    const [sellerCurrent, setSellerCurrent] = useState(1);
    const [updateLimit, setUpdateLimit] = useState([]);
    const [updateCurrent, setUpdateCurrent] = useState(1);

    const [userEmails, setUserEmail] = useState([]);
    useEffect(() => {
        EmailSubscribers().then((res) => {
            setUserEmail(res?.data?.results)
            setUpdateLimit(res?.data)
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const fetchSellerUpdateCurrentData = async (updateCurrent) => {
        const resSellerData = await EmailSubscriberPagination(updateCurrent);
        setUserEmail(resSellerData?.data?.results);
    };
    const handleSellerUpdateChangePagecount = async (e) => {
        setUpdateCurrent(e);
        const updateCurrent = e;
        await fetchSellerUpdateCurrentData(updateCurrent);
    };

    const PrevNextArrow = (current, type, originalElement) => {
        if (type === "prev") {
            return (
                <button>
                    <img src={arrowright} alt="no image" className="arrows" />
                </button>
            );
        }
        if (type === "next") {
            return (
                <button>
                    <img src={arrowleft} alt="no image" className="arrows" />
                </button>
            );
        }
        return originalElement;
    };


    return (
        <div>
            <Card className='p-0 m-0'>
                <Card.Body>
                    <div className='mb-5 mt-2'>
                        <h4>Email Subscribers</h4>
                    </div>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>S.No</th>

                                <th>Email</th>
                                {/* <th>Date</th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {userEmails.map((data, index) => {

                                return (
                                    <>


                                        <>
                                            <tr
                                                key={data.transactionId}
                                                style={{ backgroundColor: "white" }}
                                            >

                                                <td style={{ color: "black" }}>
                                                    {index + 1}

                                                </td>
                                                <td style={{ color: "black" }}>
                                                    {data.email}

                                                </td>

                                                {/* <td style={{ color: "black" }}>
                                                    {data.createdAt ? data?.createdAt : "-"}
                                                </td> */}


                                            </tr>

                                        </>
                                    </>
                                )

                            })}
                        </tbody>
                    </Table>
                    <div>
                        <div>
                            <Pagination
                                className="pagination-data mt-4"
                                total={updateLimit.totalPages * 10}
                                onChange={handleSellerUpdateChangePagecount}
                                current={updateCurrent}
                                itemRender={PrevNextArrow}
                                breakLabel="..."
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default AllSubscribers