import { HttpInterceptor } from "../auth/http-interceptors";

export function ProductListServices(page) {
  return HttpInterceptor.get(
    `/admin/products-list?sortBy=updatedAt:desc&&limit=${10**page}`
  );
}

export function ProductListByStatusServices(page, id) {
  return HttpInterceptor.get(
    `/admin/products-list?sortBy=updatedAt:desc&&limit=10&&page=${page}&&stateId=${id}`
  );
}

export function ProductListServicesByQuery(value) {
  return HttpInterceptor.get(
    `/admin/products-list?sortBy=updatedAt:desc&limit=100&search=${value}`
  );
}

export function ProductDetailsServices(id) {
  return HttpInterceptor.get(`/admin/product-details/${id}`);
}

export function ProductApprovalServices(id, data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/admin/product-update-status/${id}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function ServicesList(countpage) {
  return HttpInterceptor.get(
    `/admin/services?sortBy=updatedAt:desc&&limit=${10**countpage}`
  );
}

export function ServicesListByStatus(page, id) {
  return HttpInterceptor.get(
    `/admin/services?sortBy=updatedAt:desc&&limit=10&&page=${page}&&stateId=${id}`
  );
}

export function ServicesListByQuery(value) {
  return HttpInterceptor.get(
    `/admin/services?sortBy=updatedAt:desc&&limit=100&&search=${value}`
  );
}

export function ServiceApproval(id, data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/admin/service-update-status/${id}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function ApprovedSellerServices(totalListcount) {
  return HttpInterceptor.get(
    `/admin/sellers?sortBy=_id:desc&&limit=${10**totalListcount}&&typeId=0&&stateId=1`
  );
}


export function ApprovedSellerServices1() {

  return HttpInterceptor.get(
    `/admin/sellers?sortBy=_id:desc&&limit=${10*120000}&&typeId=0&&stateId=1`
  );
}
export function getProductContentUpdatedServices(page) {
  return HttpInterceptor.get(
    `/admin/products/content-updated?sortBy=updatedAt:desc&&limit=10&&page=${page}`
  );
}

export function getProductContentUpdatedServicesByQuery(value) {
  return HttpInterceptor.get(
    `/admin/products/content-updated?sortBy=updatedAt:desc&search=${value}`
  );
}

export function getServiceContentUpdatedServices(page) {
  return HttpInterceptor.get(
    `/admin/services/content-updated?sortBy=updatedAt:desc&&limit=10&&page=${page}`
  );
}

export function getServiceContentUpdatedServicesByQuery(value) {
  return HttpInterceptor.get(
    `/admin/services/content-updated?sortBy=updatedAt:desc&search=${value}`
  );
}

export function OrdersList(page) {
  return HttpInterceptor.get(
    `/admin/orders?sortBy=updatedAt:desc&&limit=10&&page=${page}`
  );
}

export function OrdersListByQuery(value) {
  return HttpInterceptor.get(
    `/admin/orders?sortBy=updatedAt:desc&&limit=100&&search=${value}`
  );
}

export function OrdersListByStatus(id, page) {
  return HttpInterceptor.get(
    `/admin/orders?sortBy=updatedAt:desc&&stateId=${id}&&limit=10&&page=${page}`
  );
}

export function OrdersListByStatusByQuery(id, value) {
  return HttpInterceptor.get(`/admin/orders?stateId=${id}&search=${value}`);
}

export function FilterOrder(stateId, fromDate, toDate) {
  return HttpInterceptor.get(
    `/admin/orders?sortBy=updatedAt:desc&&limit=100&&stateId=${stateId}&&fromDate=${fromDate}&toDate=${toDate}`
  );
}

export function OrdersDetailsById(id) {
  return HttpInterceptor.get(`/admin/orders?orderId=${id}`);
}

export function PaymentList() {
  return HttpInterceptor.get(
    `/admin/payments?sortBy=updatedAt:desc`
  );
}

export function PaymentListByQuery(value) {
  return HttpInterceptor.get(
    `/admin/payments?sortBy=updatedAt:desc&&limit=100&&search=${value}`
  );
}

export function PaymentDetailsById(id) {
  return HttpInterceptor.get(`/admin/payments/${id}`);
}

export function GetContactUsList(page) {
  return HttpInterceptor.get(
    `/admin/contactus?sortBy=updatedAt:desc&&limit=10&&page=${page}`
  );
}

export function GetContactUsDetails(id) {
  return HttpInterceptor.get(`/admin/contactus?_id=${id}`);
}
