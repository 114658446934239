import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

import ReactHtmlParser from "react-html-parser";
import './styles/Sellerterms.scss';
import { Termsgetsingleeseller } from '../../../../../../../services/termsandcondition_services/termsandconditions_services';
function Enduserterms() {
    const router = useNavigate();
    const [allsellerterms, setAllSellerterms] = useState([]);


    const Navigatepath = (id) => {
        router(`/womeyn/customerterms/create`);
    };
    const EditNavigatepath = (id) => {
        router(`/womeyn/customerterms/create/2`);
    };
    useEffect(() => {

        const ids = 2;
        Termsgetsingleeseller(ids).then((res) => {
            setAllSellerterms(res);
        }).catch((err) => {
            console.log(err);
        })
    }, []);
    return (
        <div className='mainsellersterms'>
            <Card>
                <div>
                    <div className='d-flex justify-content-between p-2 blogbox'>
                        <div>
                            <h4>Terms And Conditions</h4>
                        </div>
                        <div className='mb-3'>
                            {allsellerterms ? <>
                                <button className='AddBlog' onClick={() => EditNavigatepath(allsellerterms)}>
                                    Edit Terms and Conditions
                                </button>
                            </> : <>
                                <button className='AddBlog' onClick={Navigatepath}>
                                    + Add a New Terms
                                </button>
                            </>}

                        </div>
                    </div>
                </div>


                <hr/>


                <div className='mt-4'>

                    <div>

                        {allsellerterms?.content ? <>{ReactHtmlParser(allsellerterms?.content.replace(/&lt;/g, "<"))}</> : null}
                    </div>

                </div>
            </Card>
        </div>
    )
}

export default Enduserterms;