import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import {
  getSubscriber,
  getSubscriberByQuery,
} from "../../../../services/transactions/transaction";
import "./styles/Allorders.scss";
function PaidSubscribers() {

  const tableheaders = [
    {
      header: "WomeynPreneur",
    },
    {
      header: "Payment Id",
    },
    {
      header: "Selected Plan",
    },
    {
      header: "Payment Status",
    },
    {
      header: "Subscribed Date",
    },
    {
      header: "Expiry Date",
    },
    {
      header: "Status",
    },
  ];

  const [tables, setTables] = useState([]);

  useEffect(() => {
    getSubscriber()
      .then((res) => {
        let paidSubscribers = res?.data[0].results.filter(
          (e) => e?.paymentStatus === "paid"
        );
        setTables(paidSubscribers);
      })
      .catch((err) => console.log(err));
  }, []);

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const subscriberSearch = () => {
    getSubscriberByQuery(searchText)
      .then((res) => {
        let paidSubscribers = res?.data[0].results.filter(
          (e) => e?.paymentStatus === "paid"
        );
        setTables(paidSubscribers);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="py-0 col-lg-5 mb-3">
        <div className="form-group input-group mb-0">
          <input
            type="text"
            className="form-control"
            placeholder="Search for subscribers... "
            onChange={handleSearch}
          />
          <span className="input-group-text" style={{ cursor: "pointer" }}>
            <svg
              className="icon-20"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={subscriberSearch}
            >
              <circle
                cx="11.7669"
                cy="11.7666"
                r="8.98856"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></circle>
              <path
                d="M18.0186 18.4851L21.5426 22"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </span>
        </div>
      </div>
      <Table hover>
        <thead>
          <tr>
            {tableheaders.map((item, index) => {
              return <th>{item.header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tables.map((item, index) => {
            return (
              <>
                <tr
                  className="cursor-texts"
                >
                  <td>
                    <div className="d-flex align-items-center">
                      <div>
                        {item?.profileImageName ? (
                          <img
                            className="image-circle"
                            src={`${process.env.REACT_APP_IMAGE_URL}/${item.profileImageName}`}
                            alt=""
                            loading="lazy"
                          />
                        ) : (
                          <img
                            className="image-circle"
                            src="https://media.istockphoto.com/id/1371904339/vector/young-smiling-woman-jane-peeking-out-and-looking-from-behind-round-hole-searching-concept-3d.jpg?s=612x612&w=0&k=20&c=8WEdrO7y4nlUHH9CRu9lKmlspgKtJK_mBAS9tqgMxlo="
                            alt=""
                            loading="lazy"
                          />
                        )}
                      </div>
                      <h6 className="ms-3">
                        {item.firstName} {item.lastName}
                      </h6>
                    </div>
                  </td>
                  <td>{item._id}</td>
                  <td>{item.selectedPlan}</td>
                  <td>
                    {item.paymentStatus === "initiated" && (
                      <span
                        className="text-i box-shdow"
                        style={{ backgroundColor: "#B3B3FF" }}
                      >
                        Pending
                      </span>
                    )}
                    {item.paymentStatus === "unpaid" && (
                      <span
                        className="text-danger box-shdow"
                        style={{ backgroundColor: "#FF9999" }}
                      >
                        Unpaid
                      </span>
                    )}
                    {item.paymentStatus === "paid" && (
                      <span
                        className="text-success box-shdow"
                        style={{
                          backgroundColor: "#CCFFEE",
                          fontWeight: "600",
                        }}
                      >
                        Paid
                      </span>
                    )}
                  </td>
                  <td>
                    {item?.planSubscribedDate
                      ? item?.planSubscribedDate.slice(0, 10)
                      : "-"}
                  </td>
                  <td>
                    {item?.planExpiryDate
                      ? item?.planExpiryDate.slice(0, 10)
                      : "-"}
                  </td>
                  <td>
                    {item?.isActive ? (
                      <>
                        {item.isActive === true ? (
                          <>
                            <p style={{ color: "green" }}>Active</p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: "red" }}>Inactive</p>
                          </>
                        )}
                      </>
                    ) : null}
                  </td>
                  <td></td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default PaidSubscribers;
