import { HttpInterceptor } from "../auth/http-interceptors";

export function DownloadBillInvoice(orderid) {
    return HttpInterceptor
        .get(`/admin/invoice/${orderid}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}


export function DownloadRevenue(orderid) {
    return HttpInterceptor
        .get(`/admin/revenue/${orderid}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

