import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import {
  OrdersDetailsById,
  PaymentDetailsById,
} from "../../../../services/list/listServices";
import "./styles/payment.scss";
export default function PaymentDetails() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { id } = useParams();
  const history = useNavigate();

  const [paymentDetails, setPaymentDetails] = useState("");

  useEffect(() => {
    getPaymentDetails();
  }, []);

  const getPaymentDetails = () => {
    PaymentDetailsById(id)
      .then((res) => {
        setPaymentDetails(res.data);
      })
      .catch((err) => console.log(err));
  };

  if (!paymentDetails) {
    return <Loader />;
  }
  console.log(paymentDetails.orderDetails[0],'paymentDetails.orderDetails[0].customerName')
  return (
    <div>
      <h3 className="mb-4">Order Details</h3>
      <div className="header-container">
        <p className="header-btn">{paymentDetails.orderDetails[0].orderId}</p>
      </div>
      <Row>
        <Col>
          <Card style={{ padding: "0px" }}>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">WomeynPatron Details</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="new-user-info">
                <div className="row">
                  <div className="col-md-6">
                    <h6>Name:</h6>
                    <p>{paymentDetails.orderDetails[0].customerName}</p>
                  </div>
                  <div className="col-md-6">
                    <h6>Address:</h6>
                    <p>
                      {/* {
                        paymentDetails.orderDetails[0].deliveryAddress[0]
                          .fullAddress
                      } */}
                      {paymentDetails?.orderDetails[0].deliveryAddress[0]?.cityName}, {paymentDetails?.orderDetails[0].deliveryAddress[0]?.stateName}, {paymentDetails?.orderDetails[0]?.deliveryAddress[0]?.countryName}, {paymentDetails?.orderDetails[0]?.deliveryAddress[0]?.pinCode}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Payment Status</h6>
                    <p>{paymentDetails.orderDetails[0].paymentStatus}</p>
                  </div>
                  <div className="col-md-6">
                    <h6>Payment Method</h6>
                    <p>{paymentDetails.orderDetails[0].paymentMethod}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Ordered Date</h6>
                    <p>
                      {moment(
                        paymentDetails.orderDetails[0].orderedDate
                      ).format("LLLL")}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6>Total Amount</h6>
                    <p>$ {paymentDetails.orderDetails[0].totalOrderAmount}</p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <div className=" mb-2">
        <h5>Customer Details</h5>
        <span>Name: {paymentDetails.orderDetails[0].customerName},</span>
        <div style={{ width: "30%" }}>
          <span>
            Address:{" "}
            {paymentDetails.orderDetails[0].deliveryAddress[0].fullAddress},
          </span>
        </div>
        <span>
          Payment Status: {paymentDetails.orderDetails[0].paymentStatus},
        </span>
        <div>
          <span>
            Payment Method: {paymentDetails.orderDetails[0].paymentMethod},
          </span>
        </div>
        <div>
          <span>
            Ordered On:{" "}
            {moment(paymentDetails.orderDetails[0].orderedDate).format("LLLL")},
          </span>
        </div>
        <div>
          <span>
            Total Amount: $ {paymentDetails.orderDetails[0].totalOrderAmount}
          </span>
        </div>
      </div> */}
      {paymentDetails.ordersList.map((item, index) => {
        return (
          <>
            <div className="header-container mt-4">
              <p className="header-btn">{item.orderId}</p>
            </div>
            {item.itemsOrdered.map((e) => {
              return (
                <>
                  <div className="mt-0">
                    {/* <div className="header-container">
                      <p className="header-btn">{item.orderId}</p>
                    </div> */}
                    <div className="content-container">
                      <div>
                        {e?.productThumbImage ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}/${e.productThumbImage}`}
                            className="set-img"
                          />
                        ) : (
                          <img
                            src={
                              "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fae01.alicdn.com%2Fkf%2FHTB1mnnhaYYI8KJjy0Faq6zAiVXa4%2FKids-Party-Wear-Frocks-Kids-Party-Dress-for-Winter-Girls-Puffy-Dresses-Children-Vintage-Dresses-Big.jpg&f=1&nofb=1&ipt=8ebd3f3ecdf1134c57d9f35534468b857f033a925d6c6bef13ba529599b4ddfd&ipo=images"
                            }
                            className="set-img"
                          />
                        )}
                      </div>
                      <div>
                        <p className="header-text">{e.productName}</p>
                        <span> {item.sellerBusinessName}</span>
                        <div>
                          {e.variations ? (
                            <>
                              {e.variations.map((item) => {
                                return (
                                  <div className="d-flex">
                                    <p>{item?.name}:</p>
                                    <p className="ms-1"> {item?.value}</p>
                                  </div>
                                );
                              })}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <p className="header-text">$ {e.price}</p>
                      </div>
                      <div>
                        <p>Delivery expected by Fri,Oct 26th 18</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </>
        );
      })}
      {/* <div className="d-flex mt-1 mx-3">
        {paymentDetails.orderDetails[0].deliveryAddress ? (
          <>
            <p className="header-text">Delivery Address:</p>
            <p className="d-flex mx-1">
              {paymentDetails.orderDetails[0].deliveryAddress[0].fullAddress}
            </p>
          </>
        ) : null}
      </div> */}
      <div className="footer-container mt-1">
        {/* {paymentDetails.orderDetails[0].orderedDate ? (
          <p className="mb-0">
            Ordered On{" "}
            {moment(paymentDetails.orderDetails[0].orderedDate).format("LLLL")}
          </p>
        ) : null} */}
        <div>{null}</div>
        <p className="mb-0">
          Order Total{" "}
          <span className="text-black">
            $ {paymentDetails.orderDetails[0].totalOrderAmount}
          </span>
        </p>
      </div>
    </div>
  );
}
