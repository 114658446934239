import { Fragment, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "../../../components/bootstrap/card";
import * as SettingSelector from "../../../store/setting/selectors";

import PaidSubscribers from "./paidSubscribers/paidSubscribers";

const Subscribers = memo((props) => {
  useSelector(SettingSelector.theme_color);
  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between mt-3 mb-5 ms-2">
        <h3>Subscribers</h3>
      </div>
      <div>
        <Card>
          <Card.Body className="m-0 p-0">
            <PaidSubscribers />
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
});

export default Subscribers;
