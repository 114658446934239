import React, { useCallback, useState, useMemo, useEffect } from "react";

import './styles/Createblog.scss';
import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Camera from "../../../../../assets/images/bxs_camera.svg";

import b1 from '../../../../../assets/blogs/b1.png';
import { createBlog, getSingleBlog, updateBlog } from '../../../../../services/blogservice/blog-services';
import { toast } from 'react-toastify';

import JoditEditor from 'jodit-react';
import { useNavigate, useParams } from 'react-router-dom';

function CreateBlog() {

    const history = useNavigate();


    const [content, setContent] = useState("");
    const [logs, setLogs] = useState([]);
    const appendLog = useCallback(
        (message) => {
            const newLogs = [...logs, message];
            setLogs(newLogs);
        },
        [logs, setLogs]
    );

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: "Enter Post Content"

        }),
        []
    );

    const onChange = useCallback(
        (newContent) => {
            appendLog(`onChange triggered with ${newContent}`);
        },
        [appendLog]
    );

    useEffect(() => {
    }, [onChange]);

    const onBlur = useCallback(
        (newContent) => {
            appendLog(`onBlur triggered with ${newContent}`);
            setContent(newContent);
        },
        [appendLog, setContent]
    );
    const { id } = useParams();
    const [blogimage, setBlogImage] = useState("");

    const [selectimage, setSelectImage] = useState("");


    const [error, setError] = useState(false);

    const [blogforms, setBlogForms] = useState({
        title: "",
        shortDescription: "",
        postContent: "",
        keywords: "",
        upl: ""
    });

    const { title, shortDescription, postContent, keywords } = blogforms;


    const handleChange = (e) => {
        setBlogForms({ ...blogforms, [e.target.name]: e.target.value });
    }


    const handleImageChange = (e) => {
        setSelectImage(e?.target?.files[0]);
    }

    const SubmitBlogs = () => {
        if (title?.length === 0 || shortDescription?.length === 0 || content?.length === 0 || keywords?.length === 0) {
            setError(true);
        }

        if (title && shortDescription && content && keywords) {
            const formsDatas = new FormData();
            formsDatas.append("title", title)
            formsDatas.append("shortDescription", shortDescription)
            formsDatas.append("postContent", content)
            formsDatas.append("keywords", keywords)
            formsDatas.append("upl", selectimage)
            createBlog(formsDatas).then((res) => {
                toast.success("Blog Created")
                setTimeout(() => {
                    history("/womeyn/blog")
                }, 800)
            }).catch((err) => {
                toast.error("error upload images")
                console.log(err);
            })
        }
    }



    useEffect(() => {
        getSingleBlog(id).then((res) => {
            setBlogForms(res?.data);
            setContent(res?.data?.postContent)
            // setSelectImage(res?.data?.postImageName)
            setBlogImage(res?.data?.postImageName);
        }).catch((err) => {
            console.log(err);
        })
    }, [])


    const EditBlog = () => {
        if (title?.length === 0 || shortDescription?.length === 0 || content?.length === 0 || keywords?.length === 0) {
            setError(true);
        }

        if (title && shortDescription && content && keywords) {
            const formsDatas = new FormData();
            formsDatas.append("postId", id)
            formsDatas.append("title", title)
            formsDatas.append("shortDescription", shortDescription)
            formsDatas.append("postContent", content)
            formsDatas.append("keywords", keywords)
            formsDatas.append("upl", selectimage)
            updateBlog(formsDatas).then((res) => {
                toast.success("Blog Created")
                setTimeout(() => {
                    history("/womeyn/blog")
                }, 800)
            }).catch((err) => {
                toast.error("error upload Blog")
                console.log(err);
            })
        }
    }


    const BackGo = () => {
        history("/womeyn/blog")

    }
    return (
        <div>
            <Card>
                <Card.Body className='m-0 p-0'>
                    <div className='p-2'>
                        <h4>Blog Create</h4>
                    </div>
                    <div className='col-lg-8 mt-2 mb-5'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter Title" onChange={handleChange} value={title} name="title" />
                            <Form.Text className="text-muted">
                                {error && title?.length <= 0 ? <span className='text-danger'>Title is Required</span> : ""}

                            </Form.Text>
                        </Form.Group>
                    </div>

                    <div className='col-lg-12 mb-5'>
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}
                        <Form.Label>Post Content</Form.Label>
                        {/* <Form.Control type="text" placeholder="Enter postContent" onChange={handleChange} value={postContent} name="postContent" /> */}

                        {/* <JoditEditor
                                ref={editor}
                                value={content}
                                placeholder="Enter postContent"
                                // onChange={handleChange} 
                                onChange={newContent => setContent(newContent)}
                                style={{ height: '100px' }}


                            />
                            <Form.Text className="text-muted">
                                {error && content?.length <= 0 ? <span className='text-danger'>content is Required</span> : ""}

                            </Form.Text> */}

                        <JoditEditor
                            value={content}
                            config={config}
                            tabIndex={1}
                            onBlur={onBlur}
                            onChange={onChange}
                        />


                        <Form.Text className="text-muted">
                            {error && content?.length <= 0 ? <div className="text-danger">Content is Required</div> : null}

                        </Form.Text>
                        {/* </Form.Group> */}
                    </div>
                    <div className='col-lg-12 mb-5'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Keywords</Form.Label>
                            <Form.Control type="text" placeholder="Enter keywords" onChange={handleChange} value={keywords} name="keywords" />

                            <Form.Text className="text-muted">
                                {error && keywords?.length <= 0 ? <span className='text-danger'>keywords is Required</span> : ""}

                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className='col-lg-12 mb-5'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Short Description</Form.Label>

                            <Form.Control
                                as="textarea"
                                placeholder="Enter showDescription" onChange={handleChange} value={shortDescription} name="shortDescription"
                                style={{ height: '200px' }}
                            />
                            <Form.Text className="text-muted">
                                {error && shortDescription?.length <= 0 ? <span className='text-danger'>shortDescription is Required</span> : ""}

                            </Form.Text>
                        </Form.Group>
                    </div>


                    <div className='mt-5 mb-5 ms-5'>

                        <div className="text-danger mt-2 mb-3 cursor-pointer">
                            Image Size Maximum Dimensions are 1154 x 340 pixels.
                        </div>
                        {id ? <>





                            {selectimage ? <div className='boximage'>
                                <img src={URL.createObjectURL(selectimage)} alt="no image" className='blogbannerimage' />
                                <div>
                                    <div>
                                        <input
                                            id="upload-button1"
                                            type="file"
                                            name="myImage"
                                            accept="image/png,image/jpeg,image/jpg"
                                            style={{ display: "none" }}
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <label htmlFor="upload-button1">
                                        <img src={b1} alt="no image" className='editblogimage' />
                                    </label>
                                </div>
                            </div> :
                                <div className='boximage'>
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${blogimage}`}
                                        alt="no image" className='blogbannerimage' />
                                    <div>
                                        <div>
                                            <input
                                                id="upload-button1"
                                                type="file"
                                                name="myImage"
                                                accept="image/png,image/jpeg,image/jpg"
                                                style={{ display: "none" }}
                                                onChange={handleImageChange}
                                            />
                                        </div>
                                        <label htmlFor="upload-button1">
                                            <img src={b1} alt="no image" className='editblogimage' />
                                        </label>
                                    </div>
                                </div>

                            }



                        </> : <>


                            {selectimage ? <div className='boximage'>
                                <img src={URL.createObjectURL(selectimage)} alt="no image" className='blogbannerimage' />
                                <div>
                                    <div>
                                        <input
                                            id="upload-button1"
                                            type="file"
                                            name="myImage"
                                            accept="image/png,image/jpeg,image/jpg"
                                            style={{ display: "none" }}
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <label htmlFor="upload-button1">
                                        <img src={b1} alt="no image" className='editblogimage' />
                                    </label>
                                </div>
                            </div> :
                                <div className='mt-4 mb-3'>
                                    <label htmlFor="upload-button">
                                        <div className="main-camera-sections">
                                            <div className="inside-camera-section-upload">
                                                <img src={Camera} alt="no image" className="camers" />
                                            </div>
                                        </div>
                                    </label>

                                    <div>
                                        <input
                                            id="upload-button"
                                            type="file"
                                            name="myImage"
                                            accept="image/png,image/jpeg,image/jpg"
                                            style={{ display: "none" }}
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                </div>}
                        </>}

                    </div>

                    <div>

                        {error && selectimage?.length <= 0 ? <span className='text-danger'>selectimage is Required</span> : ""}

                    </div>

                    <div className="buttonsections">
                        <div >

                            {id ?
                                <button className='savebutton' onClick={EditBlog}>Update</button>

                                :

                                <button className='savebutton' onClick={SubmitBlogs}>Save</button>

                            }
                        </div>
                        <div>
                            <button className='cancelbutton' onClick={BackGo}>Cancel</button>

                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default CreateBlog