import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, Nav, Row, Tab, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from "react-html-parser";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import b1 from "../../../../assets/blogs/b1.png";
import b2 from "../../../../assets/blogs/b2.png";
import b3 from "../../../../assets/blogs/b3.png";
import {
  deleteBlog,
  getAllBlog,
} from "../../../../services/blogservice/blog-services";
import "./styles/Blog.scss";

function Blogs() {
  const [show, setShow] = useState(false);

  const [blogid, setBlogId] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const [allblog, setAllBlog] = useState([]);

  const router = useNavigate();

  const NavigateCreate = () => {
    router("/womeyn/blog/create");
  };
  const datas = [1, 2, 3, 4, 5, 6, 7];
  const Navigatepath = (id) => {
    router(`/womeyn/blog/create/${id}`);
  };

  useEffect(() => {
    getAllBlog()
      .then((res) => {
        setAllBlog(res?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [blogid]);

  const DeleteBlog = (id) => {
    setBlogId(id);
    setShow(true);
  };

  const ConfirmDelete = () => {
    // const forms = {
    //   postId: blogid,
    //   stateId: 5,
    // };

    const formsDats = new FormData();
    formsDats.append("postId", blogid);
    formsDats.append("stateId", 5);
    const overall = allblog.filter((item) => {
      return item?.id !== blogid;
    });
    deleteBlog(formsDats)
      .then((res) => {
        toast.success("Blog Deleted");
        setAllBlog(overall);
        setTimeout(() => {
          handleClose();
        }, 500);
      })
      .catch((err) => {
        toast.error("Delete Error...");
        console.log(err);
      });
  };

  const BlogView = (id) => {
    router(`/womeyn/blog/${id}`);
  };

  return (
    <>
      <Card>
        <div>
          <div className="d-flex justify-content-between p-2 blogbox">
            <div>
              <h4>Blogs</h4>
            </div>
            <div>
              <button className="AddBlog" onClick={NavigateCreate}>
                + Create a new Blog
              </button>
            </div>
          </div>
        </div>

        <div className="cards row ">
          {allblog?.map((item, index) => {
            return (
              <div className="card col-lg-3" key={index}>
                <div className="inside-cards">
                  <div className="h-50">
                    {/* postImageName */}
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}/${item?.postImageName}`}
                      alt="no image"
                      className="imageblogs"
                    />
                  </div>
                  <div>
                    <div>{item?.title} </div>
                    {item?.shortDescription?.length>40 ? (
                      <>
                        <div>{item?.shortDescription.slice(0, 40)}...</div>
                      </>
                    ) : (
                      <>{item?.shortDescription}</>
                    )}
                    {/* <div>{ReactHtmlParser(item?.postContent)?.slice(0,30)}</div> */}
                  </div>
                </div>
                <div className="icons">
                  {/* <div>
                    <img
                      src={b3}
                      alt="no image"
                      className="blogsicon"
                      onClick={() => BlogView(item?.id)}
                    />
                  </div> */}
                  <div onClick={() => Navigatepath(item?.id)}>
                    <img src={b1} alt="no image" className="blogsicon" />
                  </div>{" "}
                  <div>
                    <img
                      src={b2}
                      alt="no image"
                      className="blogsicon"
                      onClick={() => DeleteBlog(item?.id)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Card>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}>Delete Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete this post?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={ConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Blogs;
