import { Fragment, memo, useEffect, useState } from "react";

// React-boostrap
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";

// Sweetalert
import Swal from "sweetalert2";

//Components
import ProductCard from "../../components/product-card";

// Services
import {
  deleteProductCategory,
  getProductCategoryById,
  getServiceCategory,
  updateCategory,
} from "../../../services/category/categoryServices";

import { Link } from "react-router-dom";

const ServicePage = memo(() => {
  const [productList, setProductList] = useState([]);
  const [status, setStatus] = useState(false);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showEdit, setShowEdit] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState(null);

  const handleRemoveImage = (id) => {
    const ImageDatas = selectedImage.filter((items, index) => {
      return index + 1 !== id;
    });

    setSelectedImage(ImageDatas);
    setShowEdit(true);
  };

  const handleImageChange = (e) => {
    setSelectedImage([e.target.files[0]]);
    setImageData(e.target.files[0]);
    setShowEdit(false);
  };

  const [updateForm, setUpdateForm] = useState({
    name: "",
    description: "",
    // imageName: "",
  });

  const [categoryId, setCategoryId] = useState("");

  const { name, description } = updateForm;

  const updateHandleChanges = (e) => {
    setUpdateForm({ ...updateForm, [e.target.name]: [e.target.value][0] });
  };

  const update = () => {
    const imageUrl = imageData ? imageData : "";


    const formData = new FormData();

    formData.append("name", name);
    formData.append("description", description);
    formData.append("upl", imageUrl);
    formData.append("categoryId", categoryId);

    updateCategory(formData)
      .then((res) => {
        setLoading(false);
        setStatus(true);
        handleClose();
        Swal.fire(
          "Updated!",
          "Category has been Updated Successfully.",
          "success"
        );
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setStatus(false);
    getServiceCategory()
      .then((res) => {
        setProductList(res?.data?.results);
      })
      .catch((err) => console.log(err));
  }, [status]);

  const handleEdit = async (id) => {
    await getProductCategoryById(id)
      .then((res) => {
        if (res?.data) {
          setShow(true);
          setUpdateForm(res?.data);
          setCategoryId(res?.data.parentCategoryId);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleRemove = (id) => {
    Swal.fire({
      icon: "error",
      title: "Are you sure?",
      text: "You want to delete this item",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProductCategory(id)
          .then((res) => {
            setStatus(true);
            Swal.fire("Deleted!", "Category has been deleted.", "success");
          })
          .catch((err) => console.log(err));
      }
    });
  };
  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between">
        <h4 className="mb-0">Service Categories</h4>
        <Link
          className="nav-link "
          aria-current="page"
          to="/womeyn/service-add"
        >
          <Button style={{ marginBottom: 10 }}>+ Add Service Category</Button>
        </Link>
      </div>
      <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 mt-4 ">
        {productList.map((item, index) => {
          return (
            <ProductCard
              key={index}
              // onRemove={() => handleRemove(index)}
              images={item.imageName}
              itemsName={item.name}
              onRemove={() => handleRemove(item.id)}
              onEdit={() => handleEdit(item.id)}
              // productLiked={item.like}
              id={item.id}
            />
          );
        })}
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Card>
                <Card.Body className="p-0">
                  <div id="basic" className="iq-product-tracker-card show b-0">
                    <Form>
                      <div className="mt-2 mb-2">
                        <h6>Image</h6>
                        <label htmlFor="upload-button">
                          {showEdit ? (
                            <div className="main-camera-section">
                              <div>
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}/${updateForm.imageName}`}
                                  alt="Product Img"
                                  style={{ width: 200, height: 200 }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              {selectedImage.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="container mt-2  mb-3"
                                  >
                                    <div className="main-camera-section">
                                      <img
                                        src={URL.createObjectURL(item)}
                                        alt="Avatar"
                                        class="image"
                                        style={{ width: 200, height: 200 }}
                                      />
                                    </div>
                                    <div class="overlay mt-2">
                                      <div
                                        class="icon"
                                        title="User Profile"
                                        onClick={() =>
                                          handleRemoveImage(index + 1)
                                        }
                                      >
                                        <svg
                                          width="25"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          {" "}
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
                                            fill="currentColor"
                                          ></path>{" "}
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </label>

                        <div>
                          <input
                            id="upload-button"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(event) => handleImageChange(event)}
                            multiple
                          />
                        </div>
                      </div>
                      <div className="field-container">
                        <Form.Label htmlFor="validationServer01">
                          Category Name
                        </Form.Label>
                        <Form.Control
                          name="name"
                          type="text"
                          className={true ? "" : "is-valid"}
                          id="name"
                          defaultValue={name}
                          onChange={updateHandleChanges}
                          required
                          style={{ color: "black" }}
                        />
                        <div>
                          {/* {error && name.length === 0 ? (
                        <div className="text-danger">
                          Category Name is required
                        </div>
                      ) : (
                        ""
                      )} */}
                        </div>
                      </div>

                      <div className="field-container">
                        <Form.Label htmlFor="validationServer01">
                          Category Description
                        </Form.Label>
                        <Form.Control
                          name="description"
                          type="text"
                          className={true ? "" : "is-valid"}
                          id="description"
                          as="textarea"
                          rows={5}
                          defaultValue={description}
                          onChange={updateHandleChanges}
                          required
                          style={{ color: "black" }}
                        />

                        {/* <div>
                      {error && categoryDescription.length === 0 ? (
                        <div className="text-danger">
                          Category Description is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div> */}
                      </div>
                    </Form>
                  </div>
                  <div id="stmacs" className="iq-product-tracker-card  b-0">
                    <h6>Add Image</h6>
                    <div className="mt-4">
                      {/* {!selectedImage ? (
                    <label htmlFor="upload-button">
                      <div className="main-camera-section">
                        <div className="inside-camera-section-upload">
                          <img src={Camera} alt="no image" />
                        </div>
                      </div>
                    </label>
                  ) : null} */}

                      <div>
                        <input
                          id="upload-button"
                          type="file"
                          name="myImage"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(event) => handleImageChange(event)}
                          multiple
                        />
                      </div>
                    </div>
                    {/* <div className="mt-3">
                  {error && !selectedImage ? (
                    <div className="text-danger">Image is required.</div>
                  ) : (
                    ""
                  )}
                </div> */}
                    <hr className="hr-horizontal" />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={update}>
            {loading ? (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Confirm"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
});

ServicePage.displayName = "ServicePage";
export default ServicePage;
