import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./styles/Createblog.scss";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router-dom";
import { Termscreateseller, Termsgetsingleeseller, Termsupdateeseller } from "../../../../../../../services/termsandcondition_services/termsandconditions_services";

function CreateenduserEventsandupdate() {
    const editor = useRef(null);
    const { id } = useParams();
    const [blogimage, setBlogImage] = useState("");
    const history = useNavigate();
    const [content, setContent] = useState("");

    const [selectimage, setSelectImage] = useState("");

    const [error, setError] = useState(false);

    const [eventforms, setEventForms] = useState({
        contents: "",

    });

    const {
        contents
    } = eventforms;



    const handleChange = (e) => {
        setEventForms({ ...eventforms, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {
        setSelectImage(e?.target?.files[0]);
    };

    const SubmitBlogs = () => {
        if (
            content?.length === 0) {
            setError(true);
        }

        if (content) {
            const formsDatas = new FormData();
            formsDatas.append("content", content);
            formsDatas.append("typeId", 5);
            Termscreateseller(formsDatas)
                .then((res) => {
                    toast.success("Events and update Created");
                    setTimeout(() => {
                        history("/womeyn/termsandconditions");
                    }, 800);
                })
                .catch((err) => {
                    toast.error("error upload images");
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        Termsgetsingleeseller(id)
            .then((res) => {

                setContent(res?.content.replace(/&lt;/g, "<"));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const EditBlog = () => {
        if (
            content?.length === 0) {
            setError(true);
        }
        if (content) {
            const formsDatas = new FormData();
            formsDatas.append("content", content);
            formsDatas.append("typeId", 5);
            Termsupdateeseller(formsDatas)
                .then((res) => {
                    toast.success("Events and update Updated");
                    setTimeout(() => {
                        history("/womeyn/termsandconditions");
                    }, 800);
                })
                .catch((err) => {
                    toast.error("error upload images");
                    console.log(err);
                });
        }
    };

    const BackGo = () => {
        history("/womeyn/termsandconditions");
    };
    return (
        <div>
            <Card>
                <Card.Body className="m-0 p-0">
                    <div className="p-2">
                        <h4>Events and update {id}</h4>
                    </div>




                    <div className="col-lg-12 mb-5">
                        <Form.Group className="mb-3" controlId="formBasicEmail">

                            <JoditEditor
                                ref={editor}
                                value={content}
                                placeholder="Enter Events and Update content"
                                onChange={(newContent) => setContent(newContent)}
                                style={{ height: "300px" }}
                                name={"contents"}
                            />
                            <Form.Text className="text-muted">
                                {error && content?.length <= 0 ? (
                                    <span className="text-danger">Events and update is Required</span>
                                ) : (
                                    ""
                                )}
                            </Form.Text>
                        </Form.Group>
                    </div>





                    <div className="buttonsections">
                        <div>
                            {id ? (
                                <button className="savebutton" onClick={EditBlog}>
                                    Update
                                </button>
                            ) : (
                                <button className="savebutton" onClick={SubmitBlogs}>
                                    Save
                                </button>
                            )}
                        </div>
                        <div>
                            <button className="cancelbutton" onClick={BackGo}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default CreateenduserEventsandupdate;
