import axiosConfig from "../auth/axios-interceptor";
import { HttpInterceptor } from "../auth/http-interceptors";

export function forgotPasswordServices(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post("/auth/admin/forgot-password", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function changePasswordServices(value, data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/admin/change-password?token=${value}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateForgotPasswordServices(value, data) {

  // return HttpInterceptor.post(`/auth/admin/reset-password?token=${value}`, data)
  //   .then((response) => {
  //     return response.data;
  //   })
  //   .catch((err) => {
  //     return err;
  //   });

  return axiosConfig
    .post(`/auth/admin/reset-password?token=${value}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}
