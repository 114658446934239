

import { HttpInterceptor } from "../auth/http-interceptors";

export function EmailSubscribers() {
    return HttpInterceptor.get(`/admin/email-subscribers?sortBy=updatedAt:desc&&limit=10`);
}

export function EmailSubscriberPagination(page) {
    return HttpInterceptor.get(`/admin/email-subscribers?sortBy=updatedAt:desc&&limit=10&&page=${page}`);
}