import moment from "moment";
import React, { useCallback, useState, useMemo, useEffect } from "react";

import { Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import "./styles/Createblog.scss";

import { toast } from "react-toastify";

import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Termscreateseller,
  Termsgetsingleeseller,
  Termsupdateeseller,
} from "../../../../../../../services/termsandcondition_services/termsandconditions_services";

function CreateenduserFaq() {

  const [content, setContent] = useState("");
  const [logs, setLogs] = useState([]);
  const appendLog = useCallback(
    (message) => {
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter Faq"

    }),
    []
  );

  const onChange = useCallback(
    (newContent) => {
      appendLog(`onChange triggered with ${newContent}`);
    },
    [appendLog]
  );

  useEffect(() => {
  }, [onChange]);

  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );
  const { id } = useParams();
  const [blogimage, setBlogImage] = useState("");
  const history = useNavigate();

  const [selectimage, setSelectImage] = useState("");

  const [error, setError] = useState(false);

  const [eventforms, setEventForms] = useState({
    contents: "",
  });

  const { contents } = eventforms;

  const handleChange = (e) => {
    setEventForms({ ...eventforms, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setSelectImage(e?.target?.files[0]);
  };

  const SubmitBlogs = () => {
    if (content?.length === 0) {
      setError(true);
    }

    if (content) {
      const formsDatas = new FormData();
      formsDatas.append("content", content);
      formsDatas.append("typeId", 4);
      Termscreateseller(formsDatas)
        .then((res) => {
          toast.success("Faq Created");
          setTimeout(() => {
            history("/womeyn/termsandconditions");
          }, 800);
        })
        .catch((err) => {
          toast.error("error upload images");
          console.log(err);
        });
    }
  };

  useEffect(() => {
    Termsgetsingleeseller(id)
      .then((res) => {
        setContent(res?.content.replace(/&lt;/g, "<"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const EditBlog = () => {
    if (content?.length === 0) {
      setError(true);
    }
    if (content) {
      const formsDatas = new FormData();
      formsDatas.append("content", content);
      formsDatas.append("typeId", 4);
      Termsupdateeseller(formsDatas)
        .then((res) => {
          toast.success("FAQ Updated Successfully");
          setTimeout(() => {
            history("/womeyn/termsandconditions");
          }, 800);
        })
        .catch((err) => {
          toast.error("error upload images");
          console.log(err);
        });
    }
  };

  const BackGo = () => {
    history("/womeyn/termsandconditions");
  };
  return (
    <div>
      <Card>
        <Card.Body className="m-0 p-0">
          <div className="p-2">
            <h4>Terms and Conditions</h4>
          </div>

          <div className="col-lg-12 mb-5">
            <JoditEditor
              value={content}
              config={config}
              tabIndex={1}
              onBlur={onBlur}
              onChange={onChange}
            />


            <Form.Text className="text-muted">
              {error && content?.length <= 0 ? <div className="text-danger">Content is Required</div> : null}

            </Form.Text>
          </div>

          <div className="buttonsections">
            <div>
              {id ? (
                <button className="savebutton" onClick={EditBlog}>
                  Update
                </button>
              ) : (
                <button className="savebutton" onClick={SubmitBlogs}>
                  Save
                </button>
              )}
            </div>
            <div>
              <button className="cancelbutton" onClick={BackGo}>
                Cancel
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CreateenduserFaq;
