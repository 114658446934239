import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Stripe from "../../../assets/Stripe_Logo.png";
import Minus from "../../../assets/minus.png";
import paypal_logo from "../../../assets/paypal.webp";
import Plus from "../../../assets/plus.png";

import { Card, ToastBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import {
  PaymentList,
  PaymentListByQuery,
} from "../../../services/list/listServices";
import AllPayment from "./paymentList/allPayment";
import CancelledPayment from "./paymentList/cancelledPayment";
import CompletedPayment from "./paymentList/completedPayment";
import * as moment from "moment";
const Payment = () => {
  const history = useNavigate();
  const [payment, setPayment] = useState("");
  const [active, setActive] = useState(true);



  const getPaymentsList = () => {
    PaymentList()
      .then((res) => {
        setPayment(res.data[0].results);
      })
      .catch((err) => console.log(err));
  };

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const paymentSearch = () => {

    if (searchText) {
      const filteredPayments = payment.filter(item =>
        item && item?.customerName?.toLowerCase().includes(searchText.toLowerCase())
      );
      setPayment(filteredPayments);
    } else {
      PaymentListByQuery(searchText)
        .then((res) => {
          setPayment(res.data[0].results);
        })
        .catch((err) => console.log(err));
    }
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const [expandState, setExpandState] = useState({});



  useEffect(() => {

    if (searchText?.length === 0) {
      getPaymentsList()
    }

  }, [searchText]);


  const handleExpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;

    const currentExpandState = expandState[userId];
    const newExpandState = {
      ...expandState,
      [userId]: !currentExpandState,
    };
    setExpandState(newExpandState);


    const newExpandedRows = currentExpandedRows.includes(userId)
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };
  if (!payment) {
    return <Loader />;
  }
  return (
    <>
      <h3 className="mb-5">Payments</h3>
      <Card>
        <Row>
          <Col lg={6}>
            <div className="py-0 col-lg-10">
              <div className="form-group input-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for payments"
                  onChange={handleSearch}
                />
                <span
                  className="input-group-text"
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    className="icon-20"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={paymentSearch}
                  >
                    <circle
                      cx="11.7669"
                      cy="11.7666"
                      r="8.98856"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></circle>
                    <path
                      d="M18.0186 18.4851L21.5426 22"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </Col>
          <Col className="mt-2" lg={12}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Transaction Id</th>
                  <th> WomeynPatron</th>
                  <th>Order Date</th>
                  <th>Order Amount</th>
                  <th>Payment Method</th>
                  <th>Payment Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {payment?.map((data,index) => (
                  <>
                    <tr
                      key={index}
                      style={{ backgroundColor: "white" }}
                    >
                      <td
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={() =>
                          history(`/womeyn/payment-view/${data.transactionId}`)
                        }
                      >
                        {data.transactionId}
                      </td>
                      <td style={{ color: "black" }}>{data.customerName?data.customerName:"-"}</td>
                      <td style={{ color: "black" }}>
                         {moment(data?.orderedDate).format('LL')}
                      </td>
                      <td style={{ color: "black" }}>
                        $ {data.totalOrderAmount}
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          {data?.paymentMethod === "Stripe" ? (
                            <img
                              src={Stripe}
                              alt="no image"
                              width={70}
                              // height={30}
                              style={{ borderRadius: "20%" }}
                            />
                          ) : data?.paymentMethod === "PayPal" ? (
                            <img
                              src={paypal_logo}
                              alt="no image"
                              width={70}
                              // height={30}
                              style={{ borderRadius: "20%" }}
                            />
                          ) : null}
                        </div>
                      </td>
                      <td style={{ color: "black" }}>{data.paymentStatus}</td>
                      <td
                        onClick={(event) =>
                          handleExpandRow(event, data.transactionId)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {/* {active ? (
                        <img
                          src={Plus}
                          alt="no image"
                          width={10}
                          height={10}
                          style={{ borderRadius: "20%" }}
                        />
                      ) : (
                        <img
                          src={Minus}
                          alt="no image"
                          width={10}
                          height={10}
                          style={{ borderRadius: "20%" }}
                        />
                      )} */}
                        {expandState[data.transactionId] ? (
                          <img
                            src={Minus}
                            alt="no image"
                            width={10}
                            height={10}
                            style={{ borderRadius: "20%" }}
                          />
                        ) : (
                          <img
                            src={Plus}
                            alt="no image"
                            width={10}
                            height={10}
                            style={{ borderRadius: "20%" }}
                          />
                        )}
                      </td>
                    </tr>
                    <>
                      {expandedRows.includes(data.transactionId) ? (
                        <>
                         
                          {data?.orderList.map((item) => (
                            <>
                              <tr
                                onClick={() =>
                                  history(`/womeyn/view-order/${item.orderId}`)
                                }
                                style={{ backgroundColor: "#eae4e4" }}
                              >
                                <td>{item.orderId}</td>
                                <td>{item.sellerBusinessName}</td>
                                <td>{moment(item?.orderedDate).format("LL")}</td>
                                <td>
                                  <div className="d-flex align-items-center gap-2">
                                    {item.itemsOrdered[0].productThumbImage ? (
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${item.itemsOrdered[0].productThumbImage}`}
                                        alt="no image"
                                        width={30}
                                        height={30}
                                        style={{ borderRadius: "20%" }}
                                      />
                                    ) : (
                                      <img
                                        src={
                                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo13dMxSP1rF3oMPw_0yGj892NadEqEnQ_Uw&usqp=CAU"
                                        }
                                        alt="no image"
                                        width={30}
                                        height={30}
                                        style={{ borderRadius: "20%" }}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>$ {item.totalOrderAmount}</td>
                                <td>
                                  {item.stateId === 1 && (
                                    <span
                                      className="text-light box-shdow"
                                      style={{ backgroundColor: "#B3B3FF" }}
                                    >
                                      New Order
                                    </span>
                                  )}
                                  {item.stateId === 2 && (
                                    <span
                                      className="text-light box-shdow"
                                      style={{
                                        backgroundColor: "rgb(0, 40, 255)",
                                      }}
                                    >
                                      Confirmed
                                    </span>
                                  )}
                                  {item.stateId === 3 && (
                                    <span
                                      className="text-light box-shdow"
                                      style={{ backgroundColor: "#ffff00" }}
                                    >
                                      In Progress
                                    </span>
                                  )}
                                  {item.stateId === 4 && (
                                    <span
                                      className="text-light box-shdow"
                                      style={{ backgroundColor: "#ff1a1a" }}
                                    >
                                      Cancelled by Buyer
                                    </span>
                                  )}
                                  {item.stateId === 5 && (
                                    <span
                                      className="text-light box-shdow"
                                      style={{ backgroundColor: "#ff1a1a" }}
                                    >
                                      Cancelled by WomeynPreneur
                                    </span>
                                  )}
                                  {item.stateId === 6 && (
                                    <span
                                      className="text-light box-shdow"
                                      style={{ backgroundColor: "#28a745" }}
                                    >
                                      Delivered
                                    </span>
                                  )}
                                </td>
                                <td></td>
                              </tr>
                            </>
                          ))}
                        </>
                      ) : null}
                    </>
                  </>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Payment;
