import Pagination from "rc-pagination";
import React, { Fragment, useEffect, useState } from "react";
import {Card, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import arrowleft from "../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../assets/loginLogos/rightarrow.png";
import {
  getTicketList,
  getTicketListByStateId,
} from "../../../services/tickets/ticketsServices";
import "./styles/Tickets.scss";
import moment from "moment";
function Tickets() {
  const history = useNavigate();
  const [tables, setTables] = useState([]);
  const [newData, setNewData] = useState([]);
  const [open, setOpen] = useState([]);
  const [closed, setClosed] = useState([]);
  const [page, setPage] = useState(0);
  const [updateLimit, setUpdateLimit] = useState([]);
  const [updateCurrent, setUpdateCurrent] = useState(1);
  const headertexts = [
    {
      headers: "Reqested by",
    },
    {
      headers: "Subject",
    },
    {
      headers: "Category",
    },
    {
      headers: "Ticket ID",
    },
    {
      headers: "Status",
    },
    {
      headers: "Created on",
    },
  ];

  const [servicelistfilter, setServicelistfilter] = useState(0);

  const [selectedOption, setSelectedOption] = useState("All");
  const options = [
    { value: "All", label: "All" },
    { value: "New", label: "New" },
    { value: "Open", label: "Open" },
    { value: "Closed", label: "Closed" },
  ];
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
  };
  useEffect(() => {
    if (servicelistfilter === 0) {
      tickets();
    }
  }, [page]);

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const search = () => {
    setPage(0);
    getTicketList(page, searchText)
      .then((res) => {
        const result = res.data.map((e) => e.results);
        const table = result.map((t) => t);
        const resData = table[0];
        const nData = resData.filter((data) => data.stateId === 1);
        setNewData(nData);
        const openDAta = resData.filter((data) => data.stateId === 2);
        setOpen(openDAta);
        const closedData = resData.filter((data) => data.stateId === 3);
        setClosed(closedData);
        setTables(table[0]);
      })
      .catch((err) => console.log(err));
  };

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const tickets = () => {
    getTicketList(page)
      .then((res) => {
        const result = res.data.map((e) => e.results);
        const table = result.map((t) => t);
        const resData = table[0];
        const nData = resData.filter((data) => data.stateId === 1);
        setNewData(nData);
        const openDAta = resData.filter((data) => data.stateId === 2);
        setOpen(openDAta);
        const closedData = resData.filter((data) => data.stateId === 3);
        setClosed(closedData);
        setTables(table[0]);
      })
      .catch((err) => console.log(err));
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <>
          {page !== 0 ? (
            <button onClick={() => setOpen(page - 1)}>
              <img src={arrowright} alt="no image" className="arrows" />
            </button>
          ) : null}
        </>
      );
    }
    if (tables) {
      return;
    }
    if (type === "next") {
      return (
        <>
          {tables.length !== 10 ? null : (
            <button onClick={pageIncrement}>
              <img src={arrowleft} alt="no image" className="arrows" />
            </button>
          )}
        </>
      );
    }
    return originalElement;
  };



  const fetchSellerUpdateCurrentData = async (updateCurrent) => {
    const resSellerData = await getTicketList(updateCurrent);
    setUpdateLimit(resSellerData?.data);
  };
  const handleSellerUpdateChangePagecount = async (e) => {
    setUpdateCurrent(e);
    const updateCurrent = e;
    await fetchSellerUpdateCurrentData(updateCurrent);
  };
  return (
    <Fragment>
      <div className="mb-3 mt-3">
        <h3>Tickets</h3>
      </div>
      <Card className="col-lg-12">
        <div className="d-flex justify-content-end gap-3">
          <Col lg={6}>
            <div className="py-0 col-lg-10">
              <div className="form-group input-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by id... "
                  onChange={handleSearch}
                />
                <span
                  className="input-group-text"
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    className="icon-20"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={search}
                  >
                    <circle
                      cx="11.7669"
                      cy="11.7666"
                      r="8.98856"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></circle>
                    <path
                      d="M18.0186 18.4851L21.5426 22"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </Col>
          <Col lg={2}>
            <Select
              placeholder="Status"
              onChange={handleChange}
              options={options}
            ></Select>
          </Col>
        </div>
        <Card.Body className="m-0 p-0">
          <Table hover className="cursor-texts mt-5">
            <thead>
              <tr>
                {headertexts.map((item) => {
                  return (
                    <>
                      <th>{item.headers}</th>
                    </>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {selectedOption === "All" ? (
                <>
                  {tables.map((item) => {
                    return (
                      <>
                        <tr
                          onClick={() =>
                            history(`/womeyn/ticket-view/${item._id}`)
                          }
                        >
                          <td>
                            <div
                              className="d-flex align-items-center"
                              style={{ cursor: "pointer" }}
                            >
                              {item?.profileImageName ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}/${item?.profileImageName}`}
                                  alt="no image"
                                  width="45px"
                                  height="45px"
                                  style={{ borderRadius: "15px" }}
                                />
                              ) : (
                                <img
                                  src={
                                    "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg"
                                  }
                                  alt="no image"
                                  width="45px"
                                  height="45px"
                                  style={{ borderRadius: "15px" }}
                                />
                              )}
                              <span className="media-support-infos ms-3 grey">
                                {item?.isAdminViewed === false ? (
                                  <span
                                    className="mb-0 col"
                                    style={{ color: "black", fontWeight: 600 }}
                                  >
                                    {item?.fistName} {item?.lastName}
                                  </span>
                                ) : (
                                  <span className="mb-0 col">
                                    {item?.fistName} {item?.lastName}
                                  </span>
                                )}
                              </span>
                            </div>
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?.subject}
                              </span>
                            ) : (
                              <span className="mb-0 col">{item?.subject}</span>
                            )}
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?.category?item?.category:"-"}
                              </span>
                            ) : (
                              <span className="mb-0 col">
                                {item?.category?item?.category:"-"}
                              </span>
                            )}
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?._id}
                              </span>
                            ) : (
                              <span className="mb-0 col">{item?._id}</span>
                            )}
                          </td>

                          {item.stateId === 1 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  New
                                </span>
                              ) : (
                                <span className="mb-0 col">New</span>
                              )}
                            </td>
                          ) : item.stateId === 2 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  Open
                                </span>
                              ) : (
                                <span className="mb-0 col">Open</span>
                              )}
                            </td>
                          ) : item.stateId === 3 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  Closed
                                </span>
                              ) : (
                                <span className="mb-0 col">Closed</span>
                              )}
                            </td>
                          ) : null}

                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                                               {moment(item.createdAt).format('lll')}

                              </span>
                            ) : (
                              <span className="mb-0 col">
                                                               {moment(item.createdAt).format('lll')}

                              </span>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : selectedOption === "New" ? (
                <>
                  {newData.map((item, index) => {
                    return (
                      <>
                        <tr
                          onClick={() =>
                            history(`/womeyn/ticket-view/${item._id}`)
                          }
                        >
                          <td>
                            <div
                              className="d-flex align-items-center"
                              style={{ cursor: "pointer" }}
                            >
                              {item?.profileImageName ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}/${item?.profileImageName}`}
                                  alt="no image"
                                  width="45px"
                                  height="45px"
                                  style={{ borderRadius: "15px" }}
                                />
                              ) : (
                                <img
                                  src={
                                    "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg"
                                  }
                                  alt="no image"
                                  width="45px"
                                  height="45px"
                                  style={{ borderRadius: "15px" }}
                                />
                              )}
                              <span className="media-support-infos ms-3 grey">
                                {item?.isAdminViewed === false ? (
                                  <span
                                    className="mb-0 col"
                                    style={{ color: "black", fontWeight: 600 }}
                                  >
                                    {item?.fistName} {item?.lastName}
                                  </span>
                                ) : (
                                  <span className="mb-0 col">
                                    {item?.fistName} {item?.lastName}
                                  </span>
                                )}
                              </span>
                            </div>
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?.subject}
                              </span>
                            ) : (
                              <span className="mb-0 col">{item?.subject}</span>
                            )}
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?.category?item?.category:"-"}
                              </span>
                            ) : (
                              <span className="mb-0 col">
                                {item?.category?item?.category:"-"}
                              </span>
                            )}
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?._id}
                              </span>
                            ) : (
                              <span className="mb-0 col">{item?._id}</span>
                            )}
                          </td>

                          {item.stateId === 1 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  New
                                </span>
                              ) : (
                                <span className="mb-0 col">New</span>
                              )}
                            </td>
                          ) : item.stateId === 2 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  Open
                                </span>
                              ) : (
                                <span className="mb-0 col">Open</span>
                              )}
                            </td>
                          ) : item.stateId === 3 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  Closed
                                </span>
                              ) : (
                                <span className="mb-0 col">Closed</span>
                              )}
                            </td>
                          ) : null}

                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                                               {moment(item.createdAt).format('lll')}

                              </span>
                            ) : (
                              <span className="mb-0 col">
                                                               {moment(item.createdAt).format('lll')}

                              </span>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : selectedOption === "Open" ? (
                <>
                  {open.map((item, index) => {
                    return (
                      <>
                        <tr
                          onClick={() =>
                            history(`/womeyn/ticket-view/${item._id}`)
                          }
                        >
                          <td>
                            <div
                              className="d-flex align-items-center"
                              style={{ cursor: "pointer" }}
                            >
                              {item?.profileImageName ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}/${item?.profileImageName}`}
                                  alt="no image"
                                  width="45px"
                                  height="45px"
                                  style={{ borderRadius: "15px" }}
                                />
                              ) : (
                                <img
                                  src={
                                    "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg"
                                  }
                                  alt="no image"
                                  width="45px"
                                  height="45px"
                                  style={{ borderRadius: "15px" }}
                                />
                              )}
                              <span className="media-support-infos ms-3 grey">
                                {item?.isAdminViewed === false ? (
                                  <span
                                    className="mb-0 col"
                                    style={{ color: "black", fontWeight: 600 }}
                                  >
                                    {item?.fistName} {item?.lastName}
                                  </span>
                                ) : (
                                  <span className="mb-0 col">
                                    {item?.fistName} {item?.lastName}
                                  </span>
                                )}
                              </span>
                            </div>
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?.subject}
                              </span>
                            ) : (
                              <span className="mb-0 col">{item?.subject}</span>
                            )}
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?.category?item?.category:"-"}
                              </span>
                            ) : (
                              <span className="mb-0 col">
                                {item?.category?item?.category:"-"}
                              </span>
                            )}
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              > 
                                {item?._id}
                              </span>
                            ) : (
                              <span className="mb-0 col">{item?._id}</span>
                            )}
                          </td>

                          {item.stateId === 1 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  New
                                </span>
                              ) : (
                                <span className="mb-0 col">New</span>
                              )}
                            </td>
                          ) : item.stateId === 2 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  Open
                                </span>
                              ) : (
                                <span className="mb-0 col">Open</span>
                              )}
                            </td>
                          ) : item.stateId === 3 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  Closed
                                </span>
                              ) : (
                                <span className="mb-0 col">Closed</span>
                              )}
                            </td>
                          ) : null}

                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                                              {moment(item.createdAt).format('lll')}

                              </span>
                            ) : (
                              <span className="mb-0 col">
                                                              {moment(item.createdAt).format('lll')}

                              </span>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : selectedOption === "Closed" ? (
                <>
                  {closed.map((item, index) => {
                    return (
                      <>
                        <tr
                          onClick={() =>
                            history(`/womeyn/ticket-view/${item._id}`)
                          }
                        >
                          <td>
                            <div
                              className="d-flex align-items-center"
                              style={{ cursor: "pointer" }}
                            >
                              {item?.profileImageName ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}/${item?.profileImageName}`}
                                  alt="no image"
                                  width="45px"
                                  height="45px"
                                  style={{ borderRadius: "15px" }}
                                />
                              ) : (
                                <img
                                  src={
                                    "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg"
                                  }
                                  alt="no image"
                                  width="45px"
                                  height="45px"
                                  style={{ borderRadius: "15px" }}
                                />
                              )}
                              <span className="media-support-infos ms-3 grey">
                                {item?.isAdminViewed === false ? (
                                  <span
                                    className="mb-0 col"
                                    style={{ color: "black", fontWeight: 600 }}
                                  >
                                    {item?.fistName} {item?.lastName}
                                  </span>
                                ) : (
                                  <span className="mb-0 col">
                                    {item?.fistName} {item?.lastName}
                                  </span>
                                )}
                              </span>
                            </div>
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?.subject}
                              </span>
                            ) : (
                              <span className="mb-0 col">{item?.subject}</span>
                            )}
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?.category?item?.category:"-"}
                              </span>
                            ) : (
                              <span className="mb-0 col">
                                {item?.category?item?.category:"-"}
                              </span>
                            )}
                          </td>
                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {item?._id}
                              </span>
                            ) : (
                              <span className="mb-0 col">{item?._id}</span>
                            )}
                          </td>

                          {item.stateId === 1 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  New
                                </span>
                              ) : (
                                <span className="mb-0 col">New</span>
                              )}
                            </td>
                          ) : item.stateId === 2 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  Open
                                </span>
                              ) : (
                                <span className="mb-0 col">Open</span>
                              )}
                            </td>
                          ) : item.stateId === 3 ? (
                            <td>
                              {item?.isAdminViewed === false ? (
                                <span
                                  className="mb-0 col"
                                  style={{ color: "black", fontWeight: 600 }}
                                >
                                  Closed
                                </span>
                              ) : (
                                <span className="mb-0 col">Closed</span>
                              )}
                            </td>
                          ) : null}

                          <td>
                            {item?.isAdminViewed === false ? (
                              <span
                                className="mb-0 col"
                                style={{ color: "black", fontWeight: 600 }}
                              >
                                {moment(item.createdAt).format('lll')}
                              </span>
                            ) : (
                              <span className="mb-0 col">
                                {moment(item.createdAt).format('lll')}
                                
                              </span>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : null}
            </tbody>
          </Table>

          <Pagination
            className="pagination-data mt-4"
            total={updateLimit.totalPages * 10}
            onChange={handleSellerUpdateChangePagecount}
            current={updateCurrent}
            itemRender={PrevNextArrow}
            breakLabel="..."
          />
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default Tickets;
