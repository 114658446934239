import { Fragment, memo } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import Card from "../../../components/bootstrap/card";
import * as SettingSelector from "../../../store/setting/selectors";

//Router-dom
import Allorderdetails from "./allorderdetails/Allorder";
import CancelledByBuyer from "./allorderdetails/CancelledByBuyer";
import CancelledBySeller from "./allorderdetails/CancelledBySeller";
import Delivered from "./allorderdetails/Delivered";
import InProgress from "./allorderdetails/InProgress";

const OrderDetails = memo((props) => {
  useSelector(SettingSelector.theme_color);
  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between mt-3 mb-5 ms-2">
        <h3>Orders</h3>
      </div>
      <div>
        <Card>
          <Card.Body className="m-0 p-0">
            <div>
              <Tab.Container defaultActiveKey="1">
                <Nav className="tab-bottom-bordered">
                  <div
                    className="mb-0 nav nav-tabs"
                    id="nav-tab1"
                    role="tablist"
                  >
                    <Nav.Link
                      eventKey="1"
                      id="nav-admin"
                      data-bs-target="#tab-admin"
                      aria-controls="pills-admin"
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                      aria-selected="true"
                    >
                      <span className="iq-mail-section">All Orders</span>
                    </Nav.Link>
                    <Nav.Link
                      eventKey="2"
                      id="nav-sellers"
                      data-bs-target="#tab-sellers"
                      aria-controls="pills-sellers"
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <span className="iq-mail-section">In Progress</span>
                    </Nav.Link>
                    <Nav.Link
                      eventKey="3"
                      id="nav-endc"
                      data-bs-target="#tab-endc"
                      aria-controls="pills-endc"
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <span className="iq-mail-section">
                        Cancelled By WomeynPatron
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      eventKey="4"
                      id="nav-endc"
                      data-bs-target="#tab-endc"
                      aria-controls="pills-endc"
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <span className="iq-mail-section">
                        Cancelled By WomeynPreneur
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      eventKey="5"
                      id="nav-endc"
                      data-bs-target="#tab-endc"
                      aria-controls="pills-endc"
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <span className="iq-mail-section">Delivered</span>
                    </Nav.Link>
                  </div>
                </Nav>
                <Tab.Content
                  className="mt-4 iq-tab-fade-up"
                  id="nav-tabContent"
                >
                  <Tab.Pane
                    eventKey="1"
                    id="nav-admin"
                    role="tab"
                    aria-labelledby="nav-admin-tab"
                  >
                    <Allorderdetails />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="2"
                    id="nav-sellers"
                    role="tab"
                    aria-labelledby="nav-sellers-tab"
                  >
                    <InProgress />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="3"
                    id="nav-endc"
                    role="tab"
                    aria-labelledby="nav-endc-tab"
                  >
                    <CancelledByBuyer />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="4"
                    id="nav-endc"
                    role="tab"
                    aria-labelledby="nav-endc-tab"
                  >
                    <CancelledBySeller />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="5"
                    id="nav-endc"
                    role="tab"
                    aria-labelledby="nav-endc-tab"
                  >
                    <Delivered />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
});

export default OrderDetails;
